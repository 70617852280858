import React, {useState, useEffect, useLayoutEffect} from 'react';
import { withRouter, Link, NavLink} from "react-router-dom";
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import { Form } from "react-bootstrap";
import LoadingIndicator from "../components/LoadingIndicator";
import { login, setLoading, getProfiles, resetPassword } from "../redux/actions/commonActions";
import config from "../config";

const mapStateToProps = state => {
    return {
        loading: state.commonReducer.loading,
        profiles: state.commonReducer.profiles,
        passwordWasReset: state.commonReducer.passwordWasReset
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => dispatch(login(email, password)),
        setLoading: (flag) => dispatch(setLoading(flag)),
        getProfiles: (phone) => dispatch(getProfiles(phone)),
        resetPassword: (profile, tel) => dispatch(resetPassword(profile, tel))
    };
};

function LoginScreen(props) {

    const [resetPswd, setReset] = useState(false);
    const [lift, setLift] = useState(false);

    const [obj, setObj] = useState({
        tel: "8"
    });

    useLayoutEffect(() => {
        props.setLoading(false);

        if(window.location.search && window.location.search.indexOf("p=") > -1){
            let startPos = window.location.search.indexOf("p=") + 2;
            let tel = window.location.search.substring(startPos, startPos + 11);

            setObj({
                ...obj,
                tel: tel
            });

            if(tel && tel.length === 11) props.getProfiles(tel);
        }

        sessionStorage.removeItem("welcomeShown");
    }, []);

    useEffect(()=>{
        if(props.profiles.length > 0){
            setObj({
                ...obj,
                profile: props.profiles[0]["Клиент"]
            });
        }
    }, [props.profiles]);

    useEffect(() => {
        if(props.passwordWasReset) setReset(false);
    }, [props.passwordWasReset]);

    function handleFocus() {
        if( config.isMobile && navigator.appVersion.indexOf("ndroid") > -1){
            setLift(true);
        }
    }

    function handleBlur() {
        setLift(false);
    }

    function handleChange(e){
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    }

    function handleTelChange(e) {
        let value = e.target.value.replace(/\D+/gi, "");

        if(value.length === 0){
            value = 8;
        }
        if(value.length === 11){
            props.getProfiles(value);
        }

        setObj({
            ...obj,
            tel: value
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        props.login(obj.profile, obj.password);
    }

    function handleResetPswd(){
        props.resetPassword(obj.profile, obj.tel);
    }

    return (
        <div className="login-screen">
            <div className="mt-5 d-flex align-items-center justify-content-center">
                <img src="/logo.svg" className="mr-2 mb-n2" height="50" alt=""/>
            </div>
            <div className={"card border-primary mt-5" + ((props.loading) ? " blurred" : "") }>
                <div className="card-body">
                    <Form.Group controlId="phone">
                        <Form.Label className=""><b>Телефон</b></Form.Label>
                        <Form.Control
                            type="tel"
                            autoComplete={"off"}
                            name="phone"
                            className="form-control-lg"
                            value={obj.tel}
                            maxLength={11}
                            required
                            onChange={handleTelChange}
                        />
                    </Form.Group>
                    {
                        (obj.tel.length === 11 && props.profiles && props.profiles.length > 0) &&
                        <>
                            {
                            (!resetPswd) ? (
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="profile">
                                    <Form.Label className=""><b>Профиль</b></Form.Label>
                                    {
                                        (props.profiles.length === 1) ? (
                                            <div className="alert-light alert px-3">
                                                <b>
                                                    {props.profiles[0]["Имя"]}
                                                </b>
                                            </div>
                                        ) : (
                                            <Form.Control
                                                as="select"
                                                value={obj.profile}
                                                name="profile"
                                                className="form-control-lg"
                                                required
                                                onChange={handleChange}
                                            >
                                                {
                                                    props.profiles.map((u, i) => {
                                                        return (
                                                            <option value={u["Клиент"]} key={i}>{u["Имя"]}</option>
                                                        );
                                                    })
                                                }
                                            </Form.Control>
                                        )
                                    }
                                </Form.Group>
                                <Form.Group controlId="password">
                                    <Form.Label className=""><b>Пароль</b></Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        className="form-control-lg"
                                        required
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="sub">
                                    <button
                                        type={"submit"}
                                        className="btn lift btn-primary form-control form-control-lg text-white"
                                    >
                                        Вход
                                    </button>
                                </Form.Group>
                            </Form>
                            ) : null
                            }

                            {
                                (resetPswd) ? (
                                    <div className="alert alert-light">
                                        <ul className={""}>
                                            <li>
                                                Нажмите "Сбросить пароль".
                                            </li>
                                            <li>
                                                Вам поступит звонок, в котором робот продиктует пароль.
                                            </li>
                                            <li>
                                                Если не сможем до вас дозвониться - пришлём пароль в СМС.
                                            </li>
                                        </ul>
                                        <div className={"d-none mb-3"}>
                                            Нажмите "Сбросить пароль". Новый пароль придет в СМС.
                                        </div>
                                        <button
                                            className="btn btn-primary form-control lift form-control-lg"
                                            onClick={ handleResetPswd }
                                        >
                                            Сбросить пароль
                                        </button>
                                        <button
                                            className="mt-3 btn btn-outline-secondary form-control form-control-lg text-primary"
                                            onClick={() => setReset(false)}
                                        >
                                            Отмена
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        <Form.Group>
                                            <button
                                                className="btn btn-outline-primary form-control form-control-lg"
                                                onClick={() => setReset(true)}
                                            >
                                                Забыли пароль?
                                            </button>
                                        </Form.Group>
                                        <hr/>
                                        <div className={`mt-4`}>
                                            <Form.Group controlId="sub123">
                                                <Link to={"/register?p=" + obj.tel}
                                                      className="btn lift btn-primary form-control form-control-lg">
                                                    Регистрация
                                                </Link>
                                            </Form.Group>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    }
                    {
                        (obj.tel.length === 11 && props.profiles && props.profiles.length === 0) &&
                        <>
                            <Form.Group className="text-center alert alert-warning">
                                Для указанного номера телефона не найдено ни одного профиля
                            </Form.Group>
                            <Form.Group>
                                <a href={"/register?p=" + obj.tel} className="btn lift btn-primary form-control form-control-lg">
                                    Зарегистрироваться
                                </a>
                            </Form.Group>
                        </>
                    }
                </div>
            </div>
            <div className={"text-center my-3 pb-5"}>
                <NavLink to={"/policy"}>
                    Положение об обработке и защите персональных данных в ООО МЦ «МЕДЕОР»
                </NavLink>
            </div>
            <div className={(props.loading) ? "login-screen-loader login-screen-loader-show " : "login-screen-loader"}>
                <LoadingIndicator />
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(LoginScreen)));