import * as ACTIONS from "../actionTypes";
import axios from "axios";
import config from "../../config";
import { setLoading, handleError } from "./commonActions";
import {enqueueSnackbar} from "./notificationActions";

export function getAnalyz(analyz, mode) {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_ANALYZ,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/analyzes/getAnalyz', {analyz, mode}, config.axiosOptions)
                    .then((res) => {
                        if (mode === "pdf" && window.ReactNativeWebView) {  //if we work under react native app
                            window.ReactNativeWebView.postMessage(res.data);
                        }

                        if (mode === "email"){
                            dispatch(enqueueSnackbar({
                                message: res.data,
                                options: config.snackOptions.success
                            }));
                        }
                        dispatch(setLoading(false));

                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getAnalyzes() {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_ANALYZES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/analyzes/list', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}