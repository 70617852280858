import React, { useEffect } from 'react';
import { showModal } from "../../redux/actions/commonActions";
import { addAppointment } from "../../redux/actions/appointmentsActions";
import MakeAppModal from "./MakeAppModal";
import { connect } from "react-redux";

const flag = "MAKE-APP";

const mapStateToProps = state => {
    return {
        service: state.cosmetologyReducer.service,
        doctor: state.cosmetologyReducer.doctor,
        time: state.cosmetologyReducer.time,
        date: state.cosmetologyReducer.date,
        loading: state.commonReducer.loading,
        show: state.onlineReducer.showModal === flag
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (flag) => dispatch(showModal(flag)),
        addAppointment: (data) => dispatch(addAppointment(data))
    };
};

function m(props){

    if(!( props.doctor && props.service && props.time )) return null;

    const handleSubmit = () => {
        props.addAppointment({
            date: `${props.date}T${props.time}:00+05:00`,
            doctor: props.doctor['Врач'],
            service: props.service['Номенклатура'],
            duration: props.service['Длительность'] || 30,
            dms: 0,
            lk: "cosmetology"
        });
    };

    return (
        <>
            <div className={"text-center"}>
                <button
                    className={"btn btn-primary py-3 w-100"}
                    style={{ maxWidth: 400 }}
                    onClick={ handleSubmit }
                >
                    Записаться
                </button>
            </div>

            {
                props.show && <MakeAppModal />
            }
        </>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(m);