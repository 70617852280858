import React, { useEffect } from 'react';
import { showModal } from "../../redux/actions/commonActions";
import { selectService } from "../../redux/actions/cosmetologyActions";
import Service from "./Services";
import { connect } from "react-redux";

const flag = "SERVICES";

const mapStateToProps = state => {
    return {
        services: state.cosmetologyReducer.services,
        service: state.cosmetologyReducer.service,
        loading: state.commonReducer.loading,
        show: state.onlineReducer.showModal === flag
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (flag) => dispatch(showModal(flag)),
        selectService: (doc) => dispatch(selectService(doc))
    };
};

function m(props){

    const clear = () => {
        props.showModal(false);
        props.selectService(null);
    }

    return (
        <>
            <div
                className={"rounded-lg p-4 border border-primary bg-white cursor-pointer"}
                style={{ display: "grid", alignItems: "center", gridTemplateColumns: "max-content 1fr", gridGap: "1rem" }}
            >
                <div
                    className={"rounded-circle bg-primary d-flex align-items-center justify-content-center"}
                    style={{ height: 50, width: 50 }}
                    onClick={ () => props.showModal(flag) }
                >
                    <i className={"text-white fas fa-list fa-15x"}></i>
                </div>

                {
                    (props.service) && (
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div onClick={ () => props.showModal(flag) }>
                                { props.service['Наименование'] }
                            </div>
                            <i
                                className={"fas fa-trash text-secondary fa-15x"}
                                onClick={ clear}
                            ></i>
                        </div>
                    )
                }

                {
                    !props.service &&
                    <div
                        className={"h-100 d-flex align-items-center"}
                        onClick={ () => props.showModal(flag) }
                    >
                        <div>
                            Выберите услугу
                        </div>
                    </div>
                }

            </div>
            <div>
                { props.show && <Service />}
            </div>
        </>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(m);