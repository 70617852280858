import {NavLink, withRouter, Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {bindActionCreators} from "redux";
import {enqueueSnackbar} from "../redux/actions/notificationActions";
import {logout} from "../redux/actions/commonActions";
import {connect} from "react-redux";

function NavBar(props){
    const [show, setShow] = useState(false);
    const [showBackBtn, setShowBackButton] = useState(false);

    const headConfig = [
        {
            url: "/settings",
            title: "Профиль",
            showGoBack: true
        },
        {
            url: "/notifications",
            title: "Уведомления",
            showGoBack: true
        },
        {
            url: "/online",
            title: "Запись на приём",
            showGoBack: true
        },
        {
            url: "/analyzes",
            title: "Анализы",
            showGoBack: true
        },
        {
            url: "/contact",
            title: "Контакты",
            showGoBack: true
        },
        {
            url: "/appointments",
            title: "Приёмы",
            showGoBack: true
        },
        {
            url: "/payment",
            title: "Онлайн-оплата",
            showGoBack: true
        },
        {
            url: "/teledoc",
            title: "Онлайн-консультации",
            showGoBack: true
        },
        {
            url: "/topup",
            title: "Пополнение баланса",
            showGoBack: true
        },
        /*{
            url: "/cart",
            title: "Моя карта",
            showGoBack: false
        },
        {
            url: "/apps",
            title: "Записаться",
            showGoBack: false
        },*/
    ];

    useEffect(() => {
            setShow(false);
            setShowBackButton(false);

            headConfig.forEach( item => {
                if(item.url === props.location.pathname && item.showGoBack) {
                    setShowBackButton(true);
                }
            });
    },
        [props.location.pathname]
    );

    function setView() {
        setShow(!show);
    }

    function handleExit() {
        props.logout(props.user.guid);
    }

    function renderTitle(){

        let title = "";

        headConfig.forEach( item => {
            if(props.history.location.pathname === item.url) {
                title = item.title;
            }
        });

        if(title) return (
            <div className={"d-flex align-items-center"} style={{ minHeight: 48 }}>
                <h1 className="mb-0">
                    {title}
                </h1>
            </div>
        );

        return (
            <div className={"d-flex align-items-center justify-content-center"} style={{ height:48 }} >
                <img src="/logo.svg" className="mx-2" height={32} alt=""/>
            </div>
        );
    }

    return (
        <div className={"d-none d-md-block"}>
            <nav className="navbar navbar-expand-md fixed-top navbar-light bg-white m-0 py-1">
                <div className="container-fluid">
                <div className="d-flex flex-grow-1 align-items-center justify-content-between justify-content-md-start">
                    <div className={"d-flex d-md-none"}>
                        {
                            showBackBtn && (
                                <button
                                    className={`btn px-0 align-items-center d-flex`}
                                    onClick={ () => props.history.goBack() }
                                >
                                    <i className={`fas fa-chevron-left text-primary fa-15x`}></i>
                                </button>
                            )
                        }

                        {
                            !showBackBtn && (
                                <Link
                                    className={`btn px-0 align-items-center d-flex`}
                                    to={"/topup"}
                                >
                                    <i className={`fas fa-ruble-sign text-primary fa-15x`}></i>
                                </Link>
                            )
                        }

                        <div className={"d-none"}>
                            <button
                                className="btn px-0 d-flex align-items-center"
                                onClick={() => props.history.push('settings')}
                            >
                                <i className={`far fa-user-circle fa-15x ${props.history.location.pathname === "/settings" ? "text-primary" : "" }`}></i>
                            </button>
                        </div>
                    </div>

                    <div className="navbar-brand">
                        <div className={"d-md-none"}>
                            { renderTitle() }
                        </div>
                        <div className={"d-none d-lg-inline-flex align-items-center"}>
                            <img src="/logo.svg" className="mr-2" height="32" alt=""/>
                        </div>
                    </div>

                    <div className={"d-flex d-md-none"}>
                        <div className={"px-3"}></div>
                        <div className={"d-none"}>
                            <button
                                className="btn px-0 d-flex align-items-center"
                                onClick={() => props.history.push('notifications')}
                            >
                                <i className={`far fa-bell fa-15x ${props.history.location.pathname === "/notifications" ? "text-primary" : "" }`}></i>
                            </button>
                        </div>
                    </div>
                    <div className="d-none d-md-flex">
                        <ul className="navbar-nav ml-4 align-items-center">
                            <li className="nav-item">
                                <NavLink exact to={"/"} className={"nav-link"} activeClassName={"active"}>
                                    ГЛАВНАЯ
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to={"/cart"} className={"nav-link"} activeClassName={"active"}>
                                    МОЯ КАРТА
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to={"/doctors"} className={"nav-link"} activeClassName={"active"}>
                                    ВРАЧИ
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to={"/apps"} className={"nav-link"} activeClassName={"active"}>
                                    ЗАПИСАТЬСЯ
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to={"/contact"} className={"nav-link"} activeClassName={"active"}>
                                    КОНТАКТЫ
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="navbar-toggler d-none">
                    <button
                        className={"hamburger hamburger--spin p-0 " + ((show) ? "is-active" : "")} type="button"
                        onClick={setView}
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                </div>

                <div className={"collapse navbar-collapse"}>
                    <div className="row ml-lg-5">
                        <div className="nav-item dropdown">
                            <button className="btn d-flex align-items-center">
                                {
                                    (props.user && props.user['Имя']) &&
                                    <b className="d-none">
                                        {props.user['Имя'].toUpperCase()}
                                    </b>
                                }
                                <i className="far fa-user-circle fa-2x ml-2"></i>
                            </button>
                            <div className={"dropdown-menu dropdown-menu-right"}>

                                <div className="dropdown-item text-dark">
                                    {
                                        (props.user && props.user.guid) &&
                                        <b>
                                            {`${props.user['Имя']} ${props.user['Отчество']} ${props.user['Фамилия']}`}
                                        </b>
                                    }
                                </div>

                                <div className="dropdown-divider"></div>

                                <Link
                                    to={'/settings'}
                                    className="dropdown-item d-flex align-items-center justify-content-start text-dark"
                                >
                                    <i className="fas fa-cog mr-3 fa-15x"></i>
                                    Настройки
                                </Link>

                                <div className="dropdown-divider"></div>

                                <button
                                    onClick={handleExit}
                                    className="btn dropdown-item d-flex align-items-center text-danger"
                                >
                                    <i className="fas fa-sign-out-alt mr-3 fa-15x"></i>
                                    Выход
                                </button>
                            </div>
                        </div>

                        <div className="nav-item">
                            <Link
                                className={`btn align-items-center d-flex`}
                                to={"/topup"}
                            >
                                <i className={`fas fa-ruble-sign text-primary fa-2x`}></i>
                            </Link>
                        </div>

                        <div className="nav-item">
                            <button
                                className="btn"
                                onClick={() => props.history.push('notifications')}
                            >
                                <div className="lift">
                                    <i className="far fa-bell fa-2x"></i>
                                    <span className="d-none badge badge-pill number badge-danger">3</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        futureList: state.appointmentsReducer.futureList
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ enqueueSnackbar, logout }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(NavBar));

/*
<div className={"d-block d-md-none mobile-menu " + ((show) ? " mobile-menu-show" : "")}>
            <ul className="navbar-nav ml-2">
                <li className="nav-item">
                    <NavLink exact to={"/"} className={" nav-link"} activeClassName={"active active-mobile"}>
                        ГЛАВНАЯ
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={"/appointments"} className={" nav-link"} activeClassName={"active active-mobile"}>
                        ПРИЁМЫ
                        {
                            (props.futureList && props.futureList.length > 0) &&
                            <span className=" ml-1 badge badge-pill number badge-soft-danger">{props.futureList.length}</span>
                        }
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={"/analyzes"} className={" nav-link"} activeClassName={"active active-mobile"}>
                        АНАЛИЗЫ
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={"/online"} className={" nav-link"} activeClassName={"active active-mobile"}>
                        ОНЛАЙН-ЗАПИСЬ
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={"/contact"} className={" nav-link"} activeClassName={"active-mobile"}>
                        КОНТАКТЫ
                    </NavLink>
                </li>
            </ul>

            <div className="px-3">
                <hr/>
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <i className="far fa-user-circle mr-3 fa-15x"></i>
                    </div>
                    <h2 className="mb-0">
                        {props.user['Имя']} {props.user['Отчество']} {props.user['Фамилия']}
                    </h2>
                </div>
                <hr/>

                <button
                    onClick={() => props.history.push("/settings")}
                    className="mb-3 pl-0 btn btn-light d-flex align-items-center"
                >
                    <i className="fas fa-cog mr-3 fa-15x"></i>
                    НАСТРОЙКИ
                </button>

                <button
                    onClick={handleExit}
                    className="pl-0 btn btn-light text-danger d-flex align-items-center"
                >
                    <i className="fas fa-sign-out-alt mr-3 fa-15x"></i>
                    ВЫХОД
                </button>
            </div>
        </div>
 */