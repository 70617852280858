import React, { useState, useEffect } from 'react';
import { setLoading, getBalance } from "../redux/actions/commonActions";
import { showModal, getLinkToAddBalance } from "../redux/actions/paymentActions";
import { connect } from "react-redux";
import Header from "../components/Header";

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        balance: state.commonReducer.balance
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: () => dispatch(setLoading()),
        getBalance: () => dispatch(getBalance()),
        getLinkToAddBalance: (data) => dispatch(getLinkToAddBalance(data))
    };
};

function TopUpScreen(props) {

    const [amount, setAmount] = useState(0);
    const [step, setStep] = useState(1);

    let checkout;

    useEffect( () => {
        props.getBalance();
    }, []);

    function handleAmountChange(e){
        if(e.target.value < 0) setAmount(0);
        else setAmount(e.target.value);
    }

    function getPaymentLink(){
        if(amount > 0) props.getLinkToAddBalance({ amount });
    }

    return (
        <div className="">
            <Header back={1} title={"ДЕПОЗИТ"} />
            <div className="card" style={{ maxWidth: 400, margin: 'auto' }}>
                <div className="card-body mx-md-3">

                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div className={"mr-3"}>
                            <b>
                                Клиент
                            </b>
                        </div>
                        <div>
                            {props.user['Фамилия']} {props.user['Имя']} {props.user['Отчество']}
                        </div>
                    </div>

                    <hr/>

                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div className={"mr-3"}>
                            <b>
                                Текущий баланс
                            </b>
                        </div>
                        <div>
                            { props.balance } руб.
                        </div>
                    </div>

                    <hr/>

                    <div className={"d-flex align-items-center justify-content-between"}>
                        <div className={"mr-3"}>
                            <b>
                                Сумма пополнения
                            </b>
                        </div>
                        <div className={"d-flex align-items-center"}>
                            <input
                                className={"form-control"}
                                value={amount}
                                type="number"
                                placeholder="Сумма"
                                name="amount"
                                min={1}
                                style={{ width: 100 }}
                                onChange={handleAmountChange}
                            />
                            <div className={"ml-2"}>
                                руб.
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className={"text-center"}>
                        <button
                            className="btn btn-primary flex-grow-0 lift mr-n3"
                            onClick={getPaymentLink}
                            disabled={!(amount > 0)}
                        >
                            Пополнить
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default connect( mapStateToProps, mapDispatchToProps)(TopUpScreen);