import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setLoading } from '../../redux/actions/commonActions';
import { getNotifications } from '../../redux/actions/notificationActions';
import dateformat from 'dateformat';

function Notifications(props) {

    useEffect( () => props.getNotifications(), [] );

    if(props.notifications && props.notifications.length) {
        return props.notifications.map( (u,i) => {
            return (
                <div key={i}>
                    <div className="d-none d-md-block">
                        <div className={"bg-white border border-2 rounded p-3 my-3"}>
                            <b className="badge number badge-soft-dark mr-3">{ dateformat(u['Дата'] + "+05:00", "dd.mm.yyyy в HH:MM") }</b>
                            <span>
                                {u['Текст']}
                            </span>
                        </div>
                    </div>
                    <div className="d-block d-md-none">
                        <div className={"card px-3 pb-2 mb-4 bg-white"}>
                            <div className="mx-n3 px-3 pt-3 pb-2">
                                <h3 className={"mb-0"}>
                                    <b>
                                        {dateformat(u['Дата'] + "+05:00", "dd.mm.yyyy в HH:MM")}
                                    </b>
                                </h3>
                            </div>
                            <div>
                                {u['Текст']}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } );
    }
    else {
        return (
            <div className="card-body flex-grow-1 d-flex flex-column align-items-center justify-content-center">
                <i className="far text-primary fa-bell-slash fa-2x"></i>
                <div className="mt-4">
                    Новых уведомлений нет
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.commonReducer.notifications,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: () => dispatch(getNotifications()),
        setLoading: () => dispatch(setLoading())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

