import React from "react";
import Header from "../components/Header";
import {connect} from "react-redux";
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "../redux/actionTypes";

function ContactsScreen(props) {
        return (
            <div className="">
                <Header back={1} title={"КОНТАКТЫ"} />
                <div className="card-deck">

                        <div className="card bg-white mt-3">
                            <div className="card-body">

                                <div>
                                    <b>
                                        Контакт-центр
                                    </b>
                                    <div>
                                        { props.style === CLINIC_INTERFACE &&
                                            <a href="tel:+73512778808">
                                                8 (351) 277-88-08
                                            </a>
                                        }

                                        { props.style === COSMETOLOGY_INTERFACE &&
                                            <a href="tel:+73512004400">
                                                8 (351) 200-44-00
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <div>
                                    <b>Адрес</b>
                                    <p>г. Челябинск, ул. Горького, д. 16</p>
                                </div>
                                <div>
                                    <b>
                                        График работы
                                    </b>

                                    { props.style === CLINIC_INTERFACE &&
                                        <p className={""}>
                                            Пн — Вс: 8:00 - 20:00
                                        </p>
                                    }

                                    { props.style === COSMETOLOGY_INTERFACE &&
                                        <div>
                                            <p className={"mb-0"}>
                                                Пн — Сб: 9:00 - 20:00
                                            </p>
                                            <p className={""}>
                                                Вс: 9:00 - 18:00
                                            </p>
                                        </div>
                                    }

                                    <div>
                                        <small>
                                            График работы в праздничные дни уточняйте у администраторов колл-центра
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <div>
                                    <b>
                                        Email
                                    </b>

                                    { props.style === CLINIC_INTERFACE &&
                                        <p>
                                            <a href="mailto:info@medeor74.ru">
                                                info@medeor74.ru
                                            </a>
                                        </p>
                                    }

                                    { props.style === COSMETOLOGY_INTERFACE &&
                                        <p>
                                            <a href="mailto:medeorcosmetology@mail.ru">
                                                medeorcosmetology@mail.ru
                                            </a>
                                        </p>
                                    }
                                </div>

                                <div>
                                    <b>Веб-сайт</b>

                                    { props.style === CLINIC_INTERFACE &&
                                        <p className="mb-0">
                                            {
                                                (window.ReactNativeWebView) ? (
                                                    <a href="#" target="_blank" onClick={ () => window.ReactNativeWebView.postMessage("https://medeor74.ru") }>
                                                        medeor74.ru
                                                    </a>
                                                ) : (
                                                    <a href="https://medeor74.ru" target="_blank">
                                                        medeor74.ru
                                                    </a>
                                                )
                                            }
                                        </p>
                                    }

                                    { props.style === COSMETOLOGY_INTERFACE &&
                                        <p className="mb-0">
                                            {
                                                (window.ReactNativeWebView) ? (
                                                    <a href="#" target="_blank" onClick={ () => window.ReactNativeWebView.postMessage("https://medeorcosmetology.clinic/") }>
                                                        https://medeorcosmetology.clinic
                                                    </a>
                                                ) : (
                                                    <a href="https://medeorcosmetology.clinic/" target="_blank">
                                                        https://medeorcosmetology.clinic
                                                    </a>
                                                )
                                            }
                                        </p>
                                    }

                                </div>

                                <div className={"mt-3"}>
                                    <b>Наши соцсети</b>

                                    { props.style === CLINIC_INTERFACE &&
                                        <div className={"d-flex align-items-center justify-content-around mt-2"}>

                                            {
                                                (window.ReactNativeWebView) ?
                                                    (
                                                        <button className={"btn"} onClick={ () => window.ReactNativeWebView.postMessage("https://instagram.com/medeor74") }>
                                                            <i className={"fab fa-instagram fa-3x text-primary"}></i>
                                                        </button>
                                                    ) :
                                                    (
                                                        <a href="https://instagram.com/medeor74" target="_blank">
                                                            <i className={"fab fa-instagram fa-3x text-primary"}></i>
                                                        </a>
                                                    )
                                            }

                                            {
                                                (window.ReactNativeWebView) ?
                                                    (
                                                        <button className={"btn"} onClick={ () => window.ReactNativeWebView.postMessage("https://vk.com/medeor174") }>
                                                            <i className={"fab fa-vk fa-3x"}></i>
                                                        </button>
                                                    ) :
                                                    (
                                                        <a href="https://vk.com/medeor174" target="_blank">
                                                            <i className={"fab fa-vk fa-3x"}></i>
                                                        </a>
                                                    )
                                            }
                                        </div>
                                    }

                                    { props.style === COSMETOLOGY_INTERFACE &&
                                        <div className={"d-flex align-items-center justify-content-around mt-2"}>

                                            {
                                                (window.ReactNativeWebView) ?
                                                    (
                                                        <button className={"btn"} onClick={ () => window.ReactNativeWebView.postMessage("https://www.instagram.com/medeorcosmetology") }>
                                                            <i className={"fab fa-instagram fa-3x text-primary"}></i>
                                                        </button>
                                                    ) :
                                                    (
                                                        <a href="https://www.instagram.com/medeorcosmetology" target="_blank">
                                                            <i className={"fab fa-instagram fa-3x text-primary"}></i>
                                                        </a>
                                                    )
                                            }

                                            {
                                                (window.ReactNativeWebView) ?
                                                    (
                                                        <button className={"btn"} onClick={ () => window.ReactNativeWebView.postMessage("https://vk.com/medeor_cosmetology") }>
                                                            <i className={"fab fa-vk fa-3x"}></i>
                                                        </button>
                                                    ) :
                                                    (
                                                        <a href="https://vk.com/medeor_cosmetology" target="_blank">
                                                            <i className={"fab fa-vk fa-3x"}></i>
                                                        </a>
                                                    )
                                            }
                                        </div>
                                    }


                                </div>

                            </div>
                        </div>
                </div>

                <div className="card my-5 overflow-hidden">
                    <iframe
                        style={{width: '100%', height: '70vh'}}
                        src="https://yandex.ru/map-widget/v1/-/CGGeV0MZ"
                        frameBorder="0"
                        allowFullScreen={true}
                    />
                </div>
            </div>

        );
}

const mapStateToProps = state => {
    return {
        style: state.interfaceReducer.style,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsScreen);