import * as ACTIONS from '../actionTypes'
import axios from "axios";
import config from "../../config";
import {handleError, setLoading} from "./commonActions";

export function getNotifications() {
    return function (dispatch) {
        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.GET_NOTIFICATIONS,
            payload: new Promise((resolve, reject) => {
                axios.get(config.api + '/notifications/list/', config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export const enqueueSnackbar = notification => ({
    type: ACTIONS.ENQUEUE_SNACKBAR,
    notification: {
        key: new Date().getTime() + Math.random(),
        ...notification,
    },
});

export const removeSnackbar = key => ({
    type: 'REMOVE_SNACKBAR',
    key,
});