import React, {useEffect, useLayoutEffect} from 'react';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { setLoading, redirect, checkAuth, getPromos } from '../redux/actions/commonActions';
import { getFutureAppointments } from '../redux/actions/appointmentsActions';
import { getSpecs } from "../redux/actions/onlineZapisActions";
import NavBar from "../components/NavBar";
import PaymentModal from "../components/payments/PaymentModal";
import BottomNavBar from "../components/BottomNavBar";
import NotFoundScreen from "../screens/NotFoundScreen";
import {connect} from "react-redux";
import AppointmentsScreen from "../screens/AppointmentsScreen";
import AnalyzesScreen from "../screens/AnalyzesScreen";
import FileScreen from "../screens/FileScreen";
import DocView from "../components/DocView";
import OnlineScreen from "../screens/OnlineScreen1";
import SettingsScreen from "../screens/SettingsScreen";
import ContactsScreen from "../screens/ContactsScreen";
import HomeScreen from "../screens/HomeScreen";
import NotificationsScreen from '../screens/NotificationsScreen';
import PaymentScreen from "../screens/PaymentScreen";
import OnlineDoctorsScreen from "../screens/OnlineDoctors";
import BonusCodeScreen from "../screens/BonusCodeScreen";
import MyCartScreen from "../screens/MyCartScreen";
import AppointmentsRouterScreen from "../screens/AppointmentsRouterScreen";
import MoreScreen from "../screens/MoreScreen";
import TopUpScreen from "../screens/TopUpScreen";
import DoctorsScreen from "../screens/DoctorsScreen";
import PolicyScreen from "../screens/PolicyScreen";
import CosmetologyOnlineScreen from "../screens/CosmetologyOnlineScreen";

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        loading: state.commonReducer.loading,
        path: state.commonReducer.path,
        online: state.onlineReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: () => dispatch(setLoading()),
        getPromos: () => dispatch(getPromos()),
        getFutureAppointments: () => dispatch(getFutureAppointments()),
        checkAuth: () => dispatch(checkAuth()),
        redirect: (path) => dispatch(redirect(path)),
        getSpecs: () => dispatch(getSpecs())
    };
};

function AdminLayout(props) {

    useLayoutEffect(() => {
        if( props.user['Имя'] === undefined ){
            props.checkAuth();
        }
        else {
            props.getFutureAppointments();
            props.getPromos();
        }

        //props.getSpecs();
    },[]);

    if(props.user['Имя'] === undefined) return null;
    else {
        return (
            <div>
                <NavBar />

                <main className={`${(props.loading) ? "blurred1" : ""} mt-0 mt-md-5 `}>
                    <div className="container-fluid mb-5 pb-5" style={{ paddingTop: 40 }}>
                        <Switch>
                            <Route exact path={"/"} component={HomeScreen} />
                            <Route exact path={"/cart"} component={MyCartScreen} />
                            <Route path={"/appointments"} component={AppointmentsScreen} />
                            <Route path={"/analyzes"} component={AnalyzesScreen} />
                            <Route exact path={"/apps"} component={AppointmentsRouterScreen} />
                            <Route path={"/online"} component={OnlineScreen} />
                            <Route path={"/cosmo-online"} component={CosmetologyOnlineScreen} />
                            <Route path={"/teledoc"} component={ OnlineDoctorsScreen } />
                            <Route path={"/doctors"} component={ DoctorsScreen } />
                            <Route path={"/settings"} component={SettingsScreen} />
                            <Route path={"/notifications"} component={NotificationsScreen} />
                            <Route path={"/contact"} component={ContactsScreen} />
                            <Route path={"/files/:fileId"} component={FileScreen} />
                            <Route path={"/404"} component={NotFoundScreen} />
                            <Route path={"/payment"} component={ PaymentScreen } />
                            <Route exact path={"/topup"} component={ TopUpScreen } />
                            <Route path={"/code"} component={ BonusCodeScreen } />
                            <Route path={"/more"} component={ MoreScreen } />
                            <Route path={"/"} component={NotFoundScreen} />
                        </Switch>
                    </div>
                </main>
                <div className={"loading-splash " + ((props.loading) ? "loading-splash-show" : "")}>
                    <div className="spinner-border text-primary" role="status" style={{width: '4rem', height: '4rem'}}>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <DocView />
                <BottomNavBar />
                <PaymentModal />
            </div>
        );
    }
}

export default connect( mapStateToProps, mapDispatchToProps)(withRouter(AdminLayout));