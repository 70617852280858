import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../components/Header";

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        futureList: state.appointmentsReducer.futureList
    };
};

function MyCartScreen(props) {
    return (
        <>
            <Header title={"МОЯ КАРТА"} />
            <div className="mx-auto" style={{ maxWidth: 600 }}>
                <div
                    className="card p-3 cursor-pointer"
                    onClick={() => props.history.push('/appointments')}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Приёмы
                            {
                                (props.futureList && props.futureList.length > 0) &&
                                <div className="ml-2 badge badge-danger number badge-pill">
                                    {props.futureList.length}
                                </div>
                            }
                        </span>
                        <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                    </div>
                </div>

                <div
                    className="card p-3 cursor-pointer"
                    onClick={() => props.history.push('/analyzes')}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <span>
                            Результаты анализов
                        </span>
                        <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect( mapStateToProps )(withRouter(MyCartScreen));