import React, {useEffect, useState, useLayoutEffect} from 'react';
import {connect} from "react-redux";
import {withRouter, NavLink} from "react-router-dom";
import {getDocs, getSpecs } from "../../redux/actions/onlineZapisActions";
import { setLoading, showDoctorPage } from "../../redux/actions/commonActions";
import {COSMETOLOGY_INTERFACE, CLINIC_INTERFACE} from "../../redux/actionTypes";
import axios from "axios";
import config from "../../config";

const mapStateToProps = state => {
    return {
        loading: state.commonReducer.loading,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDocs: (spec) => dispatch(getDocs(spec)),
        showDoctorPage: (doc) => dispatch(showDoctorPage(doc)),
        setLoading: (flag) => dispatch(setLoading(flag))
    };
};

function DocPhoto(props) {

    const [ imgData, setImgData ] = useState("");
    const [ imgLoading, setImgLoading ] = useState(true);

    useLayoutEffect( () => {
        axios
            .get(`${config.api}/${(props.style === COSMETOLOGY_INTERFACE ? "cosmetology" : "online")}/doctor-photo/${props.docID}/${Math.round(Math.random() * 10000)}`)
            .then( r => {
                setImgLoading(false);
                setImgData(r.data);
            })
            .catch( err => {
                setImgLoading(false);
                console.log(err);
            });
    }, []);

    if (imgLoading)
        return <div className={"spinner spinner-border text-primary"}></div>;
    else {
        if (imgData && imgData.length === 3) return <img className={"img-fluid"} src={`/vrachi/${props.docID}.jpg`} alt="" />;

        if (imgData) return <img className={"img-fluid"} src={`data:image/jpg;base64,${imgData}`} alt="" />;

        return null;
    }
}

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(DocPhoto));