import React, {useEffect} from 'react';
import Specs from '../components/online/Specs';
import Docs from '../components/online/Docs';
import Services from '../components/online/Services';
import Profiles from '../components/online/Profiles';
import { getSpecs, getDocs, clearOnlineData, getProfiles } from "../redux/actions/onlineZapisActions";
import { connect } from "react-redux";
import FreeTime from "../components/online/FreeTime";
import Header from "../components/Header";
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "../redux/actionTypes";

const mapStateToProps = state => {

    return {
        profiles: state.onlineReducer.profiles,
        profile: state.onlineReducer.profile,
        specs: state.onlineReducer.specialities,
        spec: state.onlineReducer.speciality,
        dates: state.onlineReducer.dates,
        doc: state.onlineReducer.doctor,
        loading: state.commonReducer.loading,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSpecs: () => dispatch(getSpecs()),
        getProfiles: () => dispatch(getProfiles()),
        clearOnlineData: () => dispatch(clearOnlineData()),
        getDocs: (spec) => dispatch(getDocs(spec))
    };
};

function OnlineScreen(props){

    useEffect(() => {
        if(props.style === COSMETOLOGY_INTERFACE) props.history.push("/cosmo-online");
    }, [props.style]);

    //console.log(props);

    async function init() {
        try {
            await props.clearOnlineData();
            await props.getProfiles();
            await props.getSpecs();

            const spec = window.localStorage.getItem("selectedSpec");
            if(spec) await props.getDocs(spec);
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect( () => {
        init();
    }, []);

    return (
        <>
        <Header back={"/apps"} title={"ОЧНЫЙ ПРИËМ"} showOnDesktop={1} />
        <div className="row">
            <div className="col-12 col-lg-6 mx-auto d-flex1">
                <div className="card1 flex-grow-10">
                    <div className="card-body1 pb-5">
                        {
                            (props.profiles && props.profiles.length) && <Profiles />
                        }

                        {
                            (props.specs && props.specs.length && props.profile) && <Specs />
                        }

                        {
                            (props.spec && props.spec !== {}) && <Docs />
                        }

                        {
                            (props.spec && props.spec !== {} && props.doc && props.doc !== {}) && <Services />
                        }

                        {
                            (props.dates && props.dates.length > 0) && <FreeTime />
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineScreen);
