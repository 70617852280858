import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading, getFile } from '../redux/actions/commonActions';
import { withSnackbar } from 'notistack';

const mapStateToProps = state => {
    return {
        data: state.analyzesReducer.analyzesList,
        loading: state.commonReducer.loading,
        user: state.commonReducer.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFile: (fileId) => dispatch(getFile(fileId)),
        setLoading: () => dispatch(setLoading()),
    };
};

function FileScreen(props) {

    useEffect(() => {
        props.getFile(props.match.params.fileId);
    }, [props]);

    return(
        <div>

        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(FileScreen)));
