import * as ACTIONS from './actionTypes';
import logger from "./middleware/logger";
import {GET_USER_PROFILES} from "./actionTypes";

export function commonReducer(state = {}, action) {

    switch (action.type){

        case ACTIONS.GET_BANNERS + "_FULFILLED":
            state = {
                ...state,
                banners: action.payload
            };
            break;

        case ACTIONS.GET_BANNERS + "_REJECTED":
            state = {
                ...state,
                banners: []
            };
            break;

        case ACTIONS.GET_PROMOS + "_FULFILLED":
            state = {
                ...state,
                bonuses: action.payload['КоличествоБонусов']
            };
            break;

        case ACTIONS.GET_PROMOS + "_REJECTED":
            state = {
                ...state,
                bonuses: 0
            };
            break;

        case ACTIONS.GET_BALANCE + "_FULFILLED":
            state = {
                ...state,
                balance: action.payload
            };
            break;

        case ACTIONS.GET_BALANCE + "_REJECTED":
            state = {
                ...state,
                balance: 0
            };
            break;

        case ACTIONS.GET_BONUS_CODE + "_FULFILLED":
            state = {
                ...state,
                code: action.payload
            };
            break;

        case ACTIONS.GET_BONUS_CODE + "_REJECTED":
            state = {
                ...state,
                code: ""
            };
            break;

        case ACTIONS.GET_BONUS_HISTORY + "_FULFILLED":
            state = {
                ...state,
                bonusHistory: action.payload
            };
            break;

        case ACTIONS.GET_BONUS_HISTORY + "_REJECTED":
            state = {
                ...state,
                bonusHistory: []
            };
            break;

        case ACTIONS.GET_NOTIFICATIONS + "_FULFILLED":
            state = {
                ...state,
                notifications: action.payload
            };
            break;

        case ACTIONS.GET_NOTIFICATIONS + "_REJECTED":
            state = {
                ...state,
                notifications: []
            };
            break;

        case ACTIONS.RESET_PASSWORD + "_FULFILLED":
            state = {
                ...state,
                passwordWasReset: true
            };
            break;

        case ACTIONS.RESET_PASSWORD + "_REJECTED":
            state = {
                ...state,
                passwordWasReset: false
            };
            break;

        case ACTIONS.SET_BACKGROUND:
            state = {
                ...state,
                background: action.payload
            };
            break;

        case ACTIONS.SHOW_DOCTOR_PAGE:
            state = {
                ...state,
                showDoctor: action.payload
            };
            break;

        case ACTIONS.SHOW_IMG_MODAL:
            state = {
                ...state,
                modalBody: action.payload,
                showModal: true
            };
            break;

        case ACTIONS.GET_PROTOCOL + "_FULFILLED":
            state = {
                ...state,
                modalBody: action.payload,
                showModal: true
            };
            break;

        case ACTIONS.GET_PROTOCOL + "_REJECTED":
            state = {
                ...state,
                modalBody: "",
                showModal: false
            };
            break;

        case ACTIONS.GET_ANALYZ + "_FULFILLED":
            state = {
                ...state,
                modalBody: action.payload,
                showModal: true
            };
            break;

        case ACTIONS.GET_ANALYZ + "_REJECTED":
            state = {
                ...state,
                modalBody: "",
                showModal: false
            };
            break;

        case ACTIONS.HIDE_MODAL:
            state = {
                ...state,
                modalBody: "",
                showModal: false
            };
            break;

        case ACTIONS.DROP_CHECK_AUTH:
            state = {
                ...state,
                authChecked: action.payload
            };
            break;

        case ACTIONS.GET_PROFILES + "_FULFILLED":
            state = {
                ...state,
                user: {},
                profiles: action.payload
            };
            break;

        case ACTIONS.GET_PROFILES + "_REJECTED":
            state = {
                ...state,
                user: {},
                profiles: []
            };
            break;

        case ACTIONS.CHECK_AUTH + "_FULFILLED":
            state = {
                ...state,
                user: action.payload
            };
            break;

        case ACTIONS.CHECK_AUTH + "_REJECTED":
            state = {
                ...state,
                user: {},
                path: "/login"
            };
            break;

        case ACTIONS.LOGIN + "_FULFILLED":
            state = {
                ...state,
                user: action.payload,
                path: "/",
                profiles: [],
            };
            break;

        case ACTIONS.LOGIN + "_REJECTED":
            state = {
                ...state,
                user: {}
            };
            break;

        case ACTIONS.SET_ACTIVE_PROFILE + "_FULFILLED":
            state = {
                ...state,
                user: action.payload
            };
            break;

        case ACTIONS.LOGOUT + "_FULFILLED":
            state = {
                ...state,
                user: {},
                path: "/login"
            };
            break;

        case ACTIONS.GET_LIBS + "_FULFILLED":
            state = {
                ...state,
                libs: action.payload
            };
            break;

        case ACTIONS.REDIRECT:
            state = {
                ...state,
                path: action.payload
            };
            break;

        case ACTIONS.LOADING:
            state = {
                ...state,
                loading: action.payload
            };
            break;

        case ACTIONS.SET_USER_CHANGES + "_FULFILLED":
            state = {
                ...state,
                user: action.payload
            };
            break;
    }

    return state;
}

export function appointmentsReducer(state = {}, action) {
    switch (action.type) {

        case ACTIONS.GET_PAST_APPOINTMENTS + "_FULFILLED":
            state = {
                ...state,
                pastList: action.payload,
                appointmentData: {}
            };
            break;

        case ACTIONS.GET_PAST_APPOINTMENTS + "_REJECTED":
            state = {
                ...state,
                pastList: [],
                appointmentData: {},
            };
            break;

        case ACTIONS.GET_FUTURE_APPOINTMENTS + "_FULFILLED":
            let filtered = [];

            if(action.payload && action.payload.length > 0){
                filtered = action.payload.filter(
                    (u,i) => {
                        return Date.parse(u['Дата']) > new Date()
                    }
                );
            }

            state = {
                ...state,
                futureList: filtered,
                appointmentData: {}
            };
            break;

        case ACTIONS.GET_FUTURE_APPOINTMENTS + "_REJECTED":
            state = {
                ...state,
                futureList: [],
                appointmentData: {},
            };
            break;

        case ACTIONS.LOGOUT + "_FULFILLED":
            state = {
                ...state,
                pastList: [],
                futureList: [],
                appointmentData: {},
            };
            break;
    }

    return state;
}

export function analyzesReducer(state = {}, action) {
    switch (action.type) {

        case ACTIONS.GET_ANALYZES + "_FULFILLED":
            state = {
                ...state,
                analyzesList: action.payload,
                analyzData: {}
            };
            break;

        case ACTIONS.GET_ANALYZES + "_REJECTED":
            state = {
                ...state,
                analyzesList: [],
                analyzData: {}
            };
            break;

        case ACTIONS.LOGOUT + "_FULFILLED":
            state = {
                ...state,
                analyzesList: [],
                analyzData: {}
            };
            break;
    }

    return state;
}

export function onlineReducer(state = {}, action) {

    let times = [];
    let dates = [];
    let d = '';
    let uniqueDates = [];

    switch (action.type) {

        case ACTIONS.LOGIN + "_FULFILLED":
            state = {
                ...state,
                profile: action.payload.guid
            };
            break;

        case ACTIONS.SET_ACTIVE_PROFILE + "_FULFILLED":
            state = {
                ...state,
                profile: action.payload.guid
            };
            break;

        case ACTIONS.CLEAR_ONLINE_DATA:
            state = {
                ...state,
                specialities: [],
                speciality: {},
                doctors: [],
                doctor: {},
                services: [],
                service: {},
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.SHOW_MODAL:
            state = {
                ...state,
                showModal: action.payload
            };
            break;

        case ACTIONS.GET_USER_PROFILES + "_FULFILLED":
            state = {
                ...state,
                profiles: action.payload.profiles,
                profile: action.payload.profile
            };
            break;

        case ACTIONS.GET_USER_PROFILES + "_REJECTED":
            state = {
                ...state,
                profiles: [],
                profile: ""
            };
            break;

        case ACTIONS.SET_PROFILE:
            state = {
                ...state,
                profile: action.payload
            };
            break;

        case ACTIONS.GET_SPECIALITIES + "_FULFILLED":

            const selectedSpec = window.localStorage.getItem("selectedSpec");
            window.localStorage.removeItem("selectedSpec");

            state = {
                ...state,
                specialities: action.payload,
                speciality: selectedSpec || {},
                doctors: [],
                doctor: {},
                services: [],
                service: {},
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_SPECIALITIES + "_REJECTED":
            state = {
                ...state,
                specialities: [],
                speciality: {},
                doctors: [],
                doctor: {},
                services: [],
                service: {},
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_DOCTORS + "_FULFILLED":
            let selectedDoctor = window.localStorage.getItem("selectedDoc") || {};
            let dmsAllowed = false;

            window.localStorage.removeItem("selectedDoc");
            window.localStorage.removeItem("selectedSpec");

            if(action.payload.doctors.length === 1){
                selectedDoctor = action.payload.doctors[0]['Врач'];
                dmsAllowed = action.payload.doctors[0]['ДоступноДМС'];
            }

            state = {
                ...state,
                speciality: action.payload.speciality,
                doctors: action.payload.doctors,
                doctor: selectedDoctor,
                dmsAllowed: dmsAllowed,
                services: [],
                service: {},
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_DOCTORS + "_REJECTED":
            state = {
                ...state,
                doctors: [],
                doctor: {},
                services: [],
                service: "",
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_TELEDOCTORS + "_FULFILLED":
            state = {
                ...state,
                teledoctors: action.payload,
                services: [],
                service: {},
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_TELEDOCTORS + "_REJECTED":
            state = {
                ...state,
                doctors: [],
                teledoctors: [],
                doctor: {},
                services: [],
                service: "",
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.SELECT_ONLINE_DOCTOR + "_FULFILLED":

            times = [];
            dates = [];
            d = '';

            action.payload.times.forEach((v,i,arr) => {
                d = v['ПериодС'].split('T');
                dates.push(d[0]);
                times.push(v['ПериодС']);
            });

            uniqueDates = [...new Set(dates)];
            uniqueDates.forEach((date, i) => {
                let daytimes = [];
                times.forEach((time, k) => {
                    if(time.indexOf(date) >= 0) {
                        daytimes.push(time.substr(11,5));
                    }
                });

                uniqueDates[i] = {
                    date: date,
                    times: daytimes,
                    humanDate: date.substr(8,2) + "." + date.substr(5,2) //+ "." + date.substr(0,4)
                };
            });

            state = {
                ...state,
                doctors: action.payload.doctors,
                speciality: action.payload.speciality,
                doctor: action.payload.doctor,
                service: action.payload.service,
                price: action.payload.price,
                serviceName: action.payload.serviceName,
                serviceOnline: action.payload.serviceOnline,
                services: action.payload.services,
                dates: uniqueDates,
            };
            break;

        case ACTIONS.SELECT_ONLINE_DOCTOR + "_REJECTED":
            state = {
                ...state,
                doctors: [],
                speciality: "",
                doctor: {},
                service: {},
                services: [],
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_SERVICES + "_FULFILLED":
            let selectedService = "";

            const services = [];

            for(let i=0; i<action.payload.services.length; i++){

                const serviceSpec = action.payload.services[i]['Специальность'];

                if( serviceSpec === "00000000-0000-0000-0000-000000000000" || serviceSpec === state.speciality){
                    services.push(action.payload.services[i]);
                }
            }

            if(services.length === 1){
                selectedService = services[0]['Номенклатура'];
            }
            
            state = {
                ...state,
                doctor: action.payload.doctor,
                dmsAllowed: action.payload.dms,
                services: services,//action.payload.services,
                service: selectedService,
                dates: []
            };

            break;

        case ACTIONS.GET_SERVICES + "_REJECTED":
            state = {
                ...state,
                services: [],
                service: "",
                dates: [],
                dmsAllowed: false,
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        // Свободное время для приема
        case ACTIONS.GET_FREE_TIME + "_FULFILLED":
            times = [];
            dates = [];
            d = '';

            action.payload.times.forEach((v,i,arr) => {
                d = v['ПериодС'].split('T');
                dates.push(d[0]);
                times.push(v['ПериодС']);
            });

            uniqueDates = [...new Set(dates)];
            uniqueDates.forEach((date, i) => {
                let daytimes = [];
                times.forEach((time, k) => {
                    if(time.indexOf(date) >= 0) {
                        daytimes.push(time.substr(11,5));
                    }
                });

                uniqueDates[i] = {
                    date: date,
                    times: daytimes,
                    humanDate: date.substr(8,2) + "." + date.substr(5,2) //+ "." + date.substr(0,4)
                };
            });

            state = {
                ...state,
                dates: uniqueDates,
                service: action.payload.service,
                //dmsAllowed: action.payload.dms,
                price: action.payload.price,
                serviceName: action.payload.serviceName
            };
            break;

        case ACTIONS.GET_FREE_TIME + "_REJECTED":
            state = {
                ...state,
                dates: [],
                //service: "",
                dmsAllowed: false,
                price: false,
                serviceName: false
            };
            break;

            /*
        case ACTIONS.GET_PAYMENT_URL + "_FULFILLED":
            state = {
                ...state,
                paymentUrl: action.payload.token,
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

             */
    }

    return state;
}

export function notificationReducer (state = {}, action) {
    switch (action.type) {

        case ACTIONS.ENQUEUE_SNACKBAR:
            return [
                ...state,
                {
                    ...action.notification,
                },
            ];

        case 'REMOVE_SNACKBAR':
            let res = state.filter(
                notification => notification.key !== action.key,
            );
            return res;
            break;

        default:
            return state;
    }
}

export function registerReducer (state = {}, action) {
    switch (action.type) {
        case ACTIONS.SEND_REG_CODE + "_FULFILLED":
            state = {
                ...state,
                smsWasSent: true
            };
            break;

        case ACTIONS.SEND_REG_CODE + "_REJECTED":
            state = {
                ...state,
                //smsWasSent: false
            };
            break;

        case ACTIONS.CHECK_REG_CODE + "_FULFILLED":
            state = {
                ...state,
                smsWasSent: true,
                step: 2
            };
            break;

        case ACTIONS.CHECK_REG_CODE + "_REJECTED":
            state = {
                ...state,
                smsWasSent: true,
                step: 1
            };
            break;

        case ACTIONS.SUBMIT_REGISTRATION + "_FULFILLED":
            state = {
                ...state,
                smsWasSent: false,
                step: 1
            };
            break;

    }

    return state;
}

export function interfaceReducer (state = {}, action) {
    switch (action.type) {
        case ACTIONS.SWITCH_INTERFACE:
            state = {
                ...state,
                style: action.payload
            };

            window.localStorage.setItem("interface", action.payload);

            break;

        default:
            return state;
    }

    return state;
}

export function cosmetologyReducer (state = {}, action) {

    let times = [];
    let dates = [];
    let d = '';
    let uniqueDates = [];

    switch (action.type) {

        case ACTIONS.GET_COSMETOLOGY_DOCTORS + "_FULFILLED":

            let doctors = action.payload;

            if(state.filteredDocs && state.filteredDocs.length) {

                doctors = [];

                action.payload.forEach( item => {
                    state.filteredDocs.forEach( doc => {
                        if(item['Врач'] === doc['Врач']) doctors.push(item);
                    });
                });
            }

            //фильтруем список врачей по выбранной услуге
            let filtered = doctors;

            if(state.service) filtered = doctors.filter( doc => state.service['Сотрудники'].indexOf(doc['Врач']) > -1 );

            state = {
                ...state,
                doctorsFullList: action.payload,
                doctors: filtered
            };

            break;

        case ACTIONS.GET_COSMETOLOGY_DOCTORS + "_REJECTED":
            state = {
                ...state,
                doctors: []
            };
            break;

        case ACTIONS.COSMETOLOGY_SELECT_DOCTOR:
            state = {
                ...state,
                doctor: action.payload
            };
            break;

        case ACTIONS.GET_COSMETOLOGY_DATES + "_FULFILLED":
            times = [];
            dates = [];
            d = '';

            function splitTimes(blocks = []){
                blocks.forEach( block => {
                    d = block['ПериодС'].split('T');
                    dates.push(d[0]);
                    times.push(block['ПериодС']);
                });
            }

            action.payload.forEach( v => {
                if(state.doctor && state.doctor['Врач'] === v['Сотрудник']){ //если доктор выбран - фильтруем время приема только по нему
                    splitTimes(v['Блоки']);
                }

                if(!state.doctor && state.service && state.service['Сотрудники'].indexOf(v['Сотрудник']) > -1){ //если услуга выбрана - фильтруем время приема
                    splitTimes(v['Блоки']);
                }

                if(!state.doctor && !state.service) splitTimes(v['Блоки']);
            });

            uniqueDates = [...new Set(dates)];

            let daytimes = {};

            uniqueDates.forEach((date, i) => {

                daytimes = {};

                times.forEach((time, k) => {
                    if(time.indexOf(date) >= 0) {
                        let t = time.substr(11,5);
                        //daytimes.push(time.substr(11,5));
                        if(!daytimes[t]) daytimes[t] = new Set;

                        action.payload.forEach((v,i,arr) => {
                            v['Блоки'].forEach( block => {
                                if(block['ПериодС'] === time) {
                                    daytimes[t].add(v['Сотрудник']);
                                }
                            });
                        });
                    }
                });

                uniqueDates[i] = {
                    date: date,
                    times: daytimes,
                    humanDate: date.substr(8,2) + "." + date.substr(5,2) //+ "." + date.substr(0,4)
                };
            });

            state = {
                ...state,
                dates: uniqueDates
            };
            break;

        case ACTIONS.GET_COSMETOLOGY_DATES + "_REJECTED":
            state = {
                ...state,
                dates: [],
                dmsAllowed: false,
                price: false,
                serviceName: false
            };
            break;

        case ACTIONS.COSMETOLOGY_SELECT_TIME:

            let filteredDocs = state.doctorsFullList;

            if(state.doctorsFullList && state.doctorsFullList.length && action.payload && action.payload.doctors){
                filteredDocs = state.doctorsFullList.filter( doc => action.payload.doctors.has(doc['Врач']) );
            }

            state = {
                ...state,
                date: action.payload.date,
                time: action.payload.time,
                humanDate: action.payload.humanDate,
                filteredDocs: filteredDocs
            }
            break;

        case ACTIONS.GET_COSMETOLOGY_SERVICES + "_FULFILLED":
            state = {
                ...state,
                services: action.payload,
                service: null
            };
            break;

        case ACTIONS.GET_COSMETOLOGY_SERVICES + "_REJECTED":
            state = {
                ...state,
                services: [],
                service: null
            };
            break;

        case ACTIONS.COSMETOLOGY_SELECT_SERVICE:
            state = {
                ...state,
                service: action.payload
            }
            break;

        case ACTIONS.GET_COSMETOLOGY_SPECIALITIES + "_FULFILLED":
            state = {
                ...state,
                specialities: action.payload,
                speciality: {},
                doctors: [],
                doctor: null,
                services: [],
                service: null,
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_COSMETOLOGY_SPECIALITIES + "_REJECTED":
            state = {
                ...state,
                specialities: [],
                speciality: {},
                doctors: [],
                doctor: null,
                services: [],
                service: null,
                dates: [],
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        default:
            return state;
    }

    return state;
}

export function paymentReducer(state = {}, action){
    switch (action.type) {

        case ACTIONS.GET_PAYMENT_URL + "_FULFILLED":
            state = {
                ...state,
                token: action.payload.token,
                appID: action.payload.zapisID,
                isOnline: action.payload.isOnline,
                showModal: true,
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.GET_PAYMENT_URL + "_REJECTED":
            state = {
                ...state,
                token: undefined,
                appID: undefined,
                isOnline: undefined,
                showModal: false,
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.SHOW_PAYMENT_MODAL:
            state = {
                ...state,
                token: action.payload ? state.token : undefined,
                showModal: action.payload,
                price: false,
                serviceName: false,
                serviceOnline: false
            };
            break;

        case ACTIONS.CANCEL_APPOINTMENT + "_FULFILLED":
            state = {
                ...state,
                appID: undefined,
                isOnline: undefined
            };
            break;
    }

    //console.log(state);

    return state;
}






