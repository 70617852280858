import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Header from "../components/Header";

function PolicyScreen(){
    return (
        <div className={"container py-4"}>
                <Header back={"/main"} title={"Политика обработки персональных данных"} showOnDesktop={1} />
                <div className={"mt-4"}>
                        ПОЛОЖЕНИЕ об обработке и защите персональных данных в ООО МЦ «МЕДЕОР»
                        <br/><br/>1. ВВЕДЕНИЕ
                        <br/><br/>1.1 Цель
                        Цель данного Положения — определение порядка обработки персональных данных; обеспечение
                        защиты прав и свобод субъектов персональных данных при обработке их персональных данных, а
                        также установление ответственности должностных лиц, организующих/осуществляющих обработку
                        персональных данных за невыполнение требований норм, регулирующих обработку и защиту
                        персональных данных.
                        <br/><br/>1.2. Термины и определения
                        Персональные данные - любая информация, относящаяся к прямо или косвенно определенному
                        или определяемому физическому лицу (субъекту персональных данных).
                        Субъект персональных данных – физическое лицо, являющееся сотрудником, клиентом
                        Общества или иным лицом, состоящим с Обществом в гражданско-правовых и иных отношениях;
                        Конфиденциальность персональных данных — обязательное для соблюдения ответственного
                        лица, получившего доступ к персональным данным, требование не допускать их распространения без
                        их согласия или наличия иного законного основания;
                        Работник – физическое лицо, заключившее в установленном законодательством РФ порядке
                        трудовой договор с Обществом и выполняющее за плату определенную трудовую функцию.
                        Распространение персональных данных - действия, направленные на раскрытие персональных
                        данных неопределенному кругу лиц.
                        Защита персональных данных – комплекс мер для охраны персональных данных, исключающих
                        случайное или несанкционированное разрушение, или случайную их утрату, а также неправомерный
                        доступ к ним и использование.
                        Использование персональных данных — действия (операции) с персональными данными,
                        совершаемые уполномоченным лицом Общества в целях принятия решений или совершения иных
                        действий, порождающих юридические последствия в отношении субъектах персональных данных либо
                        иным образом затрагивающих их права и свободы субъекта персональных данных, либо других лиц;
                        Блокирование персональных данных - временное прекращение обработки персональных
                        данных (за исключением случаев, если обработка необходима для уточнения персональных
                        данных).
                        Уничтожение персональных данных - действия, в результате которых становится
                        невозможным восстановить содержание персональных данных в информационной системе
                        персональных данных и (или) в результате которых уничтожаются материальные носители
                        персональных данных.
                        Обезличивание персональных данных — действия, в результате которых невозможно определить
                        принадлежность персональных данных конкретному субъекту персональных данных;
                        Обработка персональных данных - действия (операции) с персональными данными, включая
                        сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование,
                        распространение (в том числе передачу), обезличивание, блокирование, уничтожение персональных
                        данных.
                        Общедоступные персональные данные — персональные данные, доступ неограниченного круга
                        лиц к которым предоставлен с согласия субъекта персональных данных или на которые в соответствии
                        с федеральными законами не распространяется требование соблюдения конфиденциальности.
                        Информация — сведения (сообщения, данные) независимо от формы их представления;
                        Документированная информация — зафиксированная на материальном носителе путем
                        документирования информация с реквизитами, позволяющими определить такую информацию или ее
                        материальный носитель.
                        Приложение № 1 к приказу
                        от «_10» августа 2018 г. № 41
                        Система защиты персональных данных – организационные меры и средства защиты
                        информации (в том числе шифровальные (криптографические) средства, средства предотвращения
                        несанкционированного доступа, утечки информации по техническим каналам, программнотехнических воздействий на технические средства обработки персональных данных), а также
                        используемые в информационной системе информационные технологии.
                        <br/><br/>1.3 Принятые сокращения
                        ПДн – персональные данные;
                        ИСПДн – информационные системы персональных данных;
                        СЗПДн – система защиты персональных данных;
                        РФ – Российская Федерация;
                        Общество – ООО МЦ «МЕДЕОР»
                        <br/><br/>2. ОБЩИЕ ПОЛОЖЕНИЯ
                        Настоящее Положение об обработке и защите персональных данных (далее – Положение)
                        разработано в соответствии с Конституцией РФ, Трудовым кодексом РФ №197-ФЗ от 30.12.2001г.,
                        Федеральным законом РФ "О персональных данных" № 152-ФЗ от 27.07.2006г., Указом Президента РФ
                        "Об утверждении перечня сведений конфиденциального характера" №188 от 06.03.1997г., приказом
                        Минкультуры РФ от 25.08.2010 № 558 "Об утверждении "Перечня типовых управленческих архивных
                        документов, образующихся в процессе деятельности государственных органов, органов местного
                        самоуправления и организаций, с указанием сроков хранения" и другими нормативно-правовыми
                        актами.
                        Персональные данные относятся к категории информации ограниченного доступа. Обеспечения
                        конфиденциальности персональных данных не требуется:
                        1) в случае обезличивания персональных данных;
                        2) в отношении общедоступных персональных данных (Приложение 6 к настоящему
                        Положению).
                        Настоящее Положение вступает в силу с момента его утверждения Директором, действует
                        бессрочно, до замены его новым Положением и является обязательным для исполнения всеми
                        сотрудниками обществом, имеющими доступ к персональным данным заемщиков, иных клиентов
                        общества и их представителей, сотрудников общества, состоящих в трудовых отношениях с
                        обществом, и иных лиц, состоящих в гражданско-правовых и иных отношениях с обществом.
                        Все допущенные к обработке ПДн сотрудники общества должны быть ознакомлены с
                        настоящим Положением под роспись. По вновь принятым сотрудникам листы ознакомления хранятся в
                        личном деле.
                        1С относится к ИСПДн общества.
                        <br/><br/>3. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛНЫХ ДАННЫХ
                        Целью обработки перечисленных ниже персональных данных является:
                        - осуществление возложенных на общество законодательством Российской Федерации функций, в
                        частности, в соответствии с Налоговым кодексом Российской Федерации, "Об индивидуальном
                        (персонифицированном) учете в системе обязательного пенсионного страхования", "О персональных
                        данных";
                        - организация учета сотрудников общества для обеспечения соблюдения законов и иных
                        нормативно-правовых актов, содействия сотруднику в трудоустройстве, обучении, продвижении по
                        службе, пользования различного вида льготами в соответствии с Трудовым кодексом Российской
                        Федерации, Налоговым кодексом Российской Федерации, федеральными законами, в частности: "Об
                        индивидуальном (персонифицированном) учете в системе обязательного пенсионного страхования", "О
                        персональных данных", а также Уставом.
                        - предоставления медицинских услуг пациентам.
                        Сведениями, составляющими персональные данные, является любая информация, относящаяся к
                        определенному или определяемому на основании такой информации, в том числе:
                        <br/><br/>1. Перечень ПДн сотрудников:
                        фамилия, имя, отчество (в т.ч. прежние), дата и место рождения;
                        паспортные данные или данные иного документа, удостоверяющего личность (серия, номер,
                        дата выдачи, наименование органа, выдавшего документ) и гражданство;
                         адрес места жительства (по паспорту и фактический) и дата регистрации по месту жительства
                        или по месту пребывания;
                        номера телефонов (мобильного и домашнего), в случае их регистрации на субъекта
                        персональных данных или по адресу его места жительства (по паспорту);
                         сведения об образовании, квалификации и о наличии специальных знаний или специальной
                        подготовки (серия, номер, дата выдачи диплома, свидетельства, аттестата или другого документа об
                        окончании образовательного учреждения, наименование и местоположение образовательного
                        учреждения, дата начала и завершения обучения, факультет или отделение, квалификация и
                        специальность по окончании образовательного учреждения, ученая степень, ученое звание, владение
                        иностранными языками и другие сведения);
                         сведения о повышении квалификации и переподготовке (серия, номер, дата выдачи документа о
                        повышении квалификации или о переподготовке, наименование и местоположение образовательного
                        учреждения, дата начала и завершения обучения, квалификация и специальность по окончании
                        образовательного учреждения и другие сведения);
                         сведения о трудовой деятельности (данные о трудовой занятости на текущее время с полным
                        указанием должности, подразделения, наименования, адреса и телефона организации, а также
                        реквизитов других организаций с полным наименованием занимаемых ранее в них должностей и
                        времени работы в этих организациях, а также другие сведения);
                         сведения о номере, серии и дате выдачи трудовой книжки (вкладыша в нее) и записях в ней;
                         содержание и реквизиты трудового договора с сотрудником Общества или гражданскоправового договора с гражданином;
                         сведения о заработной плате (номера счетов для расчета с сотрудниками, данные зарплатных
                        договоров с клиентами, в том числе номера их спец карт счетов, данные по окладу, надбавкам, налогам
                        и другие сведения);
                         сведения о воинском учете военнообязанных лиц и лиц, подлежащих призыву на военную
                        службу (серия, номер, дата выдачи, наименование органа, выдавшего военный билет, военно-учетная
                        специальность, воинское звание, данные о принятии/снятии на(с) учет(а) и другие сведения);
                         сведения о семейном положении (состояние в браке, данные свидетельства о заключении брака,
                        фамилия, имя, отчество супруга(и), паспортные данные супруга(и), степень родства, фамилии, имена,
                        отчества и даты рождения других членов семьи, иждивенцев и другие сведения);
                         сведения о номере и серии страхового свидетельства государственного пенсионного
                        страхования;
                         сведения об идентификационном номере налогоплательщика;
                         сведения из страховых полисов обязательного (добровольного) медицинского страхования (в
                        том числе данные соответствующих карточек медицинского страхования);
                         сведения, указанные в оригиналах и копиях приказов общества по личному составу и
                        материалах к ним;
                         сведения о государственных и ведомственных наградах, почетных и специальных званиях,
                        поощрениях (в том числе наименование или название награды, звания или поощрения, дата и вид
                        нормативного акта о награждении или дата поощрения) сотрудников общества;
                         сведения о временной нетрудоспособности сотрудников общества.
                         табельный номер сотрудника общества;
                         сведения о социальных льготах и о социальном статусе (серия, номер, дата выдачи,
                        наименование органа, выдавшего документ, являющийся основанием для предоставления льгот и
                        статуса, и другие сведения).
                        Для защиты персональных данных и систем обработки персональных данных в обществе должны
                        быть определены и зафиксированы цели такой обработки, включающие:
                         объем и содержание персональных данных;
                         сроки обработки и сроки хранения персональных данных;
                        необходимость получения согласия субъектов персональных данных.
                        <br/><br/>2. Перечень ПДн клиентов:
                        фамилия, имя, отчество (в т.ч. прежние), дата и место рождения;
                        паспортные данные и данные иного документа, удостоверяющего личность (серия, номер, дата
                        выдачи, наименование органа, выдавшего документ) и гражданство;
                         адрес места жительства (по паспорту и фактический) и дата регистрации по месту жительства
                        или по месту пребывания;
                        номера телефонов (мобильного и домашнего), в случае их регистрации на субъекта
                        персональных данных или по адресу его места жительства (по паспорту);
                         сведения о состоянии здоровья;
                         сведения об интимной жизни;
                         сведения о воинском учете военнообязанных лиц и лиц, подлежащих призыву на военную
                        службу (серия, номер, дата выдачи, наименование органа, выдавшего военный билет, военно-учетная
                        специальность, воинское звание, данные о принятии/снятии на(с) учет(а) и другие сведения);
                         сведения о семейном положении (состояние в браке, данные свидетельства о заключении брака,
                        фамилия, имя, отчество супруга(и), паспортные данные супруга(и), степень родства, фамилии, имена,
                        отчества и даты рождения других членов семьи, иждивенцев и другие сведения);
                        Классификация ИСПДн возлагается на комиссию из числа сотрудников общества. По
                        результатам классификации ИСПДн составляется «Акт определения уровня защищенности», который
                        утверждается директором.
                        <br/><br/>4. КЛАССИФИКАЦИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ
                        В соответствии со степенью тяжести последствий потери свойств безопасности персональных
                        данных для субъекта персональных данных, персональные данные классифицируются на:
                        персональные данные, отнесенные в соответствии с Федеральным законом ―О персональных
                        данных‖ к специальным категориям персональных данных;
                        персональные данные, отнесенные в соответствии с Федеральным законом ―О персональных
                        данных‖ к биометрическим персональным данным;
                        персональные данные, которые не могут быть отнесены к специальным категориям
                        персональных данных, к биометрическим персональным данным, к общедоступным или обезличенным
                        персональным данным;
                        персональные данные, отнесенные в соответствии с Федеральным законом ―О персональных
                        данных‖ к общедоступным или обезличенным персональным данным.
                        <br/><br/>5. ПОДХОД К ОТНЕСЕНИЮ К ИНФОРМАЦИОННЫМ СИСТЕМАМ
                        ПЕРСОНАЛЬНЫХ ДАННЫХ (ИСПДн)
                        Согласно статье 19 Федерального закона "О персональных данных" необходимо защитить
                        персональные данные от случайного или несанкционированного доступа, уничтожения, изменения,
                        блокирования доступа и других несанкционированных действий. На основании Постановления
                        Правительства РФ от 1 ноября 2012 г. N 1119 "Об утверждении требований к защите персональных
                        данных при их обработке в информационных системах персональных данных" необходимо выделить
                        уровень защищенности ПДн.
                        <br/><br/>6. ПОЛУЧЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Персональные данные общества получает у субъекта персональных данных.
                        Субъект персональных данных обязан предоставлять достоверные сведения о себе и
                        своевременно сообщать ему об изменении своих персональных данных. Общество имеет право
                        проверять достоверность сведений, предоставленных субъектом персональных данных, сверяя данные
                        с имеющимися документами.
                        При обработке ПДн, в том числе при внесении изменений в ПДн, общество обязано обеспечить их
                        точность, достоверность и актуальность, в том числе по отношению к целям обработки ПДн.
                        Если персональные данные были получены не от субъекта персональных данных, за исключением
                        случаев, если персональные данные были предоставлены обществом на основании федерального
                        закона или если персональные данные являются общедоступными, общество до начала обработки
                        таких персональных данных предоставляет субъекту персональных данных следующую информацию:
                        1) наименование или фамилия, имя, отчество его представителя и адрес общества;
                        2) цель обработки персональных данных и ее правовое основание;
                        3) предполагаемые пользователи персональных данных;
                        4) установленные настоящим Федеральным законом права субъекта персональных данных.

                        <br/><br/>7. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Обработка персональных данных осуществляется исключительно в целях обеспечения
                        соблюдения законов и иных нормативных правовых актов, а так же в соответствии с инструкцией по
                        эксплуатации ИСПДн.
                        Общество не имеет права обрабатывать персональные данные, касающиеся политических,
                        религиозных и иных убеждений. В случаях, непосредственно связанных с вопросами трудовых
                        отношений, в соответствии со статьей 24 Конституции Российской Федерации работодатель вправе
                        получать и обрабатывать данные о частной жизни сотрудника только с его письменного согласия.
                        Общество не имеет права получать и обрабатывать персональные данные, касающиеся членства в
                        общественных объединениях или профсоюзной деятельности, за исключением случаев,
                        предусмотренных федеральным законом.
                        В соответствии с Федеральными законами РФ обработка персональных данных может
                        осуществляться обществом с письменного согласия субъектов персональных данных или их законных
                        представителей, которое может быть включено в текст соответствующего договора, либо получено в
                        виде отдельного документа, форма которого приведена в Приложении 1 к настоящему Положению.
                        Обработка персональных данных работника регламентируется нормативными правовыми актами,
                        определяющими порядок делопроизводства по различным направлениям деятельности.
                        Согласие на обработку персональных данных работников приведено в Приложении 7.
                        Согласие на обработку персональных данных клиентов оформляется по форме Приложения 2,
                        и/или в анкете клиента. Так же согласование на обработку персональных данных включается в
                        договоры, заключаемые обществом с клиентами.
                        В случае изменения ПДн с целью обеспечения их точности, достоверности и актуальности, в том
                        числе по отношению к целям обработки ПДн сотрудники, имеющие доступ к соответствующим ПДн
                        вносят необходимые дополнения, изменения и уточнения после получения необходимой информации
                        для соблюдения действующих норм законодательства.
                        <br/><br/>8. ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Хранение персональных данных осуществляется в порядке, исключающем их утрату или их
                        неправомерное использование.
                        Персональные данные работников хранятся на бумажных и электронных носителях у специалиста
                        отдела кадров (учетные документы работников), а также в бухгалтерии (учетные документы по оплате
                        труда).
                        Персональные данные работников, хранящиеся на электронных носителях, обеспечиваются
                        защитой с использованием паролей и шифров.
                        Персональные данные клиентов хранятся на бумажных носителях у администраторов общества и
                        в архиве. Персональные данные клиентов хранятся на электронных носителях в помещении общества в
                        серверном помещении.
                        <br/><br/>9. ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ
                        Перечень должностей сотрудников, имеющих доступ к персональным данным, утверждается
                        приказом по обществу.
                        Доступ сотрудников общества к персональным данным, осуществляется только для выполнения
                        их должностных обязанностей.
                        Для работы с ИСПДн ответственный сотрудник по безопасности персональных данных проводит
                        инструктаж о факте обработки сотрудниками персональных данных, категориях обрабатываемых
                        персональных данных и требованиях по обработке и обеспечении безопасности персональных данных
                        в части, касающейся их должностных обязанностей.
                        Сотрудники, допущенные к работе с персональными данными, принимают на себя обязательство
                        о соблюдении конфиденциальности персональных данных.
                        В случае если общество оказывают услуги юридические лица на основании заключенных
                        договоров (либо иных оснований) и в силу данных договоров они должны иметь доступ к
                        персональным данным, то соответствующие данные предоставляются обществом только после
                        подписания с ними соглашения о неразглашении информации ограниченного доступа.
                        Допускается наличие в договорах пунктов о неразглашении информации ограниченного доступа,
                        в том числе предусматривающих защиту персональных данных.
                        Процедура оформления доступа сотрудника к ПДн включает в себя:
                         ознакомление сотрудника под роспись с внутренними документами, регулирующими обработку
                        персональных данных.
                        истребование с сотрудника письменного обязательства о соблюдении конфиденциальности
                        персональных данных и соблюдении правил их обработки, подготовленного по установленной форме
                        (Приложение 2 к настоящему Положению). Данное обязательство хранится в личном деле сотрудника.
                        Сотрудники общества, имеющие доступ к персональным данным, имеют право получать только
                        те персональные данные, которые необходимы им для выполнения конкретных должностных
                        обязанностей.
                        Передача ПДн между пользователями ресурсов ПДн, предусматривает передачу ПДн только
                        между работниками общества, имеющими соответствующий доступ к ПДн по средствам локальной
                        сети общества.
                        Доступ к персональным данным без специального разрешения имеют сотрудники, занимающие в
                        организации следующие должности:
                         Директор;
                         Заместители директора;
                         Специалист отдела кадров;
                         бухгалтера, осуществляющие расчет заработной платы – в части документов, необходимых для
                        начисления заработной платы и персонифицированного учета;
                        Указанные лица имеют право получать только те персональные данные, которые необходимы для
                        выполнения конкретных производственных функций.
                        Руководитель подразделения может иметь доступ к данным работников о дате рождения,
                        образовании, опыте работы, местожительстве, домашнем и контактном телефонах, а также сведениям
                        по оплате труда подчиненных работников.
                        Информация о поощрениях и взысканиях может быть доведена до сведения работников общества
                        по усмотрению руководителя соответствующего подразделения.
                        Допуск к персональным данным других сотрудников общества, не имеющих надлежащим
                        образом оформленного доступа, запрещается.
                        Субъект персональных данных (его законный представитель) имеет право на доступ к своим
                        персональным данным, включая право на получение копии любой записи (за исключением случаев,
                        предусмотренных федеральным законом), содержащей его персональные данные.
                        Специалист отдела кадров вправе передавать персональные данные сотрудника в бухгалтерию и
                        иные структурные подразделения в случае необходимости исполнения сотрудниками соответствующих
                        структурных подразделений своих должностных обязанностей. При передаче персональных данных
                        сотрудника менеджер по персоналу предупреждает лиц, получающих данную информацию, о том, что
                        эти данные могут быть использованы лишь в целях, для которых они сообщены.
                        Работа с машинными носителями персональных данных осуществляется в соответствии с
                        «Порядком обращения со съемными машинными носителями персональных данных».
                        <br/><br/>10. УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Общество прекращает обработку персональных данных и уничтожает собранные персональные
                        данные, если иное не установлено законодательством РФ, в следующих случаях в сроки,
                        установленные законодательством РФ:
                        по достижении целей обработки или при утрате необходимости в их достижении;
                        по требованию субъекта персональных данных или Уполномоченного органа по защите прав
                        субъектов персональных данных
                         если персональные данные являются неполными, устаревшими, недостоверными или не
                        являются необходимыми для заявленной цели обработки;
                        при отзыве субъектом персональных данных согласия на обработку своих персональных
                        данных, если такое согласие требуется в соответствии с законодательством РФ;
                        при невозможности устранения допущенных нарушений при обработке персональных данных.
                        Сроки обработки перечисленных в данном Положении персональных данных определяются в
                        соответствии со сроком действия договора с субъектом персональных данных, приказом Минкультуры
                        РФ от 25.08.2010 № 558 "Об утверждении "Перечня типовых управленческих архивных документов,
                        образующихся в процессе деятельности государственных органов, органов местного самоуправления и
                        организаций, с указанием сроков хранения", сроком исковой давности.
                        Уничтожение персональных данных, размещенных на перемещаемых носителях, осуществляется
                        следующим образом:
                        магнитные носители стираются c помощью программного обеспечения, которое отвечает
                        стандартам ГОСТ и обеспечивает гарантированное уничтожение информации.
                         оптические, бумажные и иные носители уничтожаются путем разрезания, сжигания,
                        механического уничтожения.
                        При выводе из эксплуатации или замене носителей персональных данных, входящих в состав
                        автоматизированных рабочих мест и серверов их уничтожение осуществляется в том же порядке что и
                        для перемещаемых носителей.
                        Уничтожение персональных данных происходит в выше указанных случаях и осуществляется
                        следующим образом:
                         записи на магнитных носители стираются c помощью программного обеспечения, которое
                        отвечает стандартам ГОСТ и обеспечивает гарантированное уничтожение информации.
                         оптические, бумажные и иные носители уничтожаются путем разрезания, сжигания,
                        механического уничтожения.
                        Отбор носителей и их уничтожение осуществляются в соответствии с настоящим Положением и
                        производятся постоянно действующей комиссией утверждаемой приказом директором общества, в
                        следующем составе: заместитель директора по правовым вопросам; начальник отдела компьютерного
                        обеспечения; главный врач. По факту уничтожения составляется акт установленной формы
                        (Приложение 3 к настоящему Положению).
                        <br/><br/>11. ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ТРЕТЬИМ ЛИЦАМ
                        Передача персональных данных третьим лицам осуществляется только с письменного согласия
                        субъекта персональных данных, которое может быть включено в состав согласия по форме
                        Приложения 2 к настоящему Положению и должно включать в себя:
                        фамилию, имя, отчество, адрес, номер основного документа, удостоверяющего его личность,
                        сведения о дате выдачи указанного документа и выдавшем его органе;
                        наименование и адрес общества, получающей согласие;
                        цель передачи персональных данных;
                        перечень персональных данных, на передачу которых дает согласие сотрудник или клиент;
                        наименование третьего лица, которому будут переданы персональные данные конкретного
                        субъекта персонального субъекта;
                         срок, в течение которого действует согласие, а также порядок его отзыва.
                        Согласие на передачу персональных данных третьим лицам не требуется в случаях, когда
                        передача персональных данных необходима для защиты жизни, здоровья или иных жизненно важных
                        интересов субъекта персональных данных, если получение согласия субъекта персональных данных
                        невозможно, а также в случаях, установленных федеральным законом и настоящим Положением.
                        Не допускается передача персональных данных в коммерческих целях без письменного согласия,
                        которое может быть оформлено по форме, приведенной в Приложении 1 к настоящему Положению.
                        Имеющиеся у общества персональные данные при наличии соответствующего согласия субъекта
                        персональных данных могут быть переданы для обработки третьим лицам на основании договора,
                        существенным условием которого является обязанность обеспечения третьим лицом
                        конфиденциальности персональных данных и безопасности персональных данных при их обработке.
                        В случае необходимости передачи персональных данных третьим лицам, с которыми общество не
                        имеет договорных отношений, должен составляться акт приема-передачи документов (иных
                        материальных носителей), содержащих персональные данные, содержащий обязательство лица,
                        получающего такие данные, использовать полученную информацию ограниченного доступа лишь в
                        целях, для которых она сообщена, и обеспечивать конфиденциальность и безопасность персональных
                        данных при их обработке, а также предупреждение об ответственности за незаконное использование
                        данной информации ограниченного доступа в соответствии с федеральными законами (форма
                        приведена в Приложении 4 к настоящему Положению).
                        Ответственность за соблюдение вышеуказанного порядка предоставления персональных данных
                        несет сотрудник, а также руководитель структурного подразделения общества, осуществляющего
                        передачу персональных данных третьим лицам.
                        Законному представителю субъекта персональных данных персональные данные передаются в
                        порядке, установленном действующим законодательством.
                        Предоставление персональных данных государственным органам производится в соответствии с
                        требованиями действующего законодательства.
                        Документы, содержащие персональные данные, могут быть отправлены через организацию
                        федеральной почтовой связи. При этом должна быть обеспечена их конфиденциальность. Документы,
                        содержащие персональные данные вкладываются в конверт, к нему прилагается сопроводительное
                        письмо. На конверте делается надпись о том, что содержимое конверта является информация
                        ограниченного доступа, и за незаконное ее разглашение законодательством предусмотрена
                        ответственность. Далее, конверт с сопроводительным письмом вкладывается в другой конверт, на
                        который наносятся только реквизиты, предусмотренные почтовыми правилами для заказных почтовых
                        отправлений.
                        Использование персональных данных работника допустимо только в соответствии с целями,
                        определившими их получение. При передаче персональных данных работника, в том числе третьим
                        лицам, необходимо предупреждать их о том, что эти данные могут быть использованы лишь в целях,
                        для которых они сообщены, и требовать от этих лиц подтверждения того, что это правило соблюдено.
                        Не допускается:
                         сообщать персональные данные работника третьей стороне без письменного согласия
                        работника, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни и
                        здоровью работника, а также в случаях, установленных федеральным законодательством;
                         сообщать персональные данные работника в коммерческих целях без его письменного согласия;
                         запрашивать информацию о состоянии здоровья работника, за исключением тех сведений,
                        которые относятся к вопросу о возможности выполнения работником трудовой функции.
                        Запрещается передача персональных данных по телефону.
                        Иные права, обязанности, действия работников, в трудовые обязанности которых входит
                        обработка персональных данных работника, определяются должностными инструкциями.
                        Трансграничная передача ПДн не осуществляется.
                        <br/><br/>12. ОРГАНИЗАЦИЯ ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Защита персональных данных от неправомерного их использования или утраты обеспечивается
                        обществом.
                        Защите подлежит:
                        информация о персональных данных субъекта;
                         документы, содержащие персональные данные субъекта;
                        машинные носители, содержащие персональные данные субъекта.
                        Защита сведений, хранящихся в электронных базах данных общества, от несанкционированного
                        доступа, искажения и уничтожения информации, а также от иных неправомерных действий,
                        обеспечивается Системой защиты информации.
                        Общую организацию защиты персональных данных лиц осуществляет заместитель директора по
                        правовым вопросам.
                        Контроль за защитой персональных данных клиентов осуществляет заместитель директора по
                        правовым вопросам.
                        Контроль за защитой персональных данных сотрудников осуществляет специалист отдела кадров.
                        Заместитель директора по правовым вопросам:
                         устанавливает общие требования к обеспечению безопасности ПДн при их обработке в ИСПДн;
                         определяет порядок проведения классификации ИСПДн;
                         обеспечивает общий контроль за соблюдением мероприятий по защите ПДн;
                         организует разбирательство и составление заключений по фактам несоблюдения условий
                        хранения носителей ПДн, использования средств защиты информации, которые могут привести к
                        нарушению конфиденциальности ПДн или другим нарушениям, приводящим к снижению уровня
                        защищенности ПДн, разработку и принятие мер по предотвращению возможных опасных последствий
                        подобных нарушений;
                        проведение мероприятий по организации обеспечения безопасности ПДн, обрабатываемых в
                        ИСПДн.
                        проведение мероприятий по техническому обеспечению безопасности ПДн, обрабатываемых в
                        ИСПДн.
                        правовые вопросы обработки ПДн в обществе;
                         работу с обращением граждан по вопросу ПДн.
                        <br/><br/>13. ПРАВА СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Субъект персональных данных имеет следующие права:
                        право на получение сведений о обществе, о месте его нахождения,
                        право на получение сведений о наличии в обществе ПДн, относящихся к соответствующему
                        субъекту ПДн,
                        право на ознакомление с ПДн, относящимися к соответствующему субъекту персональных
                        данных, за исключением случаев, предусмотренных частью 5 статьи 14 Федерального закона от
                        27.07.2006 N152-ФЗ "О персональных данных",
                        право требовать от общества уточнения своих ПДн, их блокирования или уничтожения в случае,
                        если ПДн являются неполными, устаревшими, недостоверными, незаконно полученными или не
                        являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом
                        меры по защите своих прав,
                        право на обжалование в уполномоченный орган по защите прав субъектов ПДн или в судебном
                        порядке неправомерные действия или бездействия общества при обработке и защите его ПДн.
                        Субъект ПДн имеет право на получение при обращении или при получении запроса информации,
                        касающейся обработки его ПДн, в том числе содержащей:
                        1) подтверждение факта обработки ПДн обществом, а также цель такой обработки;
                        2) способы обработки ПДн, применяемые обществом;
                        3) сведения о должностных лицах, которые имеют доступ к ПДн или которым может быть
                        предоставлен такой доступ;
                        4) перечень обрабатываемых ПДн и источник их получения;
                        5) сроки обработки ПДн, в том числе сроки их хранения;
                        6) сведения о том, какие юридические последствия для субъекта ПДн может повлечь за
                        собой обработка его ПДн.
                        Сотрудники общества имеют также следующие права:
                        1) право требовать об извещении работодателем всех лиц, которым ранее были сообщены
                        неверные или неполные ПДн сотрудника, обо всех произведенных в них исключениях, исправлениях
                        или дополнениях.
                        2) на полную информацию об их персональных данных и обработке этих данных;
                        3) на свободный бесплатный доступ к своим персональным данным, включая право на
                        получение копий любой записи, содержащей персональные данные работника, за исключением
                        случаев, предусмотренных действующим федеральным законодательством;
                        4) на определение своих представителей для защиты своих персональных данных;
                        5) на доступ к относящимся к ним медицинским данным с помощью медицинского
                        специалиста по их выбору;
                        6) на обжалование в суд любых неправомерных действий или бездействия работодателя при
                        обработке и защите его персональных данных.
                        <br/><br/>14. ПОРЯДОК ОБРАБОТКИ ОБРАЩЕНИЙ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ
                        ДАННЫХ (ИЛИ ИХ ЗАКОННЫХ ПРЕДСТАВИТЕЛЕЙ) ПО ВОПРОСАМ ОБРАБОТКИ ИХ
                        ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Доступ к своим ПДн, а так же к информации о наличии ПДн, относящихся к конкретному
                        субъекту ПДн, предоставляются субъекту ПДн или его законному представителю, при обращении либо
                        при получении запроса субъекта ПДн или его законного представителя.
                        Запрос должен содержать номер основного документа, удостоверяющего личность субъекта ПДн
                        или его законного представителя, сведения о дате выдачи указанного документа и выдавшем его органе
                        и собственноручную подпись субъекта ПДн или его законного представителя.
                        Запросы субъекта ПДн (его законного представителя) регистрируются в Журнале учета
                        обращений субъектов персональных данных.
                        Ответственным за ведение Журнала учета обращений субъектов персональных данных по
                        вопросам обработки ПДн является администратор. Форма журнала определена в Приложении 5 к
                        настоящему Положению.
                        Администратор, после обращения субъектов ПДн (или их законных представителей) по вопросам
                        обработки их ПДн передает информацию об обращении заместителю директора по правовым вопросам.
                        Заместитель директора по правовым вопросам после получения запроса субъекта ПДн (или их
                        представителей) по вопросам обработки их ПДн готовит ответ или мотивированный отказ в
                        предоставлении информации.
                        Общество предоставляет ответ в течение пяти рабочих дней с даты получения запроса субъекта
                        ПДн или его законного представителя.
                        В случае отказа в предоставлении субъекту ПДн или его законному представителю при
                        обращении либо при получении запроса субъекта ПДн или его законного представителя информации о
                        наличии ПДн о соответствующем субъекте ПДн, а также таких ПДн общество обязано дать в
                        письменной форме мотивированный ответ, содержащий ссылку на положение части 5 статьи 14
                        Федерального закона от 27.07.2006 №152-ФЗ "О персональных данных" или иного федерального
                        закона, являющееся основанием для такого отказа, в срок, не превышающий семи рабочих дней со дня
                        обращения субъекта ПДн или его законного представителя либо с даты получения запроса субъекта
                        ПДн или его законного представителя.
                        <br/><br/>15. ПОРЯДОК ДЕЙСТВИЙ В СЛУЧАЕ ЗАПРОСОВ УПОЛНОМОЧЕННОГО ОРГАНА
                        ПО ЗАЩИТЕ ПРАВ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ ИЛИ ИНЫХ НАДЗОРНЫХ
                        ОРГАНОВ, ОСУЩЕСТВЛЯЮЩИХ КОНТРОЛЬ И НАДЗОР В ОБЛАСТИ ПЕРСОНАЛЬНЫХ
                        ДАННЫХ
                        Запросы от уполномоченного органа по защите прав субъектов ПДн (Федеральная служба по
                        надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)
                        регистрируется в Журнале учета входящей корреспонденции и далее отдаются на рассмотрение
                        руководству общества, для подготовки ответов ответственным сотрудникам общества.
                        Ответственным за подготовку ответов на запросы уполномоченного органа по защите прав
                        субъектов ПДн (Федеральная служба по надзору в сфере связи, информационных технологий и
                        массовых коммуникаций (Роскомнадзор) является заместитель директора по правовым вопросам.
                        <br/><br/>16. ПОРЯДОК ДОСТУПА СОТРУДНИКОВ ОРГАНИЗАЦИИ ИЛИ ИНЫХ ЛИЦ В
                        ПОМЕЩЕНИЯ, В КОТОРЫХ ВЕДЕТСЯ ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ
                        Помещения, в которых размещены информационные системы, специальное оборудование и
                        ведется работа с персональными данными, должны быть оборудованы средствами охраны,
                        обеспечивающими сохранность носителей ПДн и средств защиты информации и должны исключать
                        возможность неконтролируемого проникновения или пребывания в этих помещениях посторонних лиц.
                        Размещение устройств вывода информации средств вычислительной техники, информационновычислительных комплексов и буквенно-цифровой информации, входящих в состав информационной
                        системы, в помещениях которых они установлены, должны исключать возможность просмотра
                        посторонними лицами текстовой и графической видовой информации, содержащей ПДн.
                        Охрана помещений информационных систем, где осуществляется обработка ПДн,
                        предусматривает ограничение доступа в эти помещения, контроль доступа посторонних лиц, наличие
                        надежных препятствий для несанкционированного проникновения в помещения информационных
                        систем и в места хранения носителей информации.
                        Доступ сотрудникам в помещения, где осуществляется обработка ПДн, происходит на основании
                        должностных обязанностей. Помещения, где осуществляется обработка ПДн, должны соответствовать
                        предъявленным требованиям.
                        О попытках несанкционированного проникновения посторонних лиц в помещения
                        информационных систем и в места хранения носителей информации и других нарушений данных
                        требований, немедленно информируется руководство общества.
                        Перечень помещений, в которых осуществляется обработка ПДн, утверждается приказом
                        директора.
                        <br/><br/>17. ОТВЕТСТВЕННОСТЬ
                        Иные права, обязанности, действия сотрудников, в трудовые обязанности которых входит
                        обработка ПДн, определяются также должностными инструкциями.
                        Лица, виновные в нарушении норм, регулирующих получение, обработку и защиту ПДн, несут
                        материальную, дисциплинарную, административную, гражданско-правовую или уголовную
                        ответственность в порядке, установленном федеральными законами.
                        Разглашение ПДн, имеющихся в обществе (передача их посторонним лицам, в том числе,
                        сотрудникам общества, не имеющим к ним доступа), их публичное раскрытие, утрата документов и
                        иных носителей, содержащих ПДн, а также иные нарушения обязанностей по их защите и обработке,
                        установленных настоящим Положением, локальными нормативными актами (приказами,
                        распоряжениями) общества, влечет наложение на сотрудника, имеющего доступ к ПДн,
                        дисциплинарного взыскания – замечания, выговора, увольнения.
                        Сотрудник общества, имеющий доступ к ПДн и совершивший указанный дисциплинарный
                        проступок, несет полную материальную ответственность в случае причинения его действиями ущерба
                        обществу (п.7 ст. 243 Трудового кодекса РФ).
                        Сотрудники общества, имеющие доступ к ПДн, виновные в незаконном разглашении или
                        использовании ПДн без согласия сотрудников или клиентов из корыстной или иной личной
                        заинтересованности и причинившие крупный ущерб, несут уголовную ответственность в соответствии
                        со ст. 183 Уголовного кодекса РФ.
                        Иные отношения, неурегулированные настоящим Положением, регулируются законодательством
                        РФ.
                        ПРИЛОЖЕНИЯ
                        Номер и наименование Приложения Файл
                        Приложение 1
                        Согласие на обработку ПДн
                        Приложение 1
                        Приложение 2
                        Обязательство о соблюдении режима конфиденциальности ПДн
                        Приложение 2
                        Приложение 3
                        Акт уничтожения съемных носителей ПДн
                        Приложение 3
                        Приложение 4
                        Акт приема-передачи документов (иных материальных носителей), содержащих
                        ПДн
                        Приложение 4
                        Приложение 5
                        Форма журнала учета обращений субъектов ПДн
                        Приложение 5
                        Приложение 6
                        Перечень общедоступных ПДн, обрабатываемых в информационных системах
                        Приложение 6
                        Приложение 7
                        Согласие на обработку ПДн сотрудников
                        Приложение 7
                </div>
        </div>
    );
}

export default connect()(PolicyScreen);