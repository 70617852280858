import React, { useState, useEffect } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import { showDoctorPage } from "../redux/actions/commonActions";
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "../redux/actionTypes";
import {switchInterface} from "../redux/actions/interfaceActions";

const mapStateToProps = state => {
    return {
        showDoctor: state.commonReducer.showDoctor,
        loading: state.commonReducer.loading,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showDoctorPage: () => dispatch(showDoctorPage(null)),
        switchInterface: (type) => dispatch(switchInterface(type))
    };
};

function Header(props) {

    function goBack(){
        if(props.showDoctor) props.showDoctorPage();
        else props.history.goBack();
    }

    function switchToClinic() {
        props.switchInterface(CLINIC_INTERFACE);
        changeModalState(false);
    }

    function switchToCosmetology() {
        props.switchInterface(COSMETOLOGY_INTERFACE);
        changeModalState(false);
    }

    let titleStyles = `mb-0`;
    const [showModal, changeModalState] = useState(false);

    if(!props.showOnDesktop) titleStyles += " d-md-none";

    return (
        <>
            <div className={` mb-${ props.mb || "3" } w-100 d-flex align-items-start justify-content-between1`}>
                {
                    (props.back) &&
                    <button
                        onClick={ goBack }
                        className={"btn pt-0 mr-3 px-3 border1 border-primary1 d-inline-flex align-items-center justify-content-center"}
                    >
                        <i className={"fas fa-chevron-left text-primary fa-15x"}></i>
                    </button>
                }
                {
                    (props.title) &&
                    <h1 className={titleStyles}>
                        <b>
                            { props.title }
                        </b>
                    </h1>
                }
                <button className={"d-none btn btn-sm text-primary"} onClick={ () => changeModalState(true) }>
                    <i className={"fas fa-sync-alt fa-15x"}></i>
                </button>
            </div>

            <div
                className="modal fade show"
                style={{ display: showModal ? "block" : "none", backdropFilter: "blur(10px)", background: "rgba(0,0,0,0.4)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content py-5">
                        <div className="modal-body mx-auto py-0">
                            <h2 className={"d-non1 text-center mb-5"}>
                                Переключить личный кабинет
                            </h2>
                            <div className={"d-flex flex-column align-items-center justify-content-center"}>
                                <img
                                    onClick={ switchToClinic }
                                    className={"img-fluid"}
                                    style={{ height: 75, paddingLeft: 57 }}
                                    src="https://medeor74.ru/wp-content/uploads/2021/06/logo-3.png" alt=""
                                />

                                <hr className={"w-100 bg-primary"} />

                                <div className={"text-center mt-4"}>
                                    <img
                                        onClick={ switchToCosmetology }
                                        className={"img-fluid"}
                                        style={{ height: 70 }}
                                        src="https://medeorcosmetology.clinic/wp-content/uploads/ct-logos/logo_11a0cc739e5f26b8fdafcb5703f1585f_2x.png" alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));