import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router } from 'react-router-dom';
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "./redux/actionTypes";

const store = configureStore({
    notificationReducer: [],
    registerReducer: {
        smsWasSent: false,
        step: 1
    },
    appointmentsReducer: {
        futureList: [],
        pastList: [],
        appointmentData: {},
        showProtocol: false,
        protocolBody: ""
    },
    analyzesReducer: {
        analyzesList: [],
        analyzData: {},
    },
    onlineReducer: {
        profiles: [],
        profile: "",
        specialities: [],
        speciality: {},
        doctors: [],
        teledoctors: [],
        doctor: {},
        services: [],
        service: {},
        dates: [],
        price: false,
        serviceName: "",
        serviceOnline: false,
        showModal: false,
        date: "",
        time: "",
        paymentUrl: "",
        dmsAllowed: false
    },
    commonReducer: {
        loading: true,
        libs: {},
        path: "",
        auth: false,
        authChecked: false,
        profiles: [],
        user: {},
        showModal: false,
        modalBody: "",
        background: "",
        passwordWasReset: false,
        notifications: [],
        bonuses: 0,
        code: "",
        bonusHistory: [],
        balance: 0
    },
    interfaceReducer: {
        style: window.localStorage.getItem("interface") || CLINIC_INTERFACE
    },
    cosmetologyReducer: {
        doctors: [],
        doctor: null,
        services: [],
        service: null,
        dates: [],
        price: false,
        serviceName: "",
        serviceOnline: false,
        specialities: [],
        speciality: null,
        date: "",
        time: "",
        paymentUrl: "",
        dmsAllowed: false
    },
    paymentReducer: {
        showModal: false,
        token: undefined,
        paymentStep: 1,
        appID: undefined,
        isOnline: undefined
    }
});

//window.ReactNativeWebView = 1;

ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider maxSnack={5} autoHideDuration={3000} hideIconVariant>
            <Router>
                <App />
            </Router>
        </SnackbarProvider>
    </Provider>, document.getElementById('root'));