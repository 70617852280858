import React, {useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

function CosmetologySlider(props) {

    const promos = [
        {
            "АдресКартинки": "https://lk.medeor74.ru/pics/botoks130.jpg"
        },
        {
            "АдресКартинки": "https://medeorcosmetology.clinic/wp-content/uploads/2023/06/jemton-banner.png"
        },
        {
            "АдресКартинки": "https://medeorcosmetology.clinic/wp-content/uploads/2022/12/obshhaja.jpg"
        },
        {
            "АдресКартинки": "https://medeorcosmetology.clinic/wp-content/uploads/2022/12/jendosfera.jpg"
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        slidesToScroll: 1,
        adaptiveHeight: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true
                }
            }
        ]
    };


    if(promos && promos.length){
        return (
            <Slider {...settings} >
                {
                    promos.map( (u,i) => {
                        return (
                            <div key={i} className="mb-4 p-md-3">
                                <img
                                    src={u["АдресКартинки"]} className="img-fluid rounded border border-2" alt=""
                                />
                            </div>
                        );
                    })
                }
            </Slider>
        );
    }
    else return null;
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CosmetologySlider));