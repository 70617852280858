import React, { useEffect, useState } from 'react';
import { getServices } from "../../redux/actions/onlineZapisActions";
import {connect} from "react-redux";
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "../../redux/actionTypes";

const mapStateToProps = state => {

    const style = state.interfaceReducer.style;
    let docs = [];

    if(style === CLINIC_INTERFACE) docs = state.onlineReducer.doctors;
    if(style === COSMETOLOGY_INTERFACE) docs = state.cosmetologyReducer.doctors;

    return {
        style: style,
        docs: docs,
        doc: state.onlineReducer.doctor,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getServices: (doc, dms) => dispatch(getServices(doc, dms))
    };
};

function Docs(props){

    const [docData, setDocData] = useState({});

    //console.log(props);

    useEffect(() => {
        if(props.doc && typeof props.doc === "string"){
            setDocData(props.docs.find(doc => doc['Врач'] === props.doc));
            props.getServices(props.doc, false);
        }
        //else setDocData({});
    }, [props.doc]);

    function onDocChange(event) {
        const target = event.target;
        if(!target) return;

        const value = target.value;
        if(!value) return;

        const doc = props.docs.find(d => d['Врач'] === value);
        if(!doc) return;

        props.getServices(doc['Врач'], doc['ДоступноДМС']);
    }

    function renderBlockTitle(){
        return (
            <div className="alert alert-light mb-2">
                <label>
                    <b>3. Выберите врача</b>
                </label>
            </div>
        );
    }

    if(props.docs && props.docs.length === 1) {
        return (
            <div>
                { renderBlockTitle() }

                <div className="form-control h-auto">
                    {props.docs[0]["Фамилия"]} {props.docs[0]["Имя"]} {props.docs[0]["Отчество"]}
                </div>

                {
                    (props.docs[0]['КраткоеОписание'] && props.docs[0]['КраткоеОписание'].length) &&
                    <ul className={"mt-2"}>
                        <li>
                            {props.docs[0]['КраткоеОписание']}
                        </li>
                    </ul>
                }
            </div>
        );
    }

    if(props.docs && props.docs.length > 1) {
        return (
            <>
                { renderBlockTitle() }

                <select
                    className="form-control"
                    name={"doctor"}
                    value={props.doc || 0}
                    onChange={onDocChange}
                >
                    <option value={""}> </option>
                    {
                        props.docs.map(function (s, i) {
                            return (
                                <option
                                    key={i}
                                    value={s['Врач']}
                                >
                                    {s["Фамилия"]} {s["Имя"]} {s["Отчество"]}
                                </option>
                            )
                        })
                    }
                </select>
                {
                    (docData && docData['КраткоеОписание'] && docData['КраткоеОписание'].length) &&
                    <ul className={"mt-2"}>
                        <li>
                            {docData['КраткоеОписание']}
                        </li>
                    </ul>
                }
            </>
        );
    }

    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(Docs);



