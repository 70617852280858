let isMobile = (window.screen.width < 768);

const { NODE_ENV, REACT_APP_DEV_HOST } = process.env;
const dev = NODE_ENV === "development";
const devHost = REACT_APP_DEV_HOST ? REACT_APP_DEV_HOST : "http://localhost:8081";
//const domain = dev ? devHost : "https://lk.medeor74.ru";
const domain = "https://lk.medeor74.ru";
//const domain = "http://localhost:8081";

//console.log(domain);

const config = {
    api: `${domain}/api`,
    axiosOptions: {withCredentials: true},
    isMobile: isMobile,
    snackOptions: {
        success: {
            variant: "success",
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }
        },
        error: {
            variant: "error",
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }
        }
    }
};

export default config;