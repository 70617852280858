import React, {useState} from "react";
import {connect} from "react-redux";
import {cancelAppointment} from "../../redux/actions/appointmentsActions";

function CancelButton(props) {
    const [step, setStep] = useState(1);

    function handleClick() {
        props.cancelAppointment(props.appointment);
    }

    if(step === 1){
        return (
            <div className="d-flex justify-content-end">
                <button
                    className="btn btn-outline-danger"
                    onClick={() => setStep(2)}
                >
                    Отменить
                </button>
            </div>
        );
    }

    if(step === 2){
        return (
            <div className="d-flex justify-content-end align-items-center">
                <span>
                    Вы уверены?
                </span>
                <button
                    className="btn btn-outline-danger mx-3"
                    onClick={handleClick}
                >
                    Да
                </button>
                <button
                    className="btn btn-outline-primary"
                    onClick={() => setStep(1)}
                >
                    Нет
                </button>
            </div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        cancelAppointment: (id) => dispatch(cancelAppointment(id))
    };
};

export default connect(null, mapDispatchToProps)(CancelButton);

