import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter, NavLink} from "react-router-dom";
import {getDocs, getSpecs } from "../../redux/actions/onlineZapisActions";
import { setLoading, showDoctorPage } from "../../redux/actions/commonActions";
import {COSMETOLOGY_INTERFACE, CLINIC_INTERFACE} from "../../redux/actionTypes";
import DocPhoto from "./DocPhoto";

const mapStateToProps = state => {
    return {
        spec: state.onlineReducer.speciality,
        doctors: state.onlineReducer.doctors,
        loading: state.commonReducer.loading,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDocs: (spec) => dispatch(getDocs(spec)),
        showDoctorPage: (doc) => dispatch(showDoctorPage(doc)),
        setLoading: (flag) => dispatch(setLoading(flag))
    };
};

function SpecItem(props) {

    let active = (props.spec === props.item['Специальность']);

    function onSpecChange() {
        props.getDocs(props.item['Специальность']);
    }

    function goToOnline(e,doc){
        e.stopPropagation();
        e.preventDefault();

        if(props.style === COSMETOLOGY_INTERFACE) return props.history.push(`/cosmo-online?doc=${doc['Врач']}`);

        window.localStorage.setItem("selectedDoc", doc['Врач']);
        window.localStorage.setItem("selectedSpec", props.item['Специальность']);
        props.history.push(`/online`);
    }

    function renderDocs(){
        if(!(active && props.doctors && props.doctors.length)) return null;

        return props.doctors.map( doc => {
            return (
                <div
                    onClick={ () => props.showDoctorPage(doc) }
                    className={"d-block text-dark cursor-pointer"}
                    key={doc['Врач']}
                    style={{ marginBottom: 20 }}
                >
                    <div className={"doc-info-grid"}>
                        <div
                            className={"d-flex align-items-center justify-content-center border border-primary rounded-lg overflow-hidden doc-pic"}
                            style={{ width: "100%", height: "100%" }}
                        >
                            <DocPhoto docID={doc['Врач']} />
                        </div>

                        <div
                            className={"ml-3 doc-info-grid-details"}
                        >
                            <div >
                                <h3 className={"mb-1"} style={{ fontSize: 15 }}>
                                    <b className={"text-uppercase"}>
                                        { doc['Фамилия'] }
                                    </b>
                                    <br/>
                                    <b>
                                        { doc['Имя'] } { doc['Отчество'] }
                                    </b>
                                </h3>
                                <div className={"small"}>
                                    Стоимость от { doc.minprice || 0 } руб.
                                </div>
                            </div>

                            <div></div>

                            <div className={"mt-3"}>
                                <button
                                    onClick={ (e) => goToOnline(e, doc) }
                                    className={"btn btn-sm px-3 btn-primary rounded-pill"}
                                >
                                    Записаться
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    return (
        <div id={ "spec-" + props.item['Специальность'] }>
            <div className={`form-control ${ active ? "bg-primary text-white" : "text-dark" } h-100 mb-3 cursor-pointer`} onClick={onSpecChange}>
                { props.item['Представление'] }
            </div>
            {   active &&
                <div className={"alert alert-light text-dark"} style={{ padding: "20px 20px 0" }}>
                    { renderDocs() }
                </div>
            }
        </div>
    );
}

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(SpecItem));