import React from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import dateformat from 'dateformat';
import { addAppointment, addAppointmentAndPay } from "../../redux/actions/appointmentsActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FreeTime extends React.Component {
    constructor() {
        super(...arguments);

        this.state = {
            date: false,
            time: false,
            dms: false,
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    handleChange = event => {
        this.setState({
            dms: event.target.value
        });
    }

    makeZapis = (day, humandate, time) => {
        this.setState({
            step: 2,
            date: day + "T" + time + ":00+05:00"
        });
    };

    handleSubmit = () => {
        let data = {
            date: this.state.date,
            doctor: this.props.doctor,
            service: this.props.service,
            profile: this.props.profile,
            dms: (this.state.dms) ? 1 : 0
        };
        this.props.addAppointment(data);
    };

    getPaymentUrl = () => {
        let data = {
            date: this.state.date,
            doctor: this.props.doctor,
            service: this.props.service,
            serviceName: this.props.serviceName,
            profile: this.props.profile,
            dms: (this.state.dms) ? 1 : 0,
            amount: this.props.price,
            isOnline: false
        };
        this.props.addAppointmentAndPay(data);
    };

    render() {
        if(this.props.dates && this.props.dates.length > 0){

            let settings1;

            if(this.props.dates && this.props.dates.length > 0 && this.props.dates.length <= 3 ){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    slidesToShow: this.props.dates.length,
                    slidesToScroll: 1,
                    centerPadding: "10px"
                };
            }

            if(this.props.dates && this.props.dates.length > 3){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    responsive: [
                        {
                            breakpoint: 7680,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "50px"
                            }
                        },
                        {
                            breakpoint: 425,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 320,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                centerPadding: "15px"
                            }
                        }
                    ]
                };
            }

            let settings2 = {
                dots: false,
                infinite: false,
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
                speed: 500,
                slidesToScroll: 1,
                adaptiveHeight: false
            };

            let days = this.props.dates.map((u,i) => {
                return (
                    <div key={i} className={"px-3"}>
                        <button type="button" className="btn btn-outline-primary nav1 px-2">
                            {u.humanDate}
                        </button>
                    </div>
                );
            });

            let dates = this.props.dates.map((u,i) => {
                let times = u.times.map((time,j) => {
                    return (
                        <button
                            key={j}
                            type="button"
                            className="btn btn-outline-primary lift"
                            onClick={() => this.makeZapis(u.date, u.humanDate, time)}
                        >
                            {time}
                        </button>
                    );
                });

                return (
                    <div key={i}>
                        <h3 className={"px-3"}>
                            <b>Время</b>
                        </h3>
                        <div className={"timeGrid px-3"}>
                            {times}
                        </div>
                    </div>
                );
            });

            return (
                <div className="position-relative">
                    <div className={"mb-5"}>
                        <div className="alert alert-light mb-2">
                            <label>
                                <b>
                                    5. Выберите способ оплаты
                                </b>
                            </label>
                        </div>

                        <div>
                            {
                                this.props.dms &&
                                (
                                    <div className="">
                                        <select className="form-control" value={this.state.dms} onChange={this.handleChange}>
                                            <option value={true}>
                                                Полис ДМС
                                            </option>
                                            <option value={false}>
                                                Наличными или банковской картой
                                            </option>
                                        </select>
                                    </div>
                                )
                            }

                            {
                                !this.props.dms &&
                                (
                                    <h3 className="form-control" style={{userSelect: "none", height: "min-content" }}>
                                        Наличными или банковской картой
                                    </h3>
                                )
                            }
                        </div>
                    </div>

                    <div className="form-group mb-0">
                        <div className={"alert alert-light mb-2"}>
                            <label>
                                <b>6. Выберите дату и время</b>
                            </label>
                        </div>

                        <div className="border border-primary py-4 rounded overflow-hidden position-relative mh250">
                            <div>
                                <h3 className={"px-3"}>
                                    <b>Дата</b>
                                </h3>
                                <Slider
                                    asNavFor={this.state.nav2}
                                    ref={slider => (this.slider1 = slider)}
                                    {...settings1}
                                >
                                    {days}
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </Slider>

                                <div className={"my-3"}></div>

                                <Slider
                                    asNavFor={this.state.nav1}
                                    ref={slider => (this.slider2 = slider)}
                                    {...settings2}
                                >
                                    {dates}
                                </Slider>
                            </div>
                            <div className={"zapis-form justify-content-center rounded p-3 alert-light " + ((this.state.step === 2) ? " zapis-form-filled" : "") }>
                                <div>

                                </div>

                                <div className="">
                                    <h3 className={"mb-0 text-center"} style={{lineHeight: '2rem'}}>
                                        Вы выбрали запись на приём
                                        <br/>
                                        <b>{dateformat(this.state.date, "dd.mm.yyyy в HH:MM")}</b>
                                    </h3>
                                    <div className="my-3">
                                        <button
                                            className={`d-block mx-auto btn btn-primary ${(this.props.price === 0) ? "d-none" : ""}`}
                                            onClick={this.getPaymentUrl}
                                        >
                                            Записаться и оплатить онлайн
                                        </button>
                                        {
                                            (!this.props.serviceOnline) &&
                                            <button
                                                className="d-block mx-auto btn btn-outline-primary mt-3"
                                                onClick={this.handleSubmit}
                                            >
                                                Записаться без онлайн-оплаты
                                            </button>
                                        }
                                    </div>
                                    <div className={"text-center mt-5"}>
                                        <button
                                            onClick={() => this.setState({step: 1})}
                                            className="btn btn-sm btn-outline-primary"
                                        >
                                            <i className="fas fa-chevron-left mr-2"></i>
                                            Изменить выбор
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else return null;
    }
}

const mapStateToProps = state => {
    return {
        dates: state.onlineReducer.dates,
        user: state.commonReducer.user,
        service: state.onlineReducer.service,
        dms: state.onlineReducer.dmsAllowed,
        doctor: state.onlineReducer.doctor,
        price: state.onlineReducer.price,
        profile: state.onlineReducer.profile,
        serviceName: state.onlineReducer.serviceName,
        serviceOnline: state.onlineReducer.serviceOnline
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addAppointment: (data) => dispatch(addAppointment(data)),
        addAppointmentAndPay: data => dispatch( addAppointmentAndPay(data) )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeTime);