import React, { useState, useEffect } from 'react';
import {
    setLoading,
    setUserChanges,
    logout,
    getBalance,
    getLinkToAddBalance
} from "../redux/actions/commonActions";
import { getProfiles } from "../redux/actions/onlineZapisActions";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import CheckBox from "../components/CheckBox";
import Header from "../components/Header";
import Profiles from "../components/profile/Profiles";

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        balance: state.commonReducer.balance,
        profile: state.onlineReducer.profile,
        profiles: state.onlineReducer.profiles
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: () => dispatch(setLoading()),
        setUserChanges: (data) => dispatch(setUserChanges(data)),
        logout: (guid) => dispatch(logout(guid)),
        getBalance: () => dispatch(getBalance()),
        getLinkToAddBalance: (data) => dispatch(getLinkToAddBalance(data)),
        getProfiles: () => dispatch(getProfiles()),
    };
};

function SettingsScreen(props) {
    const [obj, setObj] = useState({
        email: props.user['ЭлПочта'],
        password: '',
        password2: ''
    });

    const [passwordErr, setErr] = useState(0);
    const [passwordMsg, setErrMsg] = useState("");
    const [amount, setAmount] = useState(0);

    useEffect( () => {
        props.getBalance();
        props.getProfiles();
    }, []);

    useEffect(()=> {

        if(obj.password2 !== obj.password){
            setErr(true);
            setErrMsg("Введённые пароли не совпадают");
        }
        else if((obj.password2.length > 0 && obj.password2.length < 6) || (obj.password.length > 0 && obj.password.length < 6)){
            setErr(true);
            setErrMsg("Длина пароля должна быть не менее 6 символов");
        }
        else setErr(false);
    });

    function handleChange(e) {
        setObj({
            ...obj,
            [e.target.name]: e.target.value
        });
    }

    function handleAmountChange(e){
        if(e.target.value < 0) setAmount(0);
        else setAmount(e.target.value);
    }

    function getPaymentLink(){
        if(amount > 0) props.getLinkToAddBalance({ amount });
    }

    function saveSettings() {
        props.setUserChanges(obj);
    }

    return (
        <div className="" style={{ maxWidth: 800, margin: 'auto' }}>

            <Header back={1} title={"ПРОФИЛЬ"}/>

            <div className={"row"}>
                <div className={"col-12 col-md-6"}>
                    <Profiles/>
                </div>
                <div className={"col-12 col-md-6"}>
                    <div className="card h-100">
                        <div className="card-header">
                            <h3 className="mb-0">
                                <b>
                                    БАЛАНС СЧЁТА
                                </b>
                            </h3>
                        </div>

                        <div className="card-body px-md-0 px-2 mx-0 mx-md-3">
                            <h1 className={"m-0 text-center"}>
                                {props.balance} рублей
                            </h1>
                        </div>

                        <div className={"card-footer"}>
                            <div className={"alert alert-light"}>
                                <h3 className={"m-0"}>Вы можете пополнить баланс в любое время и на любую сумму с
                                    помощью
                                    онлайн-оплаты</h3>
                            </div>

                            <div className={"d-flex align-items-center justify-content-between"}>
                                <div className={"d-flex align-items-center"}>
                                    <Form.Group controlId="formBasicAmount" className={"m-0"}>
                                        <Form.Control
                                            value={amount}
                                            type="number"
                                            placeholder="Сумма"
                                            name="amount"
                                            min={1}
                                            onChange={handleAmountChange}
                                        />
                                    </Form.Group>
                                    <span className={"ml-2 mr-4"}>
                                рублей
                            </span>
                                </div>
                                <button
                                    className="btn btn-primary flex-grow-0 mr-n3"
                                    onClick={getPaymentLink}
                                    disabled={!(amount > 0)}
                                >
                                    Пополнить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mt-5">
                <div className="card-header">
                    <h3 className="mb-0">
                        <b>
                            НАСТРОЙКИ
                        </b>
                    </h3>
                    <button
                        className="d-none d-md-inline-block btn btn-outline-primary flex-grow-0 lift mr-n3"
                        onClick={saveSettings}
                        disabled={(passwordErr)}
                    >
                        Сохранить
                    </button>
                </div>
                <div className="card-body px-md-0 px-2 mx-0 mx-md-3">
                    <Form>
                        <div className="row">
                            <div className="col-12 col-md-6 d-flex">
                                <div className="rounded border p-3 mb-5 flex-grow-1">
                                    <h3>
                                        <b>Контактные данные</b>
                                    </h3>
                                    <hr/>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            defaultValue={props.user['ЭлПочта']}
                                            type="email"
                                            placeholder=""
                                            name="email"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <hr/>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between">
                                            <p>Телефон</p>
                                            <p>
                                            {props.user['Телефон']}
                                            </p>
                                        </div>
                                        <div className="alert alert-light">
                                            В целях безопасности изменение номера телефона возможно только при личном
                                            обращении в медицинский центр «Медеор».
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 d-flex">
                                <div className="rounded border p-3 mb-5 flex-grow-1">
                                    <h3>
                                        <b>Изменение пароля</b>
                                    </h3>
                                    <hr/>
                                    <Form.Group controlId="formBasicPass1">
                                        <Form.Label>Новый пароль</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Не менее 6 символов"
                                            name={"password"}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPass2">
                                        <Form.Label>Новый пароль повторно</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Не менее 6 символов"
                                            name={"password2"}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                    {
                                        (passwordErr) &&
                                        <div className={"alert alert-danger"}>
                                            {passwordMsg}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-none">
                                <div className="rounded border p-3  mb-5 flex-grow-1">
                                    <h3>
                                        <b>Управление рассылками</b>
                                    </h3>
                                    <hr/>
                                    <div>
                                        <label><b>Получение email рассылок</b></label>
                                        <CheckBox
                                            label={"Готовность анализов"}
                                            checked={() => setObj({...obj, emailAnaliz: !obj.emailAnaliz})}
                                            p={obj.emailAnaliz}
                                        />
                                        <CheckBox
                                            label={"Рекламные рассылки"}
                                            checked={() => setObj({...obj, emailAds: !obj.emailAds})}
                                            p={obj.emailAds}
                                        />
                                    </div>
                                    <hr/>
                                    <div>
                                        <label><b>Получение SMS рассылок</b></label>
                                        <CheckBox
                                            label={"Запись на приём"}
                                            checked={() => setObj({...obj, smsApps: !obj.smsApps})}
                                            p={obj.smsApps}
                                        />
                                        <CheckBox
                                            label={"Готовность анализов"}
                                            checked={() => setObj({...obj, smsAnaliz: !obj.smsAnaliz})}
                                            p={obj.smsAnaliz}
                                        />
                                        <CheckBox
                                            label={"Рекламные рассылки"}
                                            checked={() => setObj({...obj, smsAds: !obj.smsAds})}
                                            p={obj.smsAds}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    <div className={"d-block d-md-none text-center"}>
                        <button
                            className="btn btn-primary flex-grow-0 lift mr-n3"
                            onClick={saveSettings}
                            disabled={(passwordErr)}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>

            {localStorage.os}
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsScreen);