import * as ACTIONS from "../actionTypes";
import axios from "axios";
import { enqueueSnackbar } from "./notificationActions";
import config from "../../config";
import { setLoading, handleError, redirect, showModal } from "./commonActions";
import { selectDoctor, selectService, selectTime } from "./cosmetologyActions";

export function getFutureAppointments() {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_FUTURE_APPOINTMENTS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/appointments/future', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getPastAppointments() {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_PAST_APPOINTMENTS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/appointments/past', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getProtocol(appointment, protocol, mode) {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_PROTOCOL,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/appointments/getProtocol', {appointment, protocol, mode}, config.axiosOptions)
                    .then((res) => {
                        if (mode === "pdf" && window.ReactNativeWebView) {  //if we work under react native app
                            window.ReactNativeWebView.postMessage(res.data);
                        }

                        if(mode === "email"){
                            dispatch(enqueueSnackbar({
                                message: res.data,
                                options: config.snackOptions.success
                            }));
                        }
                        /*
                        else {
                            resolve(res.data);
                        }
                         */
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function addAppointment(data) {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.ADD_APPOINTMENT,
            payload: new Promise((resolve, reject) => {
                data.source = "";

                if(window.ReactNativeWebView) {
                    if( localStorage.os ) data.source = localStorage.os;
                    else data.source = "mobile-app";
                }
                else data.source = "web-lk";

                axios.post(config.api + '/appointments/add', data, config.axiosOptions)
                    .then(() => {
                        dispatch(redirect("/appointments"));
                        dispatch(selectDoctor(null));
                        dispatch(selectService(null));
                        dispatch(selectTime({date: "", time: "", humanDate: ""}));
                        dispatch(enqueueSnackbar({
                            message: "Запись успешно создана. Не забудьте взять с собой паспорт!",
                            options: config.snackOptions.success
                        }));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                    });
            })
        });
    }
}

export function addAppointmentAndPay(data) {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch(showModal(false));
        dispatch( {
            type: ACTIONS.GET_PAYMENT_URL,
            payload: new Promise((resolve, reject) => {
                data.source = "";

                if(window.ReactNativeWebView) {
                    if( localStorage.os ) data.source = localStorage.os;
                    else data.source = "mobile-app";
                }
                else data.source = "web-lk";

                axios.post(config.api + '/payments/geturl', data, config.axiosOptions)
                    .then((res) => {

                        resolve(res.data);

                        //if(!data.isOnline) {
                            dispatch(enqueueSnackbar({
                                message: "Запись успешно создана. Ожидаем онлайн-оплаты.",
                                options: config.snackOptions.success
                            }));

                            dispatch(getFutureAppointments());
                        //}

                        //dispatch(redirect("/payment"));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        //dispatch(getFutureAppointments());
                    });
            })
        });
    }
}

export function cancelAppointment(guid) {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.CANCEL_APPOINTMENT,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/appointments/cancel', {guid}, config.axiosOptions)
                    .then((res) => {
                        dispatch(enqueueSnackbar({
                            message: "Запись успешно отменена",
                            options: config.snackOptions.success
                        }));
                        dispatch(getFutureAppointments());
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        dispatch(getFutureAppointments());
                    });
            })
        });
    }
}

