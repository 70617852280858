import React, { useLayoutEffect, useState } from "react";
import {setBackground} from "../redux/actions/commonActions";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
    return {
        bg: state.commonReducer.background,
        user: state.commonReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setBackground: (bg) => dispatch(setBackground(bg))
    };
};

function WelcomeScreen(props) {

    let shown = true;
    const [text, setText] = useState("");
    const [cls, setCls] = useState("d-none ");

    useLayoutEffect(() => {
        let h = new Date().getHours();
        let bg;

        if(h >= 0 && h <= 5) {
            bg = "bg-night";
            setText("Доброй ночи");
        }
        if(h > 5 && h <= 11) {
            bg = "bg-morning";
            setText("Доброе утро");
        }
        if(h > 11 && h <= 18) {
            bg = "bg-day";
            setText("Добрый день");
        }
        if(h > 18 && h <= 23) {
            bg = "bg-evening";
            setText("Добрый вечер");
        }

        props.setBackground(bg);

    }, []);

    useLayoutEffect(() => {
        if(props.user['Имя'] && props.location.pathname === "/" && !shown) {
            setCls("welcome-splash-show");

            let timer = setTimeout(function() { setCls("welcome-splash-hide") }, 2000);
            let timer2 = setTimeout(function() { setCls("d-none") }, 3100);

            return () => {
                clearTimeout(timer);
                clearTimeout(timer2)
            };
        }
    }, [props.user]);



    return (
        <div className={props.bg + " welcome-splash " + cls}>
            <div className="card-body">
                <div className="text-center text-shadow">
                    {text}, {props.user['Имя']}!
                </div>
            </div>
        </div>
    );
}

export default connect( mapStateToProps, mapDispatchToProps)(withRouter(WelcomeScreen));