import React from 'react';
import { connect } from "react-redux";
import { getProtocol } from '../../redux/actions/appointmentsActions';
import { setLoading } from '../../redux/actions/commonActions';
import dateFormat from "dateformat";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import TableSearch from "../TableSearchBar";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {Dropdown, DropdownButton} from "react-bootstrap";
import config from "../../config";

const mapStateToProps = state => {
    return {
        pastList: state.appointmentsReducer.pastList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: () => dispatch(setLoading()),
        getProtocol: (appointment, protocol, mode) => dispatch(getProtocol(appointment, protocol, mode)),
    };
};

function Past(props) {

    const screenWidth = window.screen.width;

    function renderBtns(row) {
        let btns = [];

        for (let i = 1; i <= row['НомерПротокола']; i++) {
            btns.push(
                <DropdownButton
                    key={"dropdown-btn-" + i}
                    title={"Протокол"}
                    variant={ (config.isMobile) ? "outline-primary btn-sm" : "light" }
                    className="mr-2"
                    alignRight
                >
                    {
                        (window.ReactNativeWebView) ? (
                            <Dropdown.Item
                                as="button"
                                onClick={() => props.getProtocol(row['Прием'], i, "pdf")}
                            >
                                <i className="far fa-file-pdf mr-3"></i>
                                Открыть
                            </Dropdown.Item>
                        ) : (
                            <Dropdown.Item
                                as="a"
                                href={config.api + "/appointments/getProtocolPDF/" + row['Прием'] + "/" + i}
                                target={"_blank"}
                            >
                                <i className="far fa-file-pdf mr-3"></i>
                                Открыть
                            </Dropdown.Item>
                        )
                    }

                    <Dropdown.Divider/>
                    <Dropdown.Item
                        as="button"
                        onClick={() => props.getProtocol(row['Прием'], i, "email")}
                    >
                        <i className="far fa-envelope mr-3"></i>
                        Отправить на e-mail
                    </Dropdown.Item>
                </DropdownButton>
            );
        }

        return (
            <div className={`d-flex ${(config.isMobile) ? "justify-content-start my-0" : "justify-content-end" } `}>
                {btns}
            </div>
        );
    }

    const pastColumns = [
        {
            dataField: 'Дата',
            text: 'Дата',
            sort: true,
            formatter: (cellContent, row) => {
                return dateFormat(cellContent + "+05:00", "dd.mm.yyyy");
            }
        },
        {
            dataField: 'Врач',
            text: 'Специалист',
            sort: true,
        }, {
            dataField: 'Наименование',
            text: 'Наименование',
            sort: true,
        },
        {
            dataField: 'НомерПротокола',
            text: 'Протоколы',
            classes: 'text-center',
            headerClasses: 'text-center',
            formatter: (cellContent, row) => renderBtns(row)
        }
    ];

    const defaultSorted = [{
        dataField: 'Дата',
        order: 'desc'
    }];

    function indication(){
        return (
            <h2 className="text-center my-3">Записей не найдено</h2>
        );
    }

    /*
    const expandRow = {
        onlyOneExpanding: false,
        renderer: row => (
            <ProtocolTable
                appointment={row['Прием']}
                protocols={row['НомерПротокола']}
            />
        ),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if(isAnyExpands) return <i className="far fa-minus-square fa-15x"></i>;
            else return <i className="far fa-plus-square fa-15x"></i>
        },
        expandColumnRenderer: ({ expanded }) => {
            if(expanded) return <i className="far fa-minus-square fa-15x"></i>;
            else return <i className="far fa-plus-square fa-15x"></i>
        },
        showExpandColumn: true,
        expandByColumnOnly: true
    };
     */



    if(config.isMobile) {
        if(props.pastList.length === 0)
            return (
                <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
                    <div className="">
                        <i className="fas text-primary fa-tasks fa-5x"></i>
                    </div>
                    <h2 className="text-center my-3">
                        Прошедших приёмов не найдено
                    </h2>
                </div>
            );
        return props.pastList.map( (u,i) => {
            return (
                <div className={"card px-3 pb-2 mb-4 bg-white"} key={i}>
                    <div className="mx-n3 pl-3 pr-1 py-2 d-flex align-items-center justify-content-between">
                        <h3 className={"mb-0"}>
                            <b>
                                {dateFormat(u['Дата'] + "+05:00", "dd.mm.yyyy")}
                            </b>
                        </h3>
                        <div>
                            {renderBtns(u)}
                        </div>
                    </div>

                    <div className={"mt-2"}>
                        <b>
                            {u['Наименование']}
                        </b>
                    </div>
                    <div>
                        {u['Врач']}
                    </div>
                </div>
            );
        });
    }

    /*
    <b className={"small"}>
                        Специалист
                    </b>
                    <div>
                        {u['Врач']}
                    </div>
                    <hr className={"my-2"}/>
                    <b className={"small"}>
                        Наименование
                    </b>
                    <div>
                        {u['Наименование']}
                    </div>

                    <hr className={"my-2"}/>
                    <b className={"small"}>
                        Протоколы
                    </b>
                    <div>
                        {renderBtns(u)}
                    </div>
     */

    else {
        return (
            <div className="card mt-3 overflow-hidden">
                <ToolkitProvider
                    keyField="Прием"
                    data={props.pastList}
                    columns={pastColumns}
                    search
                >
                    {
                        props => (
                            <div className={"table-responsive"}>

                                <div className="p-3">
                                    <TableSearch {...props.searchProps} />
                                </div>

                                <BootstrapTable
                                    bootstrap4
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    hover
                                    pagination={paginationFactory()}
                                    noDataIndication={indication}
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Past);

/*
formatter: (cellContent, row) => {
                let buttons = [];

                if(row['НомерПротокола']){
                    for(let i = 1; i<=row['НомерПротокола']; i++){
                        buttons.push(
                            <div className="mx-2">
                                <button
                                    onClick={()=> props.getProtocol(row['Прием'], i)}
                                    className="btn btn-outline-primary"
                                >
                                    <i className="far fa-file-alt"></i> {i}
                                </button>
                            </div>
                        );
                    }
                }
                else {
                    buttons = (
                        <div>
                            Нет протоколов
                        </div>
                    );
                }

                return (
                    <div className="d-flex justify-content-end">
                        {buttons}
                    </div>);

            }
 */
