import React, { useState, useEffect } from 'react';
import { setLoading, getBalance, redirect } from "../../redux/actions/commonActions";
import { showModal, getLinkToAddBalance } from "../../redux/actions/paymentActions";
import { cancelAppointment } from "../../redux/actions/appointmentsActions";
import { connect } from "react-redux";
import axios from "axios";

const mapStateToProps = state => {
    return {
        token: state.paymentReducer.token,
        show: state.paymentReducer.showModal,
        appID: state.paymentReducer.appID,
        isOnline: state.paymentReducer.isOnline,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (path) => dispatch(redirect(path)),
        showModal: (flag) => dispatch(showModal(flag)),
        getBalance: () => dispatch(getBalance()),
        cancelAppointment: (id) => dispatch(cancelAppointment(id)),
    };
};

function PaymentModal(props) {

    const [step, setStep] = useState(1);

    let checkout;
    let timer;

    useEffect(() => {
        renderPaymentModal();
    }, [props.token, props.show]);

    function closeModal() {
        clearInterval(timer);

        if(checkout) checkout.destroy();

        checkIfNeedToCancelAppointment();
        props.getBalance();

        if(props.appID && !props.isOnline) props.redirect("/appointments");

        setStep(1);
        props.showModal(false);
    }

    function checkIfNeedToCancelAppointment(){
        if(!props.appID) return;
        if(!props.isOnline) return;
        if(step === 2 ) return props.redirect("/appointments");
        props.cancelAppointment(props.appID);
    }

    async function checkPaymentResult() {
        try {
            const { data } = await axios.post("/api/payments/sbp-result", { key: props.token });
            if (window.ReactNativeWebView) {
                let dataToSend = {
                    event: "SBP",
                    url: ""
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(dataToSend));
            }
        }
        catch(e) {
            console.log(e);
        }
    }

    function renderPaymentModal(){
        if(!props.show) return null;
        if(!props.token) return null;

        /*
        if (window.ReactNativeWebView){
            let dataToSend = {
                event: "SBP",
                url: props.token
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(dataToSend));
            //closeModal();

            timer = setInterval(checkPaymentResult, 1000);

            return null;
        }

        return window.location.href = props.token;

         */

        try {
            checkout = new window.YooMoneyCheckoutWidget({
                confirmation_token: props.token,
                error_callback: function(error) {
                    console.log(error)
                }
            });

            checkout.on('complete', () => {
                setStep(2);
                checkout.destroy();
            });

            checkout.on('fail', () => {
                setStep(3);
                checkout.destroy();
            });

            setTimeout(function () {
                checkout.render('payment-form');
            }, 500);
        }
        catch (e) {
            console.log(e);
        }
    }

    if(!(props.token && props.show)) return null;

    return (
        <>
            <div className="modal fade show d-block" role="dialog">
                <div
                    className="modal-dialog modal-dialog-scrollable"
                    style={{
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        height: "100%"
                    }}
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header py-3">
                            <h5 className="modal-title">
                                Онлайн-оплата
                            </h5>
                            <button type="button" className="close" onClick={ closeModal }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body px-3">

                            <div className={step !== 1 ? "d-none" : ""} id="payment-form"></div>

                            <div className={step !== 2 ? "d-none" : ""}>
                                <div className="text-center container">
                                    <img className="img-fluid p-4" src="https://lk.medeor74.ru/success.png"/>
                                    <h1>Оплата успешно зачислена</h1>
                                    <div className={"my-4 text-center"}>
                                        <button className={"btn btn-primary"} onClick={ closeModal }>
                                            Закрыть окно
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={step !== 3 ? "d-none" : ""}>
                                <div className="text-center container">
                                    <img className="img-fluid p-4" src="https://lk.medeor74.ru/fail.png"/>
                                    <h1>Ошибка зачисления оплаты</h1>
                                    <h4 className="mt-4">
                                        Пожалуйста, обратитесь в контактный центр по телефону <a
                                        href="tel:+73512778808">8 (351) 277-88-08</a>
                                    </h4>
                                    <div className={"my-4 text-center"}>
                                        <button className={"btn btn-primary"} onClick={closeModal}>
                                            Закрыть окно
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);