import React, { useEffect } from 'react';
import { showModal } from "../../redux/actions/commonActions";
import { addAppointment } from "../../redux/actions/appointmentsActions";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        service: state.cosmetologyReducer.service,
        doctor: state.cosmetologyReducer.doctor,
        time: state.cosmetologyReducer.time,
        date: state.cosmetologyReducer.date,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (flag) => dispatch(showModal(flag)),
        addAppointment: (data) => dispatch(addAppointment(data))
    };
};

function m(props){

    const handleSubmit = () => {
        props.addAppointment({
            date: `${props.date}T${props.time}`,
            doctor: props.doctor['Врач'],
            service: props.service['Номенклатура']
        });
    };

    return (
        <>
            <div className="modal fade show d-block" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalCenterTitle">
                                Запись на приём
                            </h2>
                            <button type="button" className="close" onClick={ () => props.showModal(false) }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-3">

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(m);