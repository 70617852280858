import React, { useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getAnalyzes, getAnalyz } from '../redux/actions/analyzesActions';
import { setLoading, showImgModal } from '../redux/actions/commonActions';
import { withSnackbar } from 'notistack';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import TableSearch from "../components/TableSearchBar";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { DropdownButton, Dropdown} from "react-bootstrap";
import config from "../config";
import Header from "../components/Header";
import dateFormat from "dateformat";

const mapStateToProps = state => {
    return {
        data: state.analyzesReducer.analyzesList,
        loading: state.commonReducer.loading,
        user: state.commonReducer.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAnalyzes: () => dispatch(getAnalyzes()),
        getAnalyz: (analyz, mode) => dispatch(getAnalyz(analyz, mode)),
        setLoading: () => dispatch(setLoading()),
        showImgModal: (url) => dispatch(showImgModal(url))
    };
};

function AnalyzesScreen(props) {

    useLayoutEffect(() => {
        props.getAnalyzes();
    }, []);

    const columns = [
        {
            dataField: 'humanDate',
            text: 'Дата готовности',
            sort: true
        }, {
            dataField: 'Наименование',
            text: 'Наименование',
            sort: true,
        }, {
            dataField: 'Анализ',
            isDummyField: true,
            text: '',
            classes: 'text-right',
            headerClasses: 'text-center',
            formatter: (cellContent, row) => {
                return (
                    <>
                        <a
                            className="btn btn-light"
                            href={ config.api + "/analyzes/getAnalyzPDF/" + row['Анализ'] }
                            target={"_blank"}
                        >
                            <i className="fas fa-cloud-download-alt mr-3"></i>
                            Скачать
                        </a>
                        <button
                            className="btn btn-light ml-3 my-2"
                            onClick={() => props.getAnalyz(row['Анализ'], "email")}
                        >
                            <i className="far fa-envelope mr-3"></i>
                            Отправить на e-mail
                        </button>

                    <DropdownButton
                        title="Результаты "
                        variant="outline-primary"
                        alignRight
                        className="d-none"
                    >
                        {
                            (1 > 2) &&
                            <Dropdown.Item
                                as="button"
                                onClick={() => props.getAnalyz(row['Анализ'], "html")}
                            >
                                <i className="fas fa-eye mr-3"></i>
                                Просмотреть
                            </Dropdown.Item>
                        }

                        <Dropdown.Item
                            as="a"
                            href={ config.api + "/analyzes/getAnalyzPDF/" + row['Анализ'] }
                            target={"_blank"}
                        >
                            <i className="fas fa-cloud-download-alt mr-3"></i>
                            Скачать
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            as="button"
                            onClick={() => props.getAnalyz(row['Анализ'], "email")}
                        >
                            <i className="far fa-envelope mr-3"></i>
                            Отправить на e-mail
                        </Dropdown.Item>
                    </DropdownButton>
                    </>
                );
            }
        },
    ];

    function checkFormat(url){
        url = config.api + "/analyzes/getAnalyzPDF/" + url;
        props.showImgModal(url);
    }

    function indication(){
        return (
            <h2 className="text-center my-3">Записей не найдено</h2>
        );
    }

    function renderList(){
        if(!props.loading && props.data.length === 0)
            return (
                <>
                    <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
                        <div className="my-3">
                            <i className="fas text-primary fa-microscope fa-3x"></i>
                        </div>
                        <h2 className="text-center my-3">
                            Готовых анализов не найдено
                        </h2>
                    </div>
                </>

            );
        return props.data.map( (u,i) => {
            console.log(u['Анализ']);
            return (
                <div className="card px-3 mb-4 bg-white" key={`${i}`}>
                    <div className="d-flex justify-content-between mx-n3 py-2 pl-3 pr-2 align-items-center">
                        <h3 className={"mb-0"}>
                            {dateFormat(u['ДатаГотовности'] + "+05:00", "dd.mm.yyyy")}
                        </h3>
                        <DropdownButton
                            title="Результаты "
                            variant={ (config.isMobile) ? "outline-primary btn-sm" : "light" }
                            alignRight
                        >
                            {
                                (window.ReactNativeWebView) ? (
                                    <Dropdown.Item
                                        as="button"
                                        onClick={() => props.getAnalyz(u['Анализ'], "pdf")}
                                    >
                                        <i className="fas fa-cloud-download-alt mr-3"></i>
                                        Скачать
                                    </Dropdown.Item>
                                ) : (

                                    (u['Анализ'].indexOf(".jpg") > -1) ?
                                        (
                                            <Dropdown.Item
                                                as="button"
                                                onClick={ () => checkFormat(u['Анализ']) }
                                            >
                                                <i className="fas fa-cloud-download-alt mr-3"></i>
                                                Скачать
                                            </Dropdown.Item>
                                        ) : (
                                            <Dropdown.Item
                                                as="a"
                                                href={ config.api + "/analyzes/getAnalyzPDF/" + u['Анализ'] }
                                                target={"_blank"}
                                            >
                                                <i className="fas fa-cloud-download-alt mr-3"></i>
                                                Скачать
                                            </Dropdown.Item>
                                        )
                                )
                            }

                            <Dropdown.Divider />
                            <Dropdown.Item
                                as="button"
                                onClick={() => props.getAnalyz(u['Анализ'], "email")}
                            >
                                <i className="far fa-envelope mr-3"></i>
                                Отправить на e-mail
                            </Dropdown.Item>
                        </DropdownButton>
                    </div>
                    <h3 className="my-4">
                        {u['Наименование']}
                    </h3>
                </div>
            );
        });
    }

    if(config.isMobile) {

        return (
            <>
                <Header back={1} title={"АНАЛИЗЫ"} />

                { renderList() }
            </>
        );
    }
    else {
        return (
            <>
            <Header back={1} showOnDesktop={1} title={"АНАЛИЗЫ"} />

            <div className="card overflow-hidden">
                <ToolkitProvider
                    keyField="id"
                    data={props.data}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <div className="table-responsive">
                                <div className="p-3 row mx-0">
                                    <div className="col">
                                        <TableSearch {...props.searchProps} />
                                    </div>
                                </div>
                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    hover
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                    noDataIndication={indication}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(AnalyzesScreen)));