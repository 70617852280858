import React from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import dateformat from 'dateformat';
import { addAppointment, addAppointmentAndPay } from "../../redux/actions/appointmentsActions";
import { enqueueSnackbar } from "../../redux/actions/notificationActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import config from "../../config";

class OnlineFreeTime extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            date: false,
            time: false,
            nav1: null,
            nav2: null,
            email: "",
            state: 1
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
            email: (this.props.user && this.props.user['ЭлПочта']) ? this.props.user['ЭлПочта'] : ""
        });
    }

    makeZapis = (day, humandate, time) => {
        this.setState({
            step: 2,
            date: day + "T" + time + ":00+05:00"
        });
    };

    handleSubmit = () => {
        let data = {
            date: this.state.date,
            doctor: this.props.doctor,
            service: this.props.service,
            dms: (this.state.dms) ? 1 : 0
        };
        this.props.addAppointment(data);
    };

    getPaymentUrl = () => {

        if(this.state.email.length < 6) return this.props.enqueueSnackbar({
            message: "Поле email обязательно",
            options: config.snackOptions.error
        });

        let data = {
            date: this.state.date,
            doctor: this.props.doctor,
            service: this.props.service,
            serviceName: this.props.serviceName,
            dms: (this.state.dms) ? 1 : 0,
            amount: this.props.price,
            email: this.state.email,
            isOnline: true
        };
        this.props.addAppointmentAndPay(data);
    };

    render() {

        if(this.props.dates && this.props.dates.length > 0){

            let settings1;

            if(this.props.dates && this.props.dates.length > 0 && this.props.dates.length <= 3 ){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    slidesToShow: this.props.dates.length,
                    slidesToScroll: 1,
                    centerPadding: "10px"
                };
            }

            if(this.props.dates && this.props.dates.length > 3){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    responsive: [
                        {
                            breakpoint: 7680,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "50px"
                            }
                        },
                        {
                            breakpoint: 425,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 320,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                centerPadding: "15px"
                            }
                        }
                    ]
                };
            }

            let settings2 = {
                dots: false,
                infinite: false,
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
                speed: 500,
                slidesToScroll: 1,
                adaptiveHeight: false
            };

            let days = this.props.dates.map((u,i) => {
                return (
                    <div key={i} className={"px-3"}>
                        <button type="button" className="btn btn-outline-secondary nav1 px-2">
                            {u.humanDate}
                        </button>
                    </div>
                );
            });

            let dates = this.props.dates.map((u,i) => {
                let times = u.times.map((time,j) => {
                    return (

                            <button
                                key={j}
                                type="button"
                                className="btn btn-primary lift"
                                onClick={() => this.makeZapis(u.date, u.humanDate, time)}
                            >
                                {time}
                            </button>
                    );
                });

                return (
                    <div key={i}>
                        <h3 className={"text-center"}><b>Время приёма</b></h3>
                        <div className={"timeGrid px-3"}>
                            {times}
                        </div>
                    </div>
                );
            });

            return (
                <>
                    <div className="position-relative">
                        <div className="form-group">
                            <input
                                type="email"
                                defaultValue={this.state.email}
                                required
                                className="form-control"
                                placeholder="Укажите ваш email"
                                onChange={e => this.setState({email: e.target.value})}
                            />
                            <small>
                                Укажите адрес вашей электронной почты. Перед онлайн-приемом мы пришлём вам письмо с
                                инструкцией к подключению.
                            </small>
                        </div>
                        <div className="form-group1 d-none">
                            <div
                                className="d-flex align-items-center"
                                style={{cursor: 'pointer'}}
                                onClick={() => this.setState({dms: !this.state.dms})}
                            >
                                {
                                    (this.state.dms) ?
                                        <i className="far fa-check-square  fa-15x"></i> :
                                        <i className="far fa-square fa-15x"></i>
                                }
                                <h3 className="ml-3 mb-0" style={{userSelect: "none"}}>
                                    У меня есть полис ДМС
                                </h3>
                            </div>
                        </div>
                        <div className="form-group mb-0">
                            <p>Выберите дату и нажмите на кнопку с подходящим для Вас временем</p>
                            <div className="border py-3 rounded overflow-hidden position-relative mh250">
                                <div className={(this.state.step === 2) ? "invisible" : ""}>
                                    <h3 className={"text-center"}>
                                        <b>Даты приёма</b>
                                    </h3>
                                    <Slider
                                        asNavFor={this.state.nav2}
                                        ref={slider => (this.slider1 = slider)}
                                        {...settings1}
                                    >
                                        {days}
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </Slider>

                                    <hr/>

                                    <Slider
                                        asNavFor={this.state.nav1}
                                        ref={slider => (this.slider2 = slider)}
                                        {...settings2}
                                    >
                                        {dates}
                                    </Slider>
                                </div>
                                <div
                                    className={"zapis-form justify-content-start rounded p-3 alert-light " + ((this.state.step === 2) ? " zapis-form-filled" : "")}>
                                    <div>
                                        <button
                                            onClick={() => this.setState({step: 1})}
                                            className="btn btn-sm btn-outline-secondary"
                                        >
                                            <i className="fas fa-chevron-left mr-2"></i>
                                            Изменить
                                        </button>
                                    </div>

                                    <div className="mt-4">
                                        <h3 className={"text-center"} style={{lineHeight: '2rem'}}>
                                            Вы выбрали запись на онлайн-консультацию
                                            <br/>
                                            <b>{dateformat(this.state.date, "dd.mm.yyyy в HH:MM")}</b>
                                        </h3>
                                        <button
                                            className={`d-block mx-auto btn btn-primary ${(this.props.price === 0) ? "d-none" : ""}`}
                                            onClick={this.getPaymentUrl}
                                        >
                                            Записаться и оплатить онлайн
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }

        //if(this.props.token) return this.renderPaymentModal();

        return null;
    }
}

const mapStateToProps = state => {
    return {
        dates: state.onlineReducer.dates,
        user: state.commonReducer.user,
        service: state.onlineReducer.service,
        doctor: state.onlineReducer.doctor,
        price: state.onlineReducer.price,
        serviceName: state.onlineReducer.serviceName,
        serviceOnline: state.onlineReducer.serviceOnline,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        enqueueSnackbar: text => dispatch(enqueueSnackbar(text)),
        addAppointment: (data) => dispatch(addAppointment(data)),
        addAppointmentAndPay: data => dispatch(addAppointmentAndPay(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineFreeTime);