import React from "react";

const TableSearch = (props) => {
    let input;
    const handleClick = () => {
        props.onSearch(input.value);
    };
    const handleClear = () => {
        input.value = "";
        handleClick();
    };
    return (
        <div className="d-flex align-items-center">
            <i className="fas fa-search fa-1x mr-3 text-secondary"></i>
            <div className="input-group">
                <input onChange={handleClick} ref={ n => input = n } type="search" className="form-control search" placeholder="Поиск" />
                <div className="input-group-append">
                    <button className="btn btn-outline-secondary" onClick={handleClear}>
                        Очистить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TableSearch;