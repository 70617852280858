import React, { Component } from 'react';

export default class CheckBox extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            app: [],
            loading: true
        }
    }

    handleCheck = () => {
        if(this.props.p) this.props.checked('');
        else this.props.checked(this.props.label);
    };

    render () {
        return (
            <p
                className={"my-3 d-flex align-items-center"}
                onClick={this.handleCheck}
                style={{cursor: 'pointer'}}
            >
                {(this.props.p) ? (
                    <i className="fas fa-check-circle text-primary fa-2x"></i>
                ) : (
                    <i className="far fa-circle text-primary fa-2x"></i>
                )}
                <span className="ml-3" style={{userSelect: 'none'}}>
                    {this.props.label}
                </span>
            </p>
        );
    }
}