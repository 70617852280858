import React, { useLayoutEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { setLoading, getBonusCode, getBonusHistory } from '../redux/actions/commonActions';
import { withSnackbar } from 'notistack';

const mapStateToProps = state => {
    return {
        loading: state.commonReducer.loading,
        user: state.commonReducer.user,
        code: state.commonReducer.code,
        bonusHistory: state.commonReducer.bonusHistory,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCode: () => dispatch(getBonusCode()),
        getBonusHistory: () => dispatch(getBonusHistory()),
        setLoading: () => dispatch(setLoading()),
    };
};

function BonusCodeScreen(props) {

    useLayoutEffect(() => {
        props.getCode();
        props.getBonusHistory();
    }, []);

    let bonusHistory = props.bonusHistory.map( ( item, i) => {
        let date ="";
        let time = "";
        let amount;

        if(item['Период']) {
            let arr = item['Период'].split("T");
            let arr1 = arr[0].split("-");
            let arr2 = arr[1].split(":");

            date = `${arr1[2]}.${arr1[1]}.${arr1[0]}`;
            time = `${arr2[0]}:${arr2[1]}`;
        }

        if( item['Количество'] !== undefined ){
            if(item['Количество'] > 0){
                amount = "+" + item['Количество'];
            }
            else amount = item['Количество'];
        }

        return (
            <div className={`py-2 px-4 d-flex align0items-center justify-content-between ${ i%2 ? "bg-light1" : "" } border-top`}>
                <div>
                    { date } в { time }
                </div>
                <div>
                    { amount }
                </div>
            </div>
        );
    });

    return (
        <div className="mt-4">
            <div className={"card mx-auto col-12 col-md-6"}>
                <div className="card-body">
                    <h3 className="text-center mt-3">
                        Ваш код для списания бонусов
                    </h3>
                    {
                        window.ReactNativeWebView ? (
                            <div className="text-center display-1">
                                {props.code}
                            </div>
                        ) : (
                            <div className="alert alert-light text-center">
                                <b className="text-center">
                                    Доступен только в мобильном приложении
                                </b>
                            </div>
                        )
                    }
                </div>
            </div>
            {
                window.ReactNativeWebView ? (
                    <div className="text-center my-5">
                        <button className="btn btn-primary" onClick={() => props.getCode()}>
                            Обновить код
                        </button>
                    </div>
                ) : null
            }
            <div className={"card mx-auto col-12 col-md-6 px-0"}>
                <div className={"card-header bg-light-gray d-flex justify-content-between"}>
                    <h2 className={"m-0"}>
                        Начисление и списание бонусов
                    </h2>
                </div>
                <div className="card-body p-0">
                    { (props.bonusHistory && props.bonusHistory.length) ? bonusHistory :
                        <div className={"text-center p-3"}>
                            Пока не было начисления или списания бонусов
                        </div>
                    }
                </div>
            </div>
        </div>

    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(BonusCodeScreen)));