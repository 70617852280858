import React, { useState, useLayoutEffect } from 'react';
import {withRouter, Link} from 'react-router-dom';
import { connect } from "react-redux";
import { getFutureAppointments, getPastAppointments, getProtocol } from '../redux/actions/appointmentsActions';
import { setLoading } from '../redux/actions/commonActions';
import { withSnackbar } from 'notistack';
import Future from '../components/appointments/Future';
import Past from '../components/appointments/Past';
import config from "../config";
import Header from "../components/Header";

const mapStateToProps = state => {
    return {
        futureList: state.appointmentsReducer.futureList,
        pastList: state.appointmentsReducer.pastList,
        loading: state.commonReducer.loading,
        showProtocol: state.appointmentsReducer.showProtocol,
        protocolBody: state.appointmentsReducer.protocolBody
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFutureAppointments: () => dispatch(getFutureAppointments()),
        getPastAppointments: () => dispatch(getPastAppointments()),
        getProtocol: (appointment, protocol) => dispatch(getProtocol(appointment, protocol)),
        setLoading: () => dispatch(setLoading())
    };
};

function AppointmentsScreen(props) {
    const [filter, setFilter] = useState(0);
    let updated = false;

    useLayoutEffect(() => {
        if (!updated){
            props.getFutureAppointments();
            props.getPastAppointments();
            updated = true;
        }
    }, []);

    return(
        <div>
            <Header title={"ПРИËМЫ"} showOnDesktop={1} back={1} />

            { config.isMobile ? (
                <div className="mx-n3 mb-3 bg-white">
                    <div className="row pt-2">
                        <div className={"col-6 text-center border-bottom border-2 " + ((filter === 0) ? "border-primary" : "")}>
                            <button className="btn py-3" onClick={()=> setFilter(0)}>
                                Предстоящие
                            </button>
                            {
                                (props.futureList && props.futureList.length > 0) &&
                                <span className="ml-n2 badge badge-pill number badge-danger">{props.futureList.length}</span>
                            }
                        </div>
                        <div className={"col-6 text-center border-bottom border-2 " + ((filter === 1) ? "border-primary" : "")}>
                            <button className="btn py-3" onClick={()=> setFilter(1)}>
                                Прошедшие
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-between">
                    <ul className="nav nav-tabs nav-overflow align-items-center flex-grow-1">
                        <li className="nav-item">
                            <a
                                href="#"
                                className={(filter === 0) ? "nav-link active" : "nav-link"}
                                onClick={()=> setFilter(0)}
                            >
                                Предстоящие
                                {
                                    (props.futureList && props.futureList.length > 0) &&
                                    <span className=" ml-2 badge badge-pill number badge-danger">{props.futureList.length}</span>
                                }
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="#"
                                className={(filter === 1) ? "nav-link active" : "nav-link"}
                                onClick={()=> setFilter(1)}
                            >
                                Прошедшие
                                {
                                    (props.pastList && props.pastList.length > 0) &&
                                    <span className=" ml-2 badge badge-pill number badge-secondary">{props.pastList.length}</span>
                                }
                            </a>
                        </li>
                    </ul>
                    <div className="nav nav-tabs align-items-center">
                        <Link
                            to={"/online"}
                            className="btn btn-primary"
                        >
                            Записаться
                        </Link>
                    </div>
                </div>
            )}

            {
                (filter === 0) && <Future />
            }
            {
                (filter === 1) && <Past />
            }
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(AppointmentsScreen)));
