import * as ACTIONS from "../actionTypes";
import axios from "axios";
import config from "../../config";
import {setLoading, handleError, redirect, showModal} from "./commonActions";

export function getSpecs() { //получаем список специальностей
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_COSMETOLOGY_SPECIALITIES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/cosmetology/getSpecs', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getDates() {
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_COSMETOLOGY_DATES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/cosmetology/getFreeTime', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function selectTime(data) {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.COSMETOLOGY_SELECT_TIME,
            payload: data
        });
    }
}

export function getServices(req = {}) { //получаем список специальностей
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_COSMETOLOGY_SERVICES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/cosmetology/getServices', req, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function selectService(data) {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.COSMETOLOGY_SELECT_SERVICE,
            payload: data
        });
    }
}

export function getDoctors() {
    return function(dispatch) {
        dispatch(setLoading(true));

        dispatch( {
            type: ACTIONS.GET_COSMETOLOGY_DOCTORS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/cosmetology/getDoctors', {}, config.axiosOptions)
                    .then((res) => {
                        const { doctors } = res.data;
                        resolve(doctors);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function selectDoctor(doctor) {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.COSMETOLOGY_SELECT_DOCTOR,
            payload: doctor
        });
        dispatch({
            type: ACTIONS.SHOW_MODAL,
            payload: false
        });
    }
}
export function preselectDoctor(doc) {
    return function(dispatch) {
        dispatch(setLoading(true));

        dispatch( {
            type: ACTIONS.GET_COSMETOLOGY_DOCTORS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/cosmetology/getDoctors', {}, config.axiosOptions)
                    .then((res) => {
                        const { doctors } = res.data;
                        resolve(doctors);

                        doctors.forEach( d => {
                            if(d['Врач'] === doc) dispatch(selectDoctor(d));
                        });

                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}