import React, { useEffect, useLayoutEffect } from 'react';
import SelectDoctor from '../components/cosmetology/SelectDoctor';
import SelectedTime from "../components/cosmetology/SelectedTime";
import SelectedService from "../components/cosmetology/SelectedService";
import MakeApp from "../components/cosmetology/MakeApp";
import Header from "../components/Header";
import { getSpecs, getDocs } from "../redux/actions/onlineZapisActions";
import {connect} from "react-redux";
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "../redux/actionTypes";

const mapStateToProps = state => {
    return {
        specs: state.onlineReducer.specialities,
        spec: state.onlineReducer.speciality,
        dates: state.onlineReducer.dates,
        loading: state.commonReducer.loading,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSpecs: () => dispatch(getSpecs()),
        getDocs: (spec) => dispatch(getDocs(spec))
    };
};

function OnlineScreen(props){

    useEffect(() => {
        if(props.style === CLINIC_INTERFACE) props.history.push("/apps");
    }, [props.style]);

    return (
        <>
            <Header title={"ОНЛАЙН ЗАПИСЬ"} showOnDesktop={1} />

            <div className="row">
                <div className="col-12 col-lg-6 mx-auto d-flex1">
                    <div className="card1 flex-grow-10">
                        <div className="card-body1 pb-5">
                            <SelectDoctor />
                            <div className={"py-3"}></div>
                            <SelectedTime />
                            <div className={"py-3"}></div>
                            <SelectedService />
                            <div className={"py-3"}></div>
                            <MakeApp />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineScreen);
