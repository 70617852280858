import React from 'react';
import {connect} from "react-redux";
import { withRouter, Link} from "react-router-dom";
import Header from "../components/Header";
import { CLINIC_INTERFACE, COSMETOLOGY_INTERFACE } from "../redux/actionTypes";
import { switchInterface } from "../redux/actions/interfaceActions";

const mapStateToProps = state => {
    return {
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        switchInterface: (type) => {
            if(window.ReactNativeWebView) {
                const msg = {
                    event: "SWITCH_INTERFACE",
                    color: ( type === COSMETOLOGY_INTERFACE ) ? "#d6ab6b" : "#742239"
                };
                window.ReactNativeWebView.postMessage(JSON.stringify(msg));
            }
            dispatch(switchInterface(type));
        }
    };
};

function MoreScreen(props){

    return (
        <>
            <Header title={"ЕЩË"}/>
            <div
                className="card p-3 cursor-pointer"
                onClick={() => props.history.push('/settings')}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div>
                            <b>
                                Профиль
                            </b>
                        </div>
                    </div>
                    <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                </div>
            </div>

            <div
                className="card p-3 cursor-pointer"
                onClick={() => props.history.push('/notifications')}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div>
                            <b>
                                Уведомления
                            </b>
                        </div>
                    </div>
                    <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                </div>
            </div>

            <div
                className="card p-3 cursor-pointer"
                onClick={() => props.history.push('/contact')}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div>
                            <b>
                                Контактная информация
                            </b>
                        </div>
                    </div>
                    <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                </div>
            </div>


            {
                (props.style === COSMETOLOGY_INTERFACE) &&
                <div
                    className="card p-3 cursor-pointer"
                    onClick={() => props.switchInterface(CLINIC_INTERFACE)}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <div>
                                <b>
                                    Перейти в Медцентр
                                </b>
                            </div>
                        </div>
                        <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                    </div>
                </div>
            }

            {
                (props.style === CLINIC_INTERFACE) &&
                <div
                    className="card p-3 cursor-pointer"
                    onClick={() => props.switchInterface(COSMETOLOGY_INTERFACE)}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <div>
                                <b>
                                    Перейти в Косметологию
                                </b>
                            </div>
                        </div>
                        <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                    </div>
                </div>
            }

            <div
                className="card p-3 cursor-pointer"
                onClick={() => props.history.push('/policy')}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <div>
                            <b>
                                Положение об обработке персональных данных
                            </b>
                        </div>
                    </div>
                    <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                </div>
            </div>
        </>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(MoreScreen);