import React, {useLayoutEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getBanners } from '../../redux/actions/commonActions';

function PromoSlider(props) {

    useLayoutEffect( () => {
        props.getBanners(props.style);
    }, []);

    //const slidesToShow = (props.promos && props.promos.length === 1) ? 1 : 2;

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,//slidesToShow,
        speed: 500,
        slidesToScroll: 1,
        adaptiveHeight: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 450,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    swipeToSlide: true
                }
            }
        ]
    };

    function redirectToLink(link) {

        if(link.indexOf("lk.medeor74.ru") > -1){

            let arr = link.split("lk.medeor74.ru");

            if(arr[1]) props.history.push(arr[1]);
        }
        else window.ReactNativeWebView.postMessage(link)
    }

    if(props.promos && props.promos.length){
        return (
            <Slider {...settings} >
                {
                    props.promos.map( (u,i) => {
                        return (
                            <div key={i} className="mb-4 p-md-3">
                                {
                                    (window.ReactNativeWebView) ? (
                                        <img
                                            src={`data:image/jpg;base64,${u["ДанныеКартинки"]}`} className="img-fluid rounded border border-2 mx-auto" alt=""
                                            onClick={ () => redirectToLink( u['АдресПерехода']) }
                                        />
                                    ) : (
                                        <a href={u['АдресПерехода']} target="_blank">
                                            <img src={`data:image/jpg;base64,${u["ДанныеКартинки"]}`} className="img-fluid rounded border border-2 mx-auto" alt=""/>
                                        </a>
                                    )
                                }
                            </div>
                        );
                    })
                }
            </Slider>
        );
    }
    else return null;
}

const mapStateToProps = state => {
    return {
        promos: state.commonReducer.banners,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBanners: (type) => dispatch(getBanners(type))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PromoSlider));