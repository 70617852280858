import React from "react";
import Notifications from "../components/home/Notifications";
import Header from "../components/Header";

export default function NotificationsScreen(props) {
      return (
          <>
            <Header back={1} title={"УВЕДОМЛЕНИЯ"} showOnDesktop={1} />
            <Notifications />
          </>
      )
            ;
}