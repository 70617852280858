import React from 'react';
import { connect } from "react-redux";
import { cancelAppointment } from '../../redux/actions/appointmentsActions';
import { setLoading } from '../../redux/actions/commonActions';
import dateFormat from "dateformat";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import CancelButton from "./CancelButton";
import FutureAppointment from "./FutureAppointment";
import { withRouter } from 'react-router-dom';
import config from "../../config";

const mapStateToProps = state => {
    return {
        futureList: state.appointmentsReducer.futureList,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: () => dispatch(setLoading()),
        cancelAppointment: (guid) => dispatch(cancelAppointment(guid))
    };
};

function Future(props) {

    const screenWidth = window.screen.width;

    const futureColumns = [
        {
            dataField: 'лкДата',
            text: 'Дата',
            sort: true,
            formatter: (cellContent, row) => {
                return cellContent;
                //return dateFormat(cellContent + "+05:00", "dd.mm.yyyy в HH:MM");
            },
            headerClasses: 'sort'
        },
        {
            dataField: 'Специальность',
            text: 'Специальность',
            sort: true,
            hidden: false
        },
        {
            dataField: 'Работы',
            text: 'Кол-во услуг',
            classes: 'text-center',
            headerClasses: 'text-center',
            formatter: (cellContent, row) => {
                return row['Работы'].length;
            }
        },
        {
            dataField: '',
            isDummyField: true,
            text: 'Общая сумма',
            formatter: (cellContent, row) => {
                let summ = 0;
                row['Работы'].forEach((u,i) => {
                    summ += u['Цена'];
                });
                return summ + " руб.";
            }
        },
        {
            dataField: 'Специалист',
            text: 'Специалист',
            sort: true,
            hidden: true
        }, {
            dataField: 'Запись',
            isDummyField: true,
            text: '',
            formatter: (cellContent, row) => {
                return <CancelButton appointment={row['Запись']} />;
            }
        }
    ];

    const defaultSorted = [{
        dataField: 'Дата',
        order: 'asc'
    }];

    function indication(){
        return (
            <h2 className="text-center my-3">Записей не найдено</h2>
        );
    }

    const subColumns = [
        {
            dataField: 'лкДатаНачала',
            text: 'Начало',
            sort: true,
            formatter: (cellContent, row) => {
                return cellContent;
                //return dateFormat(cellContent + "+05:00", "dd.mm.yyyy в HH:MM");
            }
        },
        {
            dataField: 'Специалист',
            text: 'Специалист',
        }, {
            dataField: 'Специальность',
            text: 'Специальность',
        },
        {
            dataField: 'Продолжительность',
            text: 'Продолжительность',
            formatter: (cellContent, row) => {
                return dateFormat(cellContent, "MM минут");
            }
        }, {
            dataField: 'Цена',
            text: 'Стоимость',
            formatter: (cellContent, row) => {
                return cellContent + " руб.";
            }
        }, {
            dataField: 'Подготовка',
            text: 'Подготовка',
            formatter: (cellContent, row) => {
                return (
                    <div className="bg-danger text-white p-2">
                        {cellContent}
                    </div>
                );
            }
        },
    ];

    const expandRow = {
        onlyOneExpanding: false,
        renderer: row => (
            <div className="">
                <div className="card mb-0 shadow-none overflow-hidden">
                    <BootstrapTable
                        bootstrap4
                        keyField={'Номер'}
                        bordered={ false }
                        classes={"mb-0"}
                        headerClasses="thead-white"
                        hover
                        columns={subColumns}
                        data={row['Работы']}
                    />
                </div>
            </div>
        ),
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if(isAnyExpands) return <i className="far fa-minus-square fa-15x"></i>;
            else return <i className="far fa-plus-square fa-15x"></i>
        },
        expandColumnRenderer: ({ expanded }) => {
            if(expanded) return <i className="far fa-minus-square fa-15x"></i>;
            else return <i className="far fa-plus-square fa-15x"></i>
        },
        showExpandColumn: true,
        expandByColumnOnly: true
    };

    if(config.isMobile) {
        if(props.futureList.length === 0)
            return (
                <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
                    <div className="">
                        <i className="fas text-primary fa-tasks fa-5x"></i>
                    </div>
                    <h2 className="text-center my-3">
                        Предстоящих записей не найдено
                    </h2>
                    <div className="mt-5">
                        <button
                            className="btn btn-primary"
                            onClick={() => props.history.push('/online')}
                        >
                            Записаться онлайн
                        </button>
                    </div>
                </div>
            );
        return props.futureList.map( (u,i) => <FutureAppointment appointment={u} key={u['Запись']} /> );
    }
    else {
        return(
            <div className="card mt-3 overflow-hidden">
                <div className="">
                    <ToolkitProvider
                        keyField="Запись"
                        data={ props.futureList }
                        columns={ futureColumns }
                        search
                    >
                        {
                            props => (
                                <div className={"table-responsive"}>
                                    <div className="">
                                    </div>
                                    <BootstrapTable
                                        bootstrap4
                                        defaultSorted={defaultSorted}
                                        bordered={ false }
                                        hover
                                        expandRow={ expandRow }
                                        pagination={ paginationFactory() }
                                        noDataIndication={ indication }
                                        { ...props.baseProps }
                                    />
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Future));
