import React, {useState, useEffect, useLayoutEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import { Form } from "react-bootstrap";
import LoadingIndicator from "../components/LoadingIndicator";
import { setLoading, sendRegCode, checkRegCode, submitRegistration } from "../redux/actions/commonActions";

const mapStateToProps = state => {
    return {
        loading: state.commonReducer.loading,
        smsWasSent: state.registerReducer.smsWasSent,
        step: state.registerReducer.step,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (flag) => dispatch(setLoading(flag)),
        sendRegCode: (phone) => dispatch(sendRegCode(phone)),
        checkRegCode: (phone, code) => dispatch(checkRegCode(phone, code)),
        submitRegistration: (data) => dispatch(submitRegistration(data)),
    };
};

function LoginScreen(props) {

    const [obj, setObj] = useState({
        tel: "8",
        code: "",
        f: "",
        i: "",
        o: "",
        day: "",
        g: "",
        month: "",
        year: "",
        city: "",
        checked: true
    });
    const [show, setShowModal] = useState(false);

    useLayoutEffect(() => {
        props.setLoading(false);

        if(window.location.search && window.location.search.indexOf("p=") > -1){
            let startPos = window.location.search.indexOf("p=") + 2;

            setObj({
                ...obj,
                tel: window.location.search.substring(startPos, startPos + 11)
            });
        }

        sessionStorage.removeItem("welcomeShown");
    }, []);

    function handleChange(e){

        let value = e.target.value;

        if(e.target.name === "f" || e.target.name === "i" || e.target.name === "o"){
            value = e.target.value.replace(/[^а-яА-Я]+/gi, "");
        }

        if(e.target.name === "checked"){
            value = !obj.checked;
        }

        if(e.target.name === "day"){
            value = e.target.value.replace(/\D+/gi, "");
        }

        if(e.target.name === "year"){
            value = e.target.value.replace(/\D+/gi, "");
        }

        setObj({
            ...obj,
            [e.target.name]: value
        });
    }

    function handleTelChange(e) {
        let value = e.target.value.replace(/\D+/gi, "");

        if(value.length === 0){
            value = 8;
        }

        setObj({
            ...obj,
            tel: value
        });
    }

    function handleSendCode(){
        props.sendRegCode(obj.tel);
    }

    function handleCodeChange(e){
        let value = e.target.value.replace(/\D+/gi, "");

        setObj({
            ...obj,
            code: value
        });
    }

    function handleCheckCode(){
        props.checkRegCode(obj.tel, obj.code);
    }

    function handleSubmitRegistration(){
        props.submitRegistration(obj);
    }

    function toggleModal(){
        setShowModal(!show);
    }

    return (
        <div className={"p-2 position-relative"}>
            <div className="mt-5 d-flex align-items-center justify-content-center">
                <img src="/logo.png" className="mr-2 mb-n2" height="50" alt=""/>
                <h1 className={"logo mb-0"}>
                    медеор
                </h1>
            </div>

            <h1 className="text-center mt-5">
                Регистрация в личном кабинете МЦ "Медеор"
            </h1>

            <div className={"card shadow mt-2" + ((props.loading) ? " blurred" : "") } style={{ maxWidth: 600, margin: "auto" }}>
                <div className="card-body">

                    {
                        (props.step === 1 && !props.smsWasSent) && (
                            <Form.Group controlId="phone">
                                <Form.Label className=""><b>Телефон</b></Form.Label>
                                <Form.Control
                                    type="tel"
                                    autoComplete={"off"}
                                    name="phone"
                                    className="form-control-lg"
                                    value={obj.tel}
                                    maxLength={11}
                                    required
                                    disabled={props.step && props.step === 2}
                                    onChange={handleTelChange}
                                />
                            </Form.Group>
                        )
                    }

                    {
                        (!props.loading && props.step === 1 && obj.tel.length === 11 && !props.smsWasSent) && (
                            <div className="alert alert-light">
                                <p className="text-center">
                                    Для подтверждения номера телефона вам будет отправлен проверочный код в СМС на номер {obj.tel}
                                </p>
                                <button
                                    className="btn btn-primary form-control lift form-control-lg"
                                    onClick={handleSendCode}
                                >
                                    Получить код
                                </button>
                            </div>)
                    }

                    {
                        (!props.loading && props.smsWasSent && props.step === 1) && (
                            <div className="alert alert-light">
                                <p className="text-center">
                                    Проверочный код был отправлен на номер {obj.tel}
                                </p>
                                <div className={""}>
                                    <Form.Group controlId="phonecode">
                                        <Form.Label className=""><b>Код из СМС</b></Form.Label>
                                        <Form.Control
                                            type="number"
                                            autoComplete={"off"}
                                            name="code"
                                            className="form-control-lg"
                                            value={obj.code}
                                            maxLength={6}
                                            required
                                            onChange={handleCodeChange}
                                        />
                                    </Form.Group>
                                </div>
                                <button
                                    className="btn btn-primary form-control lift form-control-lg"
                                    onClick={handleCheckCode}
                                >
                                    Подтвердить
                                </button>
                                <div className={"my-5"}></div>
                                <button
                                    className="btn btn-white form-control lift form-control-lg"
                                    onClick={handleSendCode}
                                >
                                    Отправить повторно
                                </button>
                            </div>)
                    }

                    {
                        (!props.loading && props.step === 2) && (
                            <div className={""}>
                                <Form.Group controlId="lastname">
                                    <Form.Label className=""><b>Фамилия</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete={"off"}
                                        name="f"
                                        className="form-control-lg"
                                        value={obj.f}
                                        required
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="firstname">
                                    <Form.Label className=""><b>Имя</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete={"off"}
                                        name="i"
                                        className="form-control-lg"
                                        value={obj.i}
                                        required
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="middlename">
                                    <Form.Label className=""><b>Отчество</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete={"off"}
                                        name="o"
                                        className="form-control-lg"
                                        value={obj.o}
                                        required
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <div>
                                    <Form.Group controlId="gender">
                                        <Form.Label className=""><b>Пол</b></Form.Label>
                                        <select
                                            name="g"
                                            value={obj.g}
                                            className={"form-control"}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value=""></option>
                                            <option value="М">Мужской</option>
                                            <option value="Ж">Женский</option>
                                        </select>
                                    </Form.Group>
                                </div>

                                <div className={""}>
                                    <Form.Group controlId="bd">
                                        <Form.Label className=""><b>Дата рождения</b></Form.Label>

                                        <div className={"d-flex align-items-center justify-content-between"}>
                                            <Form.Control
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete={"off"}
                                                name="day"
                                                className="form-control-lg"
                                                value={obj.day}
                                                required
                                                min={1}
                                                max={31}
                                                step={1}
                                                onChange={handleChange}
                                            />

                                            <div className={"mx-1"}>.</div>

                                            <select
                                                name="month"
                                                value={obj.month}
                                                className={"form-control"}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value=""></option>
                                                <option value="01">января</option>
                                                <option value="02">февраля</option>
                                                <option value="03">марта</option>
                                                <option value="04">апреля</option>
                                                <option value="05">мая</option>
                                                <option value="06">июня</option>
                                                <option value="07">июля</option>
                                                <option value="08">августа</option>
                                                <option value="09">сентября</option>
                                                <option value="10">октября</option>
                                                <option value="11">ноября</option>
                                                <option value="12">декабря</option>
                                            </select>

                                            <div className={"mx-1"}>.</div>

                                            <Form.Control
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete={"off"}
                                                name="year"
                                                className="form-control-lg"
                                                value={obj.year}
                                                min={1900}
                                                max={new Date().getFullYear()}
                                                step={1}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>

                                <Form.Group controlId="cityname">
                                    <Form.Label className=""><b>Город</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        autoComplete={"off"}
                                        name="city"
                                        className="form-control-lg"
                                        value={obj.city}
                                        required
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <div className="form-group">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="checked"
                                            id="check1"
                                            checked={ obj.checked }
                                            onChange={ handleChange }
                                        />
                                            <label className="form-check-label" htmlFor="check1" style={{ userSelect: "none"}}>
                                                Нажимая кнопку «Зарегистрироваться»,
                                                я даю свое согласие на обработку моих персональных данных,
                                                в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ
                                                «О персональных данных», на условиях и для целей, определенных в
                                                <a
                                                    data-toggle="modal"
                                                    data-target="#exampleModal"
                                                    className="text-dark ml-2"
                                                    href="#"
                                                    onClick={ toggleModal }
                                                    style={{ textDecoration: "underline" }}
                                                >
                                                    Согласии на обработку персональных данных
                                                </a>.
                                            </label>
                                        </div>
                                </div>

                                <button
                                    className={`btn btn-primary form-control lift form-control-lg ${obj.checked ? "" : "d-none"} `}
                                    onClick={handleSubmitRegistration}
                                >
                                    Зарегистрироваться
                                </button>

                            </div>
                        )
                    }

                    <div className={"text-center mt-5"}>
                        <a
                            href="/login"
                            className="mt-3 btn btn-outline-secondary form-control form-control-lg"
                        >
                            <i className={"fas fa-chevron-left mr-2"}></i>
                            Назад
                        </a>
                    </div>
                </div>
            </div>

            {
                props.loading && (
                    <div className={"login-screen-loader login-screen-loader-show"} style={{ position: "fixed", top: 0, left: 0, backdropFilter: "blur(7px)" }}>
                        <LoadingIndicator />
                    </div>
                )
            }

            <div className={"py-5"}></div>

            {
                show && (
                    <>
                    <div
                        className="modal fade show border-1"
                        id="exampleModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        style={{ display: "block", paddingLeft: 0}}
                    >
                        <div className="rounded-lg modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">

                                <div className="modal-body text-dark">
                                    <p>Настоящим в соответствии с Федеральным законом №
                                    152-ФЗ «О персональных данных» от 27.07.2006 года свободно, своей волей и в своем
                                    интересе выражаю свое безусловное согласие на обработку моих персональных данных ООО МЦ
                                    "МЕДЕОР" (ОГРН 1157456003814, ИНН 7452123748), зарегистрированным в соответствии с
                                    законодательством РФ по адресу: УЛ. ГОРЬКОГО, ДОМ 16, ОФИС 328, 454071 ЧЕЛЯБИНСК
                                    ЧЕЛЯБИНСКАЯ ОБЛАСТЬ, Россия (далее по тексту - Оператор). Персональные данные&nbsp;-
                                    любая информация, относящаяся к определенному или определяемому на основании такой
                                    информации&nbsp;физическому лицу.</p><p>Настоящее Согласие выдано мною на обработку
                                    следующих персональных данных:<br/>- Имя;<br/>- Отчество;<br/>- Фамилия;<br/>- Телефон;<br/>- Дата рождения.</p>
                                    <p>Согласие дано Оператору для совершения следующих действий с моими
                                    персональными данными с использованием средств автоматизации и/или без использования
                                    таких средств: сбор, систематизация, накопление, хранение, уточнение (обновление,
                                    изменение), использование, обезличивание, а также осуществление любых иных действий,
                                    предусмотренных действующим законодательством РФ как неавтоматизированными, так и
                                    автоматизированными способами.</p><p>Данное согласие дается Оператору для обработки моих
                                    персональных данных в следующих целях:<br/>- предоставление мне услуг/работ;<br/>-
                                        направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ;<br/>-
                                            подготовка и направление ответов на мои запросы;<br/>- направление в мой адрес
                                                информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах
                                                Оператора.</p><p>Настоящее согласие действует до момента его отзыва путем
                                    направления соответствующего уведомления на электронный адрес medeor-med@mail.ru. В
                                    случае отзыва мною согласия на обработку персональных данных Оператор вправе продолжить
                                    обработку персональных данных без моего согласия при наличии оснований, указанных в
                                    пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального
                                    закона №152-ФЗ «О персональных данных» от 27.06.2006 г.</p></div>
                                <div className="modal-footer p-3">
                                    <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal"  onClick={ toggleModal }>Закрыть
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
                )
            }
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(LoginScreen)));