import React, { useEffect } from 'react';
import { getSpecs, getDocs } from "../../redux/actions/onlineZapisActions";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        specs: state.onlineReducer.specialities,
        spec: state.onlineReducer.speciality
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSpecs: () => dispatch(getSpecs()),
        getDocs: (spec) => dispatch(getDocs(spec))
    };
};

function Specs(props){
    useEffect(() => {
        //if(!props.spec.length) props.getSpecs();
    }, []);

    /*
    useEffect(() => {
        const selectedSpec = window.localStorage.getItem("selectedSpec");

        if(props.spec.length && selectedSpec) {
            props.getDocs(selectedSpec);
            window.localStorage.removeItem("selectedSpec");
        }
    }, [props.spec && props.spec.length]);

     */

    function onSpecChange(event) {
        if(event.target.value !== ""){
            props.getDocs(event.target.value, );
        }
    }

    if(props.specs && props.specs.length > 0)
        return (
            <div className="form-group">
                <div className={"alert alert-light mb-2"}>
                    <label htmlFor="specialitySelect">
                        <b>2. Выберите специализацию врача</b>
                    </label>
                </div>
                <select
                    className="form-control"
                    id="specialitySelect"
                    name={ "speciality" }
                    value={ props.spec }
                    onChange={ onSpecChange }
                >
                    <option value=""></option>
                    {
                        props.specs.map(function (s, i) {
                            return (
                                <option
                                    key={i}
                                    value={s['Специальность']}
                                >
                                    {s["Представление"]}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        );

    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(Specs);



