import { withRouter } from "react-router-dom";
import React, {useEffect} from "react";
import {bindActionCreators} from "redux";
import {enqueueSnackbar} from "../redux/actions/notificationActions";
import {logout} from "../redux/actions/commonActions";
import {connect} from "react-redux";

function BottomNavBar(props){

    useEffect( () => {

        if(props.history.location.pathname === "/"){
            if(window && window.sessionStorage && window.sessionStorage.getItem("homeRefreshed")){

                const lastRefreshTime = +window.sessionStorage.getItem("homeRefreshed");
                const age = new Date().getTime() - lastRefreshTime;
                const timeout = 1000 * 60 * 60 * 24;
                //const timeout = 1000 * 10;

                if(age > timeout ){
                    window.sessionStorage.setItem("homeRefreshed", `${new Date().getTime()}`);
                    window.location.reload();
                }
            }
            else {
                window.sessionStorage.setItem("homeRefreshed", `${new Date().getTime()}`);
            }
        }
    }, [props.history.location.pathname]);

    const inMainScreen = props.history.location.pathname === "/";

    const inMyCart = ( props.history.location.pathname.indexOf("cart") > -1
        || props.history.location.pathname.indexOf("appointments") > -1
        || props.history.location.pathname.indexOf("analyzes") > -1 );

    const inAppsRouter = ( props.history.location.pathname.indexOf("online") > -1
        || props.history.location.pathname.indexOf("teledoc") > -1
        || props.history.location.pathname.indexOf("apps") > -1 );

    const inDocsRouter = ( props.history.location.pathname.indexOf("doctors") > -1 );

    const inMoreRouter = (
        props.history.location.pathname.indexOf("more") > -1
        || props.history.location.pathname.indexOf("contact") > -1
        || props.history.location.pathname.indexOf("settings") > -1
        || props.history.location.pathname.indexOf("notifications") > -1 );

    return (
        <>
            <nav
                className="d-md-none navbar justify-content-between fixed-bottom bg-primary text-white px-2 border-0"
                style={ { borderTopLeftRadius: 10, borderTopRightRadius: 10, paddingTop: 12, paddingBottom: 60, bottom: -50 } }
            >
                <svg className={"d-none"} width="100%" height="54" style={{ transform: "rotate(180deg) translateY(47px)" }}>
                    <defs>
                        <pattern id="header-bottom-wave-pattern" x="0" y="0" width="54" height="54" patternUnits="userSpaceOnUse" style={{ fill: "#FFF" }}>
                            <path d="M31.967,9.869c0.26,0-0.015-0.016-0.064-0.023L31.967,9.869z M0.011,0v20.047c1.704-0.01,3.555-0.287,5.598-0.915 c6.48-1.994,14.031-14.058,26.286-9.288l0.104-0.004V9.834c7.733,2.945,12.696,10.543,21.989,10.193V0H0.011z"></path>
                        </pattern>
                        <filter id="header-bottom-wave-filter" x="0" y="0" width="100%" height="100%">
                            <feOffset result="offOut" in="SourceGraphic" dx="0" dy="4"></feOffset>
                            <feColorMatrix result="matrixOut" in="offOut" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="0"></feGaussianBlur>
                            <feBlend in="SourceGraphic" in2="blurOut" mode="normal"></feBlend>
                        </filter>
                    </defs>
                    <rect x="0" y="0" width="100%" height="54" style={{ fill: 'url(#header-bottom-wave-pattern)', filter: 'url(#header-bottom-wave-filter)' }}></rect>
                </svg>
                <div className={`col p-0 text-center ${inMainScreen ? "active-tab" : ""}`}>
                    <button
                        className={`btn bottom-nav-item`}
                        onClick={() => props.history.push('/')}
                    >
                        <img src={`/${ inMainScreen ? "home-bordo" : "home" }.svg`} height={21} alt=""/>
                        <div className={"small"}>
                            Главная
                        </div>
                    </button>
                </div>

                <div className={`col p-0 position-relative ${inMyCart ? "active-tab" : ""}`}>
                    {
                        (props.futureList && props.futureList.length > 0) &&
                        <div className="badge badge-light number badge-pill border-primary" style={{ position: 'absolute', top: 0, right: 10}}>
                            {props.futureList.length}
                        </div>
                    }

                    <button
                        className={`btn bottom-nav-item`}
                        onClick={() => props.history.push('/cart')}
                    >
                        <img src={`/${ inMyCart ? "cart-bordo" : "cart" }.svg`} height={21} alt=""/>
                        <div className={"small"}>
                            Моя карта
                        </div>
                    </button>
                </div>

                <div className={`col p-0 position-relative ${inDocsRouter ? "active-tab" : ""}`}>
                    <button
                        className={`btn bottom-nav-item`}
                        onClick={() => props.history.push('/doctors')}
                    >
                        <img src={`/docs${inDocsRouter ? "-bordo" : ""}.svg`} height={21} alt=""/>
                        <div className={"small"}>
                            Врачи
                        </div>
                    </button>
                </div>

                <div className={`col p-0 ${inAppsRouter ? "active-tab" : ""}`}>
                    <button
                        className={`btn bottom-nav-item ${inAppsRouter ? "active" : ""}`}
                        onClick={() => props.history.push('/apps')}
                    >
                        <img src={`/${ inAppsRouter ? "zapis-bordo" : "zapis" }.svg`} height={21} alt=""/>
                        <div className={"small"}>
                            Запись
                        </div>
                    </button>
                </div>

                <div className={`col p-0 ${inMoreRouter ? "active-tab" : ""}`}>
                    <button
                        className={`btn bottom-nav-item`}
                        onClick={() => props.history.push('/more')}
                    >
                        <img src={`/${ inMoreRouter ? "more-bordo" : "more" }.svg`} height={21} alt=""/>
                        <div className={"small"}>
                            Ещё
                        </div>
                    </button>
                </div>
            </nav>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        futureList: state.appointmentsReducer.futureList
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ enqueueSnackbar, logout }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BottomNavBar));

/*
<div className="col p-0">
    <button
        className="btn bottom-nav-item"
        onClick={() => props.history.push('teledoc')}
    >
        {
            props.history.location.pathname === "/teledoc" ? (
                <i className="fas text-primary fa-user-md fa-15x"></i>
            ) : (
                <i className="fas fa-user-md fa-15x"></i>
            )
        }
        <div className={"small"}>
            Онлайн
        </div>
    </button>
</div>

<div className="col p-0 position-relative">
    {
        (props.futureList && props.futureList.length > 0) &&
        <div className="badge badge-soft-danger number badge-pill" style={{ position: 'absolute', top: 0, right: '10%'}}>
            {props.futureList.length}
        </div>
    }

    <button
        className="btn bottom-nav-item"
        onClick={() => props.history.push('appointments')}
    >
        {
            props.history.location.pathname === "/appointments" ? (
                <i className="fas fa-tasks text-primary fa-15x"></i>
            ) : (
                <i className="fas fa-tasks fa-15x"></i>
            )
        }
        <div className={"small"}>
            Приёмы
        </div>
    </button>
</div>

<div className="col p-0">
    <button
        className="btn bottom-nav-item"
        onClick={() => props.history.push('analyzes')}
    >
        {
            props.history.location.pathname === "/analyzes" ? (
                <i className="fas text-primary fa-microscope fa-15x"></i>
            ) : (
                <i className="fas fa-microscope fa-15x"></i>
            )
        }
        <div className={"small"}>
            Анализы
        </div>
    </button>
</div>



 */