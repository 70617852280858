import React, { Component } from 'react';

export default class LoadingIndicator extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {

        }
    }

    render() {
        return (
            <div className="spinner-border text-primary" role="status" style={{width: '3rem', height: '3rem'}}>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }
}