import React, { useState } from 'react';
import dateFormat from "dateformat";
import CancelButton from "./CancelButton";

export default function FutureAppointment(props) {

    const [ show, setShow ] = useState(false);
    let row = props.appointment;

    return (

        <div className="card px-3 pb-2 mb-4 bg-white">
            <div className="d-flex justify-content-between mx-n3 py-2 pl-3 pr-2 align-items-center">
                <h3 className={"mb-0"}>
                    <b>
                        { row['лкДата'] }
                    </b>
                </h3>
                <button
                    className="btn btn-sm btn-outline-primary"
                    onClick={() => setShow(!show)}
                >
                    {show ? 'Свернуть' : 'Подробнее'}
                </button>
            </div>
            <b className={"small"}>
                Специальность
            </b>
            <div>
                {row['Специальность']}
            </div>


            <div className={show ? "" : "d-none"}>
                <hr className={"my-2"}/>

                <b className="small">
                    Специалист
                </b>
                <div>
                    {row['Специалист']}
                </div>

                <hr className={"my-2"}/>

                <b className={"small"}>
                    Стоимость
                </b>
                <div>
                    { row['Работы'].reduce((summ, item) => summ + item['Цена'], 0) } руб.
                </div>

                <hr className={"my-2"}/>

                <div>
                    {
                        row['Работы'].map( (line, k) => {
                            return (
                                <div
                                    className={"border border-2 rounded my-3 bg-lighter p-2 overflow-hidden"}
                                    key={"work" + "-" + k}
                                >
                                    <div className="d-flex align-items-center ">
                                        <i className="fas fa-file-medical fa-1x text-secondary"></i>
                                        <div className={"ml-3"}>
                                            {line['Номенклатура']}
                                        </div>
                                    </div>
                                    <hr className="text-secondary my-2"/>
                                    <div className="d-flex align-items-center ">
                                        <i className="fas fa-ruble-sign fa-1x text-secondary"></i>
                                        <div className={"ml-3"}>
                                            {line['Цена']} руб.
                                        </div>
                                    </div>
                                    <hr className="text-secondary my-2"/>
                                    <div className="d-flex align-items-center ">
                                        <i className="fas fa-user-md fa-1x text-secondary"></i>
                                        <div className={"ml-3"}>
                                            {line['Специалист']}
                                        </div>
                                    </div>

                                    {
                                        line['Подготовка'] &&
                                        <>
                                            <hr className="text-secondary my-2"/>
                                            <div className="d-flex align-items-center bg-danger text-white m-n2 p-2">
                                                <i className="fas fa-exclamation-circle fa-1x"></i>
                                                <div className={"ml-3"}>
                                                    <div>
                                                        <b>Информация о подготовке</b>
                                                        <div>
                                                            {line['Подготовка']}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            );
                        })
                    }
                </div>

                <hr className={"mb-3"}/>

                <div className={"mb-2"}>
                    <CancelButton appointment={row['Запись']} />
                </div>

            </div>
        </div>
    );
}