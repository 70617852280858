import * as ACTIONS from "../actionTypes";
import axios from "axios";
import config from "../../config";
import { setLoading, handleError, redirect, showModal } from "./commonActions";
import {GET_USER_PROFILES} from "../actionTypes";

export function clearOnlineData() {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.CLEAR_ONLINE_DATA,
            payload: ""
        });
    }
}

export function getProfiles() { //получаем список специальностей
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_USER_PROFILES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/online/getProfiles', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        //dispatch(getSpecs());
                        dispatch(setLoading(false));
                    })
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function setProfile(profile) {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.SET_PROFILE,
            payload: profile
        });
    }
}

export function getSpecs() { //получаем список специальностей
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_SPECIALITIES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/online/getSpecs', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getOnlineDocs() { //получаем список врачей для онлайн-консультаций
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_TELEDOCTORS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/online/getTeleDoctors', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function selectOnlineDoc(data) { //получаем список услуг
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.SELECT_ONLINE_DOCTOR,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/online/selectOnlineDoctor', data, config.axiosOptions)
                    .then((res) => {
                        let result = res.data;
                        //result.service = data['ОнлайнКонсультация'];
                        //result.service = result.chosenService['Номенклатура'];
                        result.speciality = data['Специальность'];
                        result.doctor = data['Врач'];
                        resolve(result);

                        dispatch(setLoading(false));
                        dispatch(showModal(true));
                        //dispatch(redirect("/online"));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getDocs(spec) { //получаем список врачей
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_DOCTORS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/online/getDoctors', {spec}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        if(res.data.doctors && res.data.doctors.length === 1){
                            dispatch(getServices(res.data.doctors[0]['Врач']));
                        }
                        else {
                            dispatch(setLoading(false));
                        }
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getServices(doc, dms) { //получаем список услуг
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_SERVICES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/online/getServices', {doc}, config.axiosOptions)
                    .then((res) => {

                        resolve({...res.data, dms});

                        dispatch(setLoading(false));

                        /*
                        if(res.data.services && res.data.services.length === 1){
                            dispatch(getFreeTime( res.data.services[0]['Номенклатура'], doc, res.data.services[0]['Цена'], res.data.services[0]['Наименование'], dms ));
                        }
                        else {
                            dispatch(setLoading(false));
                        }
                         */
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getFreeTime(service, doc, price, serviceName, dms, duration = 30) { //получаем список услуг
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_FREE_TIME,
            payload: new Promise((resolve, reject) => {
                axios.post(
                    config.api + '/online/getFreeTime',
                    { service, doc, duration },
                    config.axiosOptions
                )
                    .then((res) => {

                        let d = res.data;
                        d.price = price;
                        d.serviceName = serviceName;
                        d.dms = dms;

                        resolve(d);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}