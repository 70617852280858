import React, { useEffect } from 'react';
import { getProfiles, setProfile } from "../../redux/actions/onlineZapisActions";
import { connect } from "react-redux";
import moment from "moment";

const mapStateToProps = state => {
    return {
        profiles: state.onlineReducer.profiles,
        profile: state.onlineReducer.profile
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfiles: () => dispatch(getProfiles()),
        setProfile: (p) => dispatch(setProfile(p)),
    };
};

function Profiles(props){

    function onProfileChange(event) {
        if(event.target.value !== ""){
            props.setProfile(event.target.value);
        }
    }

    if(!(props.profiles && props.profiles.length > 0)) return (
        <div>Не найдено профилей</div>
    );

    return (
        <div className="form-group">
            <div className={"alert alert-light mb-2"}>
                <label htmlFor="profileSelect">
                    <b>1. Выберите, кого записать на приём</b>
                </label>
            </div>
            <select
                className="form-control"
                id="profileSelect"
                name={"profile"}
                value={props.profile}
                onChange={onProfileChange}
            >
                {
                    props.profiles.map(function (s, i) {
                        return (
                            <option
                                key={s['Клиент']}
                                value={s['Клиент']}
                            >
                                { s['Имя'] } ({ moment(s['ДатаРождения']).format("DD.MM.YYYY") })
                            </option>
                        )
                    })
                }
            </select>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);