import React from 'react';
//import { getSpecs, getDocs } from "../redux/actions/onlineZapisActions";
import { setLoading } from "../redux/actions/commonActions";
import {connect} from "react-redux";
import { withRouter, Link} from "react-router-dom";

const mapStateToProps = state => {
    return {
        paymentUrl: state.onlineReducer.paymentUrl,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelLoading: () => dispatch(setLoading(false))
        //getSpecs: () => dispatch(getSpecs()),
        //getDocs: (spec) => dispatch(getDocs(spec))
    };
};

function PaymentScreen(props){

    props.cancelLoading();

    if(props.paymentUrl){
        return (
            <div className="row">
                <div className="col-12 col-lg-8 mx-auto d-flex">
                    <div className="paymentWrap">
                        <div className="card h-100">
                            <div className="card-body p-0 p-md-3 h-100">
                                <iframe src={props.paymentUrl} frameBorder="0" className="w-100 h-100 d-block"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="row">
                <div className="col-12 col-lg-8 mx-auto">
                    <div className="card">
                        <div className="card-body text-center">
                            <i className="far fa-frown fa-4x my-5"></i>
                            <h2 className="text-center mb-4">
                                Недостаточно данных для совершения онлайн-оплаты
                            </h2>
                            <Link to={"/online"} className="btn btn-primary">
                                В онлайн-расписание
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);