import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { hideModal } from '../redux/actions/commonActions';
import { Modal } from 'react-bootstrap';
import { withSnackbar } from 'notistack';

const mapStateToProps = state => {
    return {
        showModal: state.commonReducer.showModal,
        modalBody: state.commonReducer.modalBody
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => dispatch(hideModal())
    };
};

function DocView(props){

    function handleHide() {
        props.hideModal();
    }

    return (
        <Modal
            show={props.showModal}
            size="lg"
            onHide={handleHide}
        >
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body style={{overflowX: 'scroll'}}>
                <div dangerouslySetInnerHTML={{__html: props.modalBody}} />
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleHide}>
                    Закрыть
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(DocView)));