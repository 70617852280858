import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";
import SpecItem from "../components/doctors/SpecItem";
import DocPhoto from "../components/doctors/DocPhoto";
import {getDocs, getServices, getSpecs} from "../redux/actions/onlineZapisActions";
import * as cosmeticActions from "../redux/actions/cosmetologyActions";
import {CLINIC_INTERFACE, COSMETOLOGY_INTERFACE} from "../redux/actionTypes";

const mapStateToProps = state => {
    return {
        specs: state.onlineReducer.specialities,
        spec: state.onlineReducer.speciality,
        showDoctor: state.commonReducer.showDoctor,
        loading: state.commonReducer.loading,
        style: state.interfaceReducer.style,
        cosmetologySpecs: state.cosmetologyReducer.specialities,
        cosmetologySpec: state.cosmetologyReducer.speciality,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSpecs: () => dispatch(getSpecs()),
        getDocs: (spec) => dispatch(getDocs(spec)),
        getServices: (doc) => dispatch(getServices(doc)),
        getCosmetologyDocs: () => dispatch(cosmeticActions.getDoctors()),
        getCosmetologySpecs: () => dispatch(cosmeticActions.getSpecs()),
        selectCosmoDoc: doc => dispatch(cosmeticActions.selectDoctor(doc))
    };
};

function DoctorsScreen(props) {

    const [showSpec, setShowSpec] = useState(false);
    const [showEd, setShowEd] = useState(false);

    useEffect( () => {
        if(props.style === COSMETOLOGY_INTERFACE) props.getCosmetologySpecs();
        else props.getSpecs();
    }, []);

    if(props.showDoctor){

        const goToOnlineZapis = async () => {
            if(props.style === COSMETOLOGY_INTERFACE) return props.history.push(`/cosmo-online?doc=${props.showDoctor['Врач']}`);

            window.localStorage.setItem("selectedDoc", props.showDoctor['Врач']);
            window.localStorage.setItem("selectedSpec", props.showDoctor['Специальность']);
            props.history.push(`/online`);
        };

        return (
            <>
                <Header back={1} />

                <div className="mx-auto" style={{ maxWidth: 600 }}>
                    <div>
                        <DocPhoto docID={props.showDoctor['Врач']} />
                    </div>

                    <div
                        className={"rounded-lg bg-primary text-white d-flex align-items-center justify-content-between"}
                        style={{ padding: "30px 17px" }}
                    >
                        <div>
                            <h1 className={"mb-1 text-uppercase"}>
                                { props.showDoctor['Фамилия'] }
                            </h1>
                            <h3 className={"m-0"}>
                                { props.showDoctor['Имя'] } { props.showDoctor['Отчество'] }
                            </h3>
                        </div>
                        <div>
                            <button
                                className={"btn rounded-pill bg-white text-primary"}
                                onClick={ goToOnlineZapis }
                            >
                                Записаться
                            </button>
                        </div>
                    </div>

                    <div className={"mt-3 alert alert-light text-dark"}>
                        { props.showDoctor['КраткоеОписание'] }
                    </div>

                    <div
                        className={"cursor-pointer d-flex align-items-center text-primary justify-content-between form-control"}
                        onClick={ () => setShowSpec(!showSpec) }
                    >
                        <div>
                            Специализация
                        </div>
                        <i className={`fas fa-chevron-${ showSpec ? "down" : "right"}`}></i>
                    </div>
                    {
                        showSpec &&
                        <div className={"p-2"} dangerouslySetInnerHTML={{ __html: props.showDoctor['Специализация'] || "В ближайшее время мы дополним информацию о враче" }}></div>
                    }

                    <div
                        className={"mt-4 cursor-pointer d-flex align-items-center text-primary justify-content-between form-control"}
                        onClick={ () => setShowEd(!showEd) }
                    >
                        <div>
                            Образование
                        </div>
                        <i className={`fas fa-chevron-${ showEd ? "down" : "right"}`}></i>
                    </div>
                    {
                        showEd &&
                        <div className={"p-2"} dangerouslySetInnerHTML={{ __html: props.showDoctor['Образование'] || "В ближайшее время мы дополним информацию о враче" }}></div>
                    }

                    <div className={"my-5"}></div>

                </div>
            </>
        );
    }
    else {
        return (
            <>
                <Header title={"ВРАЧИ"} />
                <div className="mx-auto pb-5" style={{ maxWidth: 600 }}>
                    { props.style === CLINIC_INTERFACE && props.specs.map( item => {
                        return <SpecItem item={item} key={item['Специальность']} />
                    }) }

                    { props.style === COSMETOLOGY_INTERFACE && props.cosmetologySpecs.map( item => {
                        return <SpecItem item={item} key={item['Специальность']} />
                    }) }
                </div>
            </>
        );
    }
}

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(DoctorsScreen));