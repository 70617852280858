/* common */
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const GET_FILE = "GET_FILE";
export const GET_PROMOS = "GET_PROMOS";
export const GET_BANNERS = "GET_BANNERS";
export const GET_PROFILES = "GET_PROFILES";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const DROP_CHECK_AUTH = "DROP_CHECK_AUTH";
export const CHECK_AUTH = "CHECK_AUTH";
export const LOADING = "LOADING";
export const REDIRECT = "REDIRECT";
export const GET_LIBS = "GET_LIBS";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_MODAL = false;
export const SET_BACKGROUND = "SET_BACKGROUND";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const SET_USER_CHANGES = "SET_USER_CHANGES";
export const GET_BONUS_CODE = "GET_BONUS_CODE";
export const GET_BONUS_HISTORY = "GET_BONUS_HISTORY";
export const GET_BALANCE = "GET_BALANCE";
export const SHOW_IMG_MODAL = "SHOW_IMG_MODAL";
export const SHOW_DOCTOR_PAGE = "SHOW_DOCTOR_PAGE";
export const SET_ACTIVE_PROFILE = "SET_ACTIVE_PROFILE";

/* РЕГИСТРАЦИЯ */
export const SEND_REG_CODE = "SEND_REG_CODE";
export const CHECK_REG_CODE = "CHECK_REG_CODE";
export const SUBMIT_REGISTRATION = "SUBMIT_REGISTRATION";

/* ПРИЕМЫ */
export const GET_FUTURE_APPOINTMENTS = "GET_FUTURE_APPOINTMENTS";
export const GET_PAST_APPOINTMENTS = "GET_PAST_APPOINTMENTS";
export const GET_PROTOCOL = "GET_PROTOCOL";
export const CANCEL_APPOINTMENT = "CANCEL_APPOINTMENT";
export const ADD_APPOINTMENT = "ADD_APPOINTMENT";

/* АНАЛИЗЫ */
export const GET_ANALYZES = "GET_ANALYZES";
export const GET_ANALYZ = "GET_ANALYZ";

/* ОНЛАЙН-ЗАПИСЬ */
export const GET_USER_PROFILES = "GET_USER_PROFILES";
export const SET_PROFILE = "SET_PROFILE";
export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_DOCTORS = "GET_DOCTORS";
export const GET_SERVICES = "GET_SERVICES";
export const GET_FREE_TIME = "GET_FREE_TIME";
export const GET_TELEDOCTORS = "GET_TELEDOCTORS";
export const SELECT_ONLINE_DOCTOR = "SELECT_ONLINE_DOCTOR";
export const CLEAR_ONLINE_DATA = "CLEAR_ONLINE_DATA";

/* ОНЛАЙН-ОПЛАТА */
export const GET_PAYMENT_URL = "GET_PAYMENT_URL";
export const SHOW_PAYMENT_MODAL = "SHOW_PAYMENT_MODAL";


/* КОСМЕТОЛОГИЯ */
export const CLINIC_INTERFACE = "CLINIC_INTERFACE";
export const COSMETOLOGY_INTERFACE = "COSMETOLOGY_INTERFACE";
export const SWITCH_INTERFACE = "SWITCH_INTERFACE";
export const GET_COSMETOLOGY_DATES = "GET_COSMETOLOGY_DATES";
export const COSMETOLOGY_SELECT_TIME = "COSMETOLOGY_SELECT_TIME";
export const GET_COSMETOLOGY_DOCTORS = "GET_COSMETOLOGY_DOCTORS";
export const COSMETOLOGY_SELECT_DOCTOR = "COSMETOLOGY_SELECT_DOCTOR";
export const GET_COSMETOLOGY_SPECIALITIES = "GET_COSMETOLOGY_SPECIALITIES";
export const COSMETOLOGY_SELECT_SERVICE = "COSMETOLOGY_SELECT_SERVICE";
export const GET_COSMETOLOGY_SERVICES = "GET_COSMETOLOGY_SERVICES";