import React from 'react';
import {connect} from "react-redux";
import {withSnackbar} from "notistack";
import {bindActionCreators} from "redux";
import {setLoading} from "../redux/actions/commonActions";
function NotFoundScreen(props) {
    props.setLoading(false);

    return (
        <div className="p-5 d-flex vh-100 justify-content-center align-items-center flex-column">
            <h1 className="my-5 text-center">
                Страница не найдена
            </h1>
        </div>
    );
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setLoading }, dispatch);

export default connect(null, mapDispatchToProps)(withSnackbar(NotFoundScreen));