import React, {useEffect} from 'react';
import { getOnlineDocs, selectOnlineDoc, getDocs, getServices, clearOnlineData } from "../redux/actions/onlineZapisActions";
import {setLoading, showModal} from "../redux/actions/commonActions";
import {connect} from "react-redux";
import { Modal } from 'react-bootstrap';
import OnlineFreeTime from "../components/online/OnlineFreeTime";
import ServicesOnline from "../components/online/ServicesOnline";
import Header from "../components/Header";


const mapStateToProps = state => {
    return {
        loading: state.commonReducer.loading,
        teledoctors: state.onlineReducer.teledoctors,
        dates: state.onlineReducer.dates,
        service: state.onlineReducer.service,
        showModal: state.onlineReducer.showModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: (flag) => dispatch(setLoading(flag)),
        getOnlineDocs: () => dispatch(getOnlineDocs()),
        selectOnlineDoc: doc => dispatch(selectOnlineDoc(doc)),
        getDocs: spec => dispatch(getDocs(spec)),
        getServices: doc => dispatch(getServices(doc)),
        setShowModal: (flag) => dispatch(showModal(flag)),
        clearOnlineData: () => dispatch(clearOnlineData())
    };
};

function OnlineDoctorsScreen(props){

    useEffect(() => {
        props.setLoading(true);
        props.getOnlineDocs();
    }, []);

    function handleHide() {
        props.clearOnlineData();
        props.setShowModal(false);
    }

    if(props.teledoctors && props.teledoctors.length){
        const docsList = props.teledoctors.map( (u,i) => {
            return (
                    <div
                        key={u['Врач']}
                        className="card bg-primary px-3 pb-2 mb-0 text-white w-100 cursor-pointer d-flex flex-grow-1"
                        onClick={ () => props.selectOnlineDoc(u) }
                    >
                        <div className="d-flex justify-content-between mx-n3 py-2 pl-3 pr-2 align-items-center">
                            <b className={"lead text-uppercase"}>
                                {u['Фамилия']} {u['Имя']} {u['Отчество']}
                            </b>
                        </div>
                        <div className="mt-2">
                            {u['Описание']}
                        </div>
                    </div>
            )
        });

        return (
            <div className="pb-5">

                <Header back={"/apps"} />

                <div className={"mb-4 alert alert-light p-3 rounded-lg"}>
                    <div>
                        Онлайн-консультация подходит вам в том случае, если вы хотите получить рекомендации:
                    </div>
                    <ul className="">
                        <li>
                            по необходимой диагностике, исходя из вашей проблемы;
                        </li>
                        <li>
                            по результатам анализов или диагностики;
                        </li>
                        <li>
                            по ранее установленному диагнозу.
                        </li>
                    </ul>
                </div>

                <div>
                    {
                        (window.ReactNativeWebView) ? (
                            <span className="text-decoration-underline text-primary" onClick={ () => window.ReactNativeWebView.postMessage("https://medeor74.ru/vrach-online/") }>
                                Подробнее об услуге «Онлайн-консультация врача»
                            </span>
                        ) : (
                            <a href="https://medeor74.ru/vrach-online/" target="_blank">Подробнее об услуге «Онлайн-консультация врача»</a>
                        )
                    }
                </div>

                <h1 className={"mt-5 text-center"}>
                    Выберите специалиста
                </h1>

                <div className="row1 docsGrid">
                    {docsList}
                </div>

                <Modal
                    show={props.showModal}
                    size="md"
                    onHide={handleHide}
                    dialogClassName="p-0"
                >
                    <Modal.Header closeButton bsPrefix="modal-header p-3 p-md-4">
                        <b>Расписание онлайн-приёма</b>
                    </Modal.Header>

                    <Modal.Body bsPrefix="modal-body p-3 p-md-4">
                        <ServicesOnline />
                        {
                            (props.service && props.service.length && props.dates && props.dates.length > 0)
                                ? <OnlineFreeTime/>
                                : <div>Свободного времени не найдено</div>
                        }
                        {
                            (props.service && props.service.length && props.dates && props.dates.length === 0) ? <div>Свободного времени не найдено</div> : null
                        }
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
    else {
        return (
            <div className="row">
                <div className="col-12 col-lg-8 mx-auto">
                    Нет данных
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnlineDoctorsScreen);