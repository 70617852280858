import * as ACTIONS from "../actionTypes";
import axios from "axios";
import config from "../../config";
import { setLoading, handleError, redirect } from "./commonActions";

export const showModal = (flag = true) => ({
    type: ACTIONS.SHOW_PAYMENT_MODAL,
    payload: flag
});

export function getLinkToAddBalance(data) {
    return function(dispatch) {
        dispatch(setLoading(true));

        dispatch( {
            type: ACTIONS.GET_PAYMENT_URL,
            payload: new Promise((resolve, reject) => {

                data.source = "";

                if(window.ReactNativeWebView) {
                    if( localStorage.os ) data.source = localStorage.os;
                    else data.source = "mobile-app";
                }
                else data.source = "web-lk";

                axios.post(config.api + '/payments/geturltoaddbalance', data, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        dispatch(setLoading(false));
                    });
            })
        });
    }
}