import React, { useEffect, useLayoutEffect } from 'react';
import { showModal } from "../../redux/actions/commonActions";
import {selectDoctor, preselectDoctor, getDoctors} from "../../redux/actions/cosmetologyActions";
import Doctors from "./Doctor";
import { connect } from "react-redux";

const flag = "DOCTORS";

const mapStateToProps = state => {
    return {
        doctors: state.cosmetologyReducer.doctors,
        doctor: state.cosmetologyReducer.doctor,
        loading: state.commonReducer.loading,
        show: state.onlineReducer.showModal === flag
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (flag) => dispatch(showModal(flag)),
        selectDoctor: (doc) => dispatch(selectDoctor(doc)),
        getDoctors: () => dispatch(getDoctors()),
        preselectDoctor: doc => dispatch(preselectDoctor(doc))
    };
};

function SelectDoctor(props){

    const qs = (function(a) {
        if (a === "") return {};
        var b = {};
        for (var i = 0; i < a.length; ++i)
        {
            var p=a[i].split('=', 2);
            if (p.length === 1)
                b[p[0]] = "";
            else
                b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
        }
        return b;
    })(window.location.search.substr(1).split('&'));

    useLayoutEffect( () => {
        async function a(){
            try {
                await props.getDoctors();
            }
            catch (e) {
                console.log(e);
            }

            if(qs['doc']) props.preselectDoctor(qs['doc']);
        }

        a();
    }, []);

    const clearDoc = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.showModal(false);
        props.selectDoctor(null);
        return false;
    }

    return (
        <>
            <div
                className={"rounded-lg p-4 border border-primary bg-white cursor-pointer"}
                style={{ display: "grid", alignItems: "center", gridTemplateColumns: "max-content 1fr", gridGap: "1rem" }}
            >
                <div
                    className={"rounded-circle bg-primary d-flex align-items-center justify-content-center"}
                    style={{ height: 50, width: 50 }}
                    onClick={ () => props.showModal(flag) }
                >
                    <i className={"text-white far fa-user fa-15x"}></i>
                </div>

                {
                    (props.doctor) && (
                        <div className={"d-flex align-items-center justify-content-between h-100"}>
                            <div
                                className={"h-100 d-flex align-items-center"}
                                onClick={ () => props.showModal(flag) }
                            >
                                <div>
                                    {props.doctor['Фамилия']} {props.doctor['Имя']} {props.doctor['Отчество']}
                                </div>
                            </div>
                            <i
                                className={"fas fa-trash text-secondary fa-15x"}
                                onClick={ clearDoc }
                            ></i>
                        </div>
                    )
                }

                {
                    !props.doctor &&
                    <div
                        className={"h-100 d-flex align-items-center"}
                        onClick={ () => props.showModal(flag) }
                    >
                        <div>
                            Выберите специалиста
                        </div>
                    </div>
                }

            </div>
            <div>
                { props.show && <Doctors />}
            </div>
        </>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDoctor);