import React, { useEffect } from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../components/Header";
import {COSMETOLOGY_INTERFACE} from "../redux/actionTypes";

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        futureList: state.appointmentsReducer.futureList,
        style: state.interfaceReducer.style
    };
};

function AppointmentsRouterScreen(props) {

    useEffect(() => {
        if(props.style === COSMETOLOGY_INTERFACE) props.history.push("/cosmo-online");
    }, [props.style]);

    return (
        <>
            <div className="mx-auto" style={{ maxWidth: 600 }}>

                <Header title={"ЗАПИСЬ НА ПРИËМ"} />

                <div className={"mb-4 alert alert-light p-3 rounded-lg"}>
                    <div className={"mb-0"}>
                        Выберите удобный для вас формат консультации со специалистом
                    </div>
                </div>

                <div
                    className="card bg-primary text-white p-3 cursor-pointer"
                    onClick={() => props.history.push('/online')}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <div>
                                <b>
                                    ОЧНЫЙ ПРИЁМ
                                </b>
                            </div>
                            <div className={"small"}>
                                Приём в медицинском центре
                            </div>
                        </div>
                        <i className={"fas fa-chevron-right fa-15x"}></i>
                    </div>
                </div>


                <div
                    className="card p-3 cursor-pointer text-primary"
                    onClick={() => props.history.push('/teledoc')}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <div>
                                <b>
                                    ОНЛАЙН-КОНСУЛЬТАЦИЯ
                                </b>
                            </div>
                            <div className={"small"}>
                                Консультация по видеосвязи с врачом
                            </div>
                        </div>
                        <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                    </div>
                </div>

                <div className={"card p-3 text-center"}>
                    <div>
                        Уважаемые пациенты!
                    </div>
                    <div>
                        Запись на услуги по полису ДМС осуществляется только через колл-центр по телефону:
                    </div>
                    <div className={"mt-3"}>
                        <a href="tel:+73512778808">
                            8 (351) 277-88-08
                        </a>
                    </div>
                </div>


            </div>
        </>
    );
}

export default connect(mapStateToProps)(withRouter(AppointmentsRouterScreen));