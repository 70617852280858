import React, { useLayoutEffect, useState } from 'react';
import { getFreeTime } from "../../redux/actions/onlineZapisActions";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        services: state.onlineReducer.services,
        service: state.onlineReducer.service,
        serviceOnline: state.onlineReducer.serviceOnline,
        doc: state.onlineReducer.doctor
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFreeTime: (service, doc, price, serviceName) => dispatch(getFreeTime(service, doc, price, serviceName)),
    };
};

function ServicesOnline(props){
    const [serviceData, setData] = useState({});

    useLayoutEffect(() => {
        if(props.service && props.service.length){
            setData(props.services.find(s => s['Номенклатура'] === props.service));
        }
        else setData({});
    }, [props.service]);

    function handleChange(event) {
        if(event.target.value !== ""){
            let ss;
            let price = 0;
            ss = props.services.find(s => s['Номенклатура'] === event.target.value);
            //console.log(ss);
            //price = Math.floor( ss['Цена'] * (100 - ss['ОнлайнПроцентСкидки']) / 100 );
            if(ss) props.getFreeTime(event.target.value, props.doc, ss['Цена'], ss["Наименование"]);
        }
    }

    if(props.services && props.services.length === 1) {
        return (
            <div>
                <div className="alert-light alert px-3">
                    <div>
                        {props.services[0]["Наименование"]}
                    </div>
                    <div className="mt-2">
                        <b>Стоимость:</b> { props.services[0]['Цена'] } рублей
                    </div>
                </div>
            </div>
        );
    }

    if(props.services && props.services.length > 1)
        return (
            <>
                <div className="form-group form-group-lg">
                    <label><b>Выберите услугу</b></label>
                    <select className="form-control" value={props.service} onChange={handleChange}>
                        <option value={""}> </option>
                        {
                            props.services.map(function (s, i) {
                                return (
                                    <option key={i} value={s["Номенклатура"]}>{s["Наименование"]}</option>
                                )
                            })
                        }
                    </select>
                </div>
                {
                    (serviceData['Цена'] !== undefined) &&
                    <div className="alert alert-light" role="alert">
                        <b>Стоимость:</b> { serviceData['Цена'] } рублей
                    </div>
                }
            </>
        );

    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesOnline);