import React, { useEffect } from "react";
import {connect} from "react-redux";
import { showModal, setLoading } from "../../redux/actions/commonActions";
import { getDoctors, selectDoctor } from "../../redux/actions/cosmetologyActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Doctors extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            date: false,
            time: false,
            nav1: null,
            nav2: null,
            dms: false
        };
    }

    componentDidMount() {
        this.props.getDoctors();
    }

    showMore = (e,doc) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            showMoreInfo: doc,
        });

        return false;
    };

    hideModal = () => {
        this.setState({
            showMoreInfo: null,
        });
    };

    render() {

        let docs = null;
        let moreInfoModal = null;

        if(!(this.props.doctors && this.props.doctors.length)) docs = (
            <div className={"text-center h2"}>
                Не найдено специалистов, подходящих под выбранные фильтры
            </div>
        );
        else {
            docs = Array.from(this.props.doctors).map((u,i) => {

                let activeClass = "";

                if(this.props.doctor && this.props.doctor['Врач'] === u['Врач']) activeClass = "border-3";

                return (
                    <div
                        key={i}
                        className={"border border-primary mb-3 rounded-lg p-3 cursor-pointer " + activeClass}
                        onClick={ () => this.props.selectDoctor(u) }
                    >

                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div className={"h2 mb-0"}>
                                {u['Фамилия']} {u['Имя']} {u['Отчество']}
                            </div>

                            <div
                                style={{ width: 30, height: 30 }}
                                className={"d-flex align-items-center justify-content-center rounded-circle border border-secondary"}
                                onClick={ (e) => this.showMore(e,u) }
                            >
                                <i className={"fas fa-info text-secondary"}></i>
                            </div>
                        </div>

                        { u['КраткоеОписание'] &&
                            <div className={"text-secondary mt-3"}>
                                { u['КраткоеОписание'] }
                            </div>
                        }
                    </div>
                );
            });
        }

        moreInfoModal = null;

        if(this.state.showMoreInfo){
            moreInfoModal = (
                <>
                    <div className="modal fade show d-block" role="dialog">
                        <div className="modal-dialog modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title" id="exampleModalCenterTitle">
                                        О специалисте
                                    </h2>
                                    <button type="button" className="close" onClick={ () => this.hideModal() }>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body px-3">
                                    <h2>
                                        { this.state.showMoreInfo['Фамилия'] } { this.state.showMoreInfo['Имя'] } { this.state.showMoreInfo['Отчество'] }
                                    </h2>

                                    { this.state.showMoreInfo['Специализация'] && (
                                        <>
                                            <h3 className={"text-primary"}>
                                                Специализация
                                            </h3>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.showMoreInfo['Специализация'] }} />
                                        </>
                                    ) }

                                    { this.state.showMoreInfo['Образование'] && (
                                        <>
                                            <h3 className={"text-primary"}>
                                                Образование
                                            </h3>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.showMoreInfo['Образование'] }} />
                                        </>
                                    ) }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            );

            return moreInfoModal;
        }

        return (
            <>
                <div className="modal fade show d-block" role="dialog">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title" id="exampleModalCenterTitle">
                                    Выберите специалиста
                                </h2>
                                <button type="button" className="close" onClick={ () => this.props.showModal(false) }>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body px-3">
                                <div className="position-relative">
                                    <div className="form-group mb-0">
                                        <div className="overflow-hidden1 position-relative mh250">
                                            { docs }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        );

    }
}

const mapStateToProps = state => {
    return {
        doctors: state.cosmetologyReducer.doctors,
        doctor: state.cosmetologyReducer.doctor,
        time: state.cosmetologyReducer.time,
        user: state.commonReducer.user,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: flag => dispatch(showModal(flag)),
        setLoading: flag => dispatch(setLoading(flag)),
        getDoctors: () => dispatch(getDoctors()),
        selectDoctor: (doctor) => dispatch(selectDoctor(doctor))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Doctors);