import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import AdminLayout from './layouts/AdminLayout';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import Notifier from "./Notifier";
import WelcomeScreen from "./screens/WelcomeScreen";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { enqueueSnackbar } from './redux/actions/notificationActions';
import { redirect } from "./redux/actions/commonActions";
import { COSMETOLOGY_INTERFACE, CLINIC_INTERFACE } from "./redux/actionTypes";
import PolicyScreen from "./screens/PolicyScreen";

function App(props){

    useEffect(() => {
        if(props.path !== "") {
            props.history.push(props.path);
            props.redirect("");
        }
    },[props.path]);

    useEffect(
        () => window.scrollTo(0, 0),
        [props.location.pathname]
    );

    useEffect(() => {
        let r = document.querySelector(':root');
        if(props.style && r) {
            r.style.setProperty('--bordo', (props.style === COSMETOLOGY_INTERFACE) ? "#d6ab6b" : "#742239" );
            r.style.setProperty('--text1', (props.style === COSMETOLOGY_INTERFACE) ? "#3e2634" : "#FFF" );
            r.style.setProperty('--text2', (props.style === COSMETOLOGY_INTERFACE) ? "#FFF" : "#000" );
            r.style.setProperty('--font-family', (props.style === COSMETOLOGY_INTERFACE) ? "Gotham" : "Roboto");
            r.style.setProperty('--font-family-bold', (props.style === COSMETOLOGY_INTERFACE) ? "Gotham" : "Roboto-Bold");
        }
    },[props.style]);

    return (
        <>
            <Notifier />
            <Switch>
                <Route path={"/login"} component={LoginScreen} />
                <Route path={"/register"} component={RegisterScreen} />
                <Route path={"/policy"} component={ PolicyScreen } />
                <Route path={"/"} component={AdminLayout} />
            </Switch>
            <WelcomeScreen />
        </>
    );
}

const mapStateToProps = state => {
    return {
        auth: state.commonReducer.auth,
        path: state.commonReducer.path,
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ enqueueSnackbar, redirect }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(App));