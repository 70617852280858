import React, { useEffect, useLayoutEffect } from 'react';
import { showModal } from "../../redux/actions/commonActions";
import { selectService, getServices } from "../../redux/actions/cosmetologyActions";
import { connect } from "react-redux";

const mapStateToProps = state => {
    return {
        services: state.cosmetologyReducer.services,
        service: state.cosmetologyReducer.service,
        doctor: state.cosmetologyReducer.doctor,
        loading: state.commonReducer.loading,
        show: state.onlineReducer.showModal
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (flag) => dispatch(showModal(flag)),
        getServices: (doc) => dispatch(getServices({doc})),
        selectService: (service) => dispatch(selectService(service))
    };
};

function Services(props){

    useLayoutEffect(() => {
        props.getServices(props.doctor ? props.doctor['Врач'] : undefined);
    }, []);

    const select = (s) => {
        props.showModal(false);
        props.selectService(s);
    }

    return (
        <>
            <div className="modal fade show d-block" role="dialog">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalCenterTitle">
                                Выберите услугу
                            </h2>
                            <button type="button" className="close" onClick={ () => props.showModal(false) }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body px-3">
                            <div className="position-relative">
                                <div className="form-group mb-0">
                                    <div className="overflow-hidden1 position-relative mh250">
                                        { props.services.map( s => {
                                            return (
                                                <div
                                                    key={ s['Номенклатура'] }
                                                    className={"rounded-lg border border-primary mb-3 px-3 pt-3 pb-2 cursor-pointer"}
                                                    onClick={ () => select(s) }
                                                >
                                                    <h3>
                                                        { s['Наименование'] }
                                                    </h3>
                                                    <div className={"text-primary"}>
                                                        { s['Цена'] } руб.
                                                    </div>
                                                </div>
                                            )
                                        } ) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);