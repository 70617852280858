import React, { useLayoutEffect, useState } from 'react';
import { getFreeTime } from "../../redux/actions/onlineZapisActions";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        services: state.onlineReducer.services,
        service: state.onlineReducer.service,
        speciality: state.onlineReducer.speciality,
        serviceOnline: state.onlineReducer.serviceOnline,
        doc: state.onlineReducer.doctor,
        loading: state.commonReducer.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFreeTime: (service, doc, price, serviceName) => {
            dispatch(getFreeTime(service, doc, price, serviceName));
        },
    };
};

function Services(props){
    const [serviceData, setData] = useState({});

    //console.log(props.speciality);

    useLayoutEffect(() => {
        if(props.service && props.service.length){
            setData(props.services.find(s => s['Номенклатура'] === props.service));
        }
        else setData({});
    }, [props.service]);

    useLayoutEffect(() => {
        if(props.services && props.services.length === 1){
            const s = props.services[0];

            props.getFreeTime(
                s['Номенклатура'],
                props.doc,
                s['Цена'],
                s['Наименование'],
                s['Длительность']
            );

            setData(props.services[0]);
        }
    }, [props.services]);

    function handleChange(event) {
        if(event.target.value !== ""){
            let ss;
            ss = props.services.find(s => s['Номенклатура'] === event.target.value);
            if(ss) {
                props.getFreeTime(
                    event.target.value,
                    props.doc,
                    ss['Цена'],
                    ss["Наименование"],
                    ss['Длительность']
                );
            }
        }
    }

    function renderBlockTitle(){
        return (
            <div className="alert alert-light mb-2">
                <label>
                    <b>
                        4. Выберите услугу
                    </b>
                </label>

                {
                    props.speciality === "e1b1abcd-d136-11e5-80c0-0cc47a0dc212" &&
                    <div className={"alert alert-warning mb-0 mt-2"}>
                        Запись на процедуры осуществляется только через звонок в колл-центр
                    </div>
                }
            </div>
        );
    }

    if (props.services && props.services.length === 1) {

        return (
            <div>

                {renderBlockTitle()}

                <div className="">
                    {
                        serviceData &&
                        <div className="form-control h-auto">
                            {serviceData["Наименование"]}
                        </div>
                    }

                    {
                        (props.serviceOnline) &&
                        <div className="alert alert-light" role="alert">
                            Консультация в режиме онлайн
                        </div>
                    }
                </div>

                {
                    (serviceData && serviceData['Цена'] !== undefined) &&
                    <ul className={"mt-2"}>
                        <li>
                            Стоимость: {serviceData['Цена']} руб.
                        </li>
                    </ul>
                }

                {
                    (serviceData && serviceData['Подготовка'] !== undefined) &&
                    <div className="bg-primary text-white alert">
                        <b>
                            <i className="fas fa-exclamation-circle mr-2"></i>
                            ИНФОРМАЦИЯ О ПОДГОТОВКЕ
                        </b>
                        <div>
                            {serviceData['Подготовка']}
                        </div>
                    </div>
                }
            </div>
        );
    }

    if (props.services && props.services.length > 1) {
        return (
            <>
                {renderBlockTitle()}

                <div className="">
                    <select className="form-control" value={props.service} onChange={handleChange}>
                        <option value={""}></option>
                        {
                            props.services.map(function (s, i) {
                                return (
                                    <option key={i} value={s["Номенклатура"]}>{s["Наименование"]}</option>
                                )
                            })
                        }
                    </select>
                </div>

                {
                    (props.serviceOnline) &&
                    <div className="alert alert-light" role="alert">
                        Консультация в режиме онлайн
                    </div>
                }

                {
                    (serviceData['Цена'] !== undefined) &&
                    <ul className={"mt-2"}>
                        <li>
                            Стоимость: {serviceData['Цена']} руб.
                        </li>
                    </ul>
                }

                {
                    (serviceData['Подготовка'] !== undefined) &&
                    <div className="bg-primary text-white alert">
                        <b>
                            <i className="fas fa-exclamation-circle mr-2"></i>
                            ИНФОРМАЦИЯ О ПОДГОТОВКЕ
                        </b>
                        <div>
                            {serviceData['Подготовка']}
                        </div>
                    </div>
                }
            </>
        );
    }

    return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);