import React, { useEffect } from 'react';
import { getProfiles } from "../../redux/actions/onlineZapisActions";
import {logout, setActiveProfile} from "../../redux/actions/commonActions";
import { connect } from "react-redux";
import moment from "moment";

const mapStateToProps = state => {
    return {
        profiles: state.onlineReducer.profiles,
        profile: state.onlineReducer.profile,
        user: state.commonReducer.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProfiles: () => dispatch(getProfiles()),
        setActiveProfile: (p) => dispatch(setActiveProfile(p)),
        logout: (guid) => dispatch(logout(guid)),
    };
};

function Profiles(props){

    function onProfileChange(p) {
        props.setActiveProfile(p['Клиент']);
        props.getProfiles();
    }

    function handleExit() {
        const r = window.confirm("Выйти из профиля?");
        if(!r) return;

        props.logout(props.user.guid);
    }

    function renderProfiles(){
        return props.profiles.map(p => {
            if(p['Клиент'] === props.profile) return null;

            return (
                <div
                    key={p['Клиент']}
                    className={"cursor-pointer form-control mb-3 d-flex align-items-center justify-content-between"}
                    onClick={ () => onProfileChange(p) }
                >
                    <div>
                        <i className={"far fa-user mr-2 text-primary"}></i> { p['Имя'] }
                    </div>
                    <div className={"badge badge-light"}>
                        { moment(p['ДатаРождения']).format("DD.MM.YYYY")}
                    </div>
                </div>
            );
        });
    }

    if(!(props.profiles && props.profiles.length > 0)) return null;

    return (
        <>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h3 className="mb-0" style={{whiteSpace: "nowrap"}}>
                        <b>
                            АКТИВНЫЙ ПРОФИЛЬ
                        </b>
                    </h3>
                    <div className={"text-right"}>
                        <button
                            className={"btn btn-primary btn-sm"}
                            onClick={handleExit}
                        >
                            Выйти
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <h2 className="mb-0">
                        {props.user['Фамилия']} {props.user['Имя']} {props.user['Отчество']}
                    </h2>
                </div>
            </div>
            <div className="card mb-md-0">
                <div className={"card-header"}>
                    ПЕРЕЙТИ В ДРУГОЙ ПРОФИЛЬ
                </div>
                <div className={"card-body pb-0"}>
                    {renderProfiles()}
                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profiles);