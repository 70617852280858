import React, { useEffect } from "react";
import Slider from "react-slick";
import {connect} from "react-redux";
import dateformat from 'dateformat';
import { showModal, setLoading } from "../../redux/actions/commonActions";
import { getDates, selectTime } from "../../redux/actions/cosmetologyActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class FreeTime extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            date: false,
            time: false,
            nav1: null,
            nav2: null,
            dms: false
        };
    }

    componentDidMount() {
        this.props.getDates();
    }

    selectTime(date, humanDate, time, doctors){
        this.props.selectTime({date, time, humanDate, doctors});
        this.props.showModal(false);
    }

    render() {

        if(this.props.dates && this.props.dates.length > 0){

            let settings1 = {
                dots: false,
                infinite: false,
                arrows: false,
                centerMode: true,
                focusOnSelect: true,
                swipeToSlide: true,
                speed: 500,
                slidesToShow: this.props.dates.length,
                slidesToScroll: 1,
                centerPadding: "10px"
            };

            if(this.props.dates && this.props.dates.length > 0 && this.props.dates.length <= 3 ){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    slidesToShow: this.props.dates.length,
                    slidesToScroll: 1,
                    centerPadding: "10px"
                };
            }

            if(this.props.dates && this.props.dates.length > 3){
                settings1 = {
                    dots: false,
                    infinite: false,
                    arrows: false,
                    centerMode: true,
                    focusOnSelect: true,
                    swipeToSlide: true,
                    speed: 500,
                    responsive: [
                        {
                            breakpoint: 7680,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "50px"
                            }
                        },
                        {
                            breakpoint: 425,
                            settings: {
                                slidesToShow: 5,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 375,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1,
                                centerPadding: "10px"
                            }
                        },
                        {
                            breakpoint: 320,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                centerPadding: "15px"
                            }
                        }
                    ]
                };
            }

            let settings2 = {
                dots: false,
                infinite: false,
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
                speed: 500,
                slidesToScroll: 1,
                adaptiveHeight: false
            };

            const sortedDates = this.props.dates.sort( (a,b) => {
                let a1 = +`${a.date.replaceAll("-","")}`;
                let b1 = +`${b.date.replaceAll("-","")}`;
                return a1 - b1;
            });

            let days = sortedDates.map((u,i) => {
                return (
                    <div key={i} className={"px-3"}>
                        <button type="button" className="btn btn-outline-primary nav1 px-2">
                            {u.humanDate}
                        </button>
                    </div>
                );
            });

            let dates = this.props.dates.map((u,i) => {

                const sortedTimes = Object.keys(u.times).sort( (a,b) => {
                    let a1 = +`${a.replace(":","")}`;
                    let b1 = +`${b.replace(":","")}`;
                    return a1 - b1;
                });

                const times = sortedTimes.map((key,j) => {
                    return (
                        <button
                            key={j}
                            type="button"
                            className="btn btn-outline-primary lift"
                            onClick={() => this.selectTime(u.date, u.humanDate, key, u.times[key])}
                        >
                            {key}
                        </button>
                    );
                });

                return (
                    <div key={i}>
                        <h3 className={"px-3"}>
                            <b>Время</b>
                        </h3>
                        <div className={"timeGrid px-3"}>
                            {times}
                        </div>
                    </div>
                );
            });

            if(!(this.state.nav2 && this.state.nav1)){
                this.setState({
                    nav1: this.slider1,
                    nav2: this.slider2
                });
            }

            return (
                <div>
                    <div className="modal fade show d-block" role="dialog">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h2 className="modal-title" id="exampleModalCenterTitle">
                                        Выберите дату и время
                                    </h2>
                                    <button type="button" className="close" onClick={ () => this.props.showModal(false) }>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="position-relative">
                                        <div className="form-group mb-0">
                                            <div className="overflow-hidden1 position-relative mh250">
                                                <div>
                                                    <h3 className={"px-3"}>
                                                        <b>Дата</b>
                                                    </h3>
                                                    <div className={"border border-primary py-4 rounded"}>
                                                        <Slider
                                                            asNavFor={this.state.nav2}
                                                            ref={slider => (this.slider1 = slider)}
                                                            {...settings1}
                                                        >
                                                            {days}
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                            <div></div>
                                                        </Slider>
                                                    </div>

                                                    <div className={"my-3"}></div>

                                                    <div className={"border border-primary py-4 rounded"}>
                                                        <Slider
                                                            asNavFor={this.state.nav1}
                                                            ref={slider => (this.slider2 = slider)}
                                                            {...settings2}
                                                        >
                                                            {dates}
                                                        </Slider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            );
        }
        else return null;
    }
}

const mapStateToProps = state => {
    return {
        dates: state.cosmetologyReducer.dates,
        user: state.commonReducer.user,
        loading: state.commonReducer.loading,
        //date: state.cosmetologyReducer.date,
        //time: state.cosmetologyReducer.time,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: flag => dispatch(showModal(flag)),
        setLoading: flag => dispatch(setLoading(flag)),
        getDates: () => dispatch(getDates()),
        selectTime: (data) => dispatch(selectTime(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeTime);