import React, {useEffect} from "react";
import {setLoading} from "../redux/actions/commonActions";
import {getProtocol} from "../redux/actions/appointmentsActions";
import {switchInterface} from "../redux/actions/interfaceActions";
import { CLINIC_INTERFACE, COSMETOLOGY_INTERFACE } from "../redux/actionTypes";
import {connect} from "react-redux";
import { withRouter, Link, NavLink } from 'react-router-dom';
import dateFormat from "dateformat";
import PromoSlider from "../components/home/PromoSlider";
import Notifications from "../components/home/Notifications";
import config from "../config";
import Header from "../components/Header";
import CosmetologySlider from "../components/home/CosmetologySlider";

function HomeScreen(props) {

    return (
        <div className="row1" style={{ maxWidth: 600, margin: "auto" }}>

            <div className="col-12 col-md-6- d-flex flex-column mx-auto">

                <div className={"mb-3 d-flex align-items-start justify-content-between"}>
                    <Header mb={"0"} title={"ГЛАВНАЯ"}/>
                    <NavLink to={"/topup"} className={"btn pt-0 d-flex align-items-start d-md-none"}>
                        <i className={"fas fa-ruble-sign fa-15x text-primary"}></i>
                    </NavLink>
                </div>

                {(props.style !== COSMETOLOGY_INTERFACE) &&
                    <div className={"card flex-grow-1 bg-primary"}>
                        <div className="card-body">
                            <h2 className="text-center text-white mb-0" style={{lineHeight: '2rem'}}>
                                {props.user['Имя']} {props.user['Отчество']}, на вашей бонусной
                                карте <b>{props.bonuses}</b> бонусов
                            </h2>
                            <div className="text-center mt-4">
                                {
                                    window.ReactNativeWebView ? (
                                        <Link to={"/code"} className="btn btn-white">
                                            Получить код
                                        </Link>
                                    ) : (
                                        <Link to={"/online"} className="btn btn-white">
                                            Записаться в 5 кликов
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="col-12 d-flex">
                <div className="card flex-grow-1">
                    <div className="card-header d-flex justify-content-between">
                        <h3 className="mb-0">
                            <b>
                                БЛИЖАЙШИЙ ПРИЁМ
                            </b>
                        </h3>
                        {
                            (props.appointment) ?
                                (
                                    <button
                                        className="btn btn-sm btn-outline-primary flex-grow-0 mr-n3"
                                        onClick={() => props.history.push("/appointments")}
                                    >
                                        Перейти
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-sm btn-outline-primary flex-grow-0 mr-n3"
                                        onClick={() => props.history.push("/online")}
                                    >
                                        Записаться
                                    </button>
                                )
                        }
                    </div>

                    <div className="card-body">
                        {
                            (props.appointment) ? (
                                <div className="row">
                                    <div className="col">
                                        <div className="d-flex align-items-center">
                                            <i className="far text-primary fa-id-card fa-15x mr-3"></i>
                                            {props.appointment['Специалист']} ({props.appointment['Специальность']})
                                        </div>
                                        <div className="d-flex align-items-center mt-3">
                                            <i className="far fa-clock text-primary fa-15x mr-3"></i>
                                            {dateFormat(props.appointment['Дата'] + "+05:00", "dd.mm.yyyy в HH:MM")}
                                        </div>
                                    </div>
                                    <div className="col-auto d-none">
                                        <button
                                            className="btn btn-link text-primary lift"
                                            onClick={() => props.history.push("/appointments")}
                                        >
                                            <i className="fas fa-chevron-right fa-2x"></i>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <i className="far fa-calendar-minus text-primary fa-2x"></i>
                                    <div className="mt-4">
                                        Запланированных приёмов не найдено
                                    </div>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>

            {
                (!config.isMobile && 500 > 1000) && (
                    <div className="col-12 col-md-6 d-flex">
                        <div className="card flex-grow-1 d-flex">
                            <div className="card-header bg-light-gray flex-grow-0">
                                <h2 className="mb-0">
                                    Уведомления
                                </h2>
                            </div>
                            <div style={{overflowY: 'scroll', maxHeight: 365}}>
                                <Notifications/>
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="col-12">
                <PromoSlider/>
            </div>

            {
                (1 > 2 && props.style === CLINIC_INTERFACE && props.user['Баннеры'].length) &&
                <div className="col-12">
                    <div className="card pb-4">
                        <div className="card-header bg-light-gray d-none">
                            <h2 className="mb-0">
                                Специальные предложения
                                <span
                                    className="d-none ml-2 badge badge-pill number badge-soft-info">{props.user['Баннеры'].length}</span>
                            </h2>
                        </div>
                        <PromoSlider/>
                    </div>
                </div>
            }

            {
                (1 > 2 && props.style === COSMETOLOGY_INTERFACE) &&
                <div className="col-12">
                    <div className="card pb-4">
                        <div className="card-header">
                            <b>
                                СПЕЦИАЛЬНЫЕ ПРЕДЛОЖЕНИЯ
                            </b>
                        </div>
                        <div className={"pt-2"}>
                            <CosmetologySlider/>
                        </div>
                    </div>
                </div>
            }

            {
                (props.style === COSMETOLOGY_INTERFACE) &&
                <div className={"d-none col-12"}>
                    <div
                        className="card p-3 cursor-pointer"
                        onClick={() => props.switchInterface(CLINIC_INTERFACE)}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <div>
                                    <b>
                                        Перейти в Медцентр
                                    </b>
                                </div>
                            </div>
                            <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                        </div>
                    </div>
                </div>
            }

            {
                (props.style === CLINIC_INTERFACE) &&
                <div className={"d-none col-12"}>
                    <div
                        className="card p-3 cursor-pointer"
                        onClick={() => props.switchInterface(COSMETOLOGY_INTERFACE)}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <div>
                                    <b>
                                        Перейти в Косметологию
                                    </b>
                                </div>
                            </div>
                            <i className={"fas fa-chevron-right fa-15x text-primary"}></i>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => {
    return {
        user: state.commonReducer.user,
        bonuses: state.commonReducer.bonuses,
        bg: state.commonReducer.background,
        showWelcomeSplash: state.commonReducer.showWelcomeSplash,
        appointment: state.appointmentsReducer.futureList[0],
        style: state.interfaceReducer.style
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setLoading: () => dispatch(setLoading()),
        getProtocol: (appointment, protocol, mode) => dispatch(getProtocol(appointment, protocol, mode)),
        switchInterface: (type) => dispatch(switchInterface(type))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeScreen));