import React, { useEffect } from 'react';
import { showModal } from "../../redux/actions/commonActions";
import { selectTime } from "../../redux/actions/cosmetologyActions";
import FreeTime from "./FreeTIme";
import { connect } from "react-redux";

const flag = "TIMES";

const mapStateToProps = state => {
    return {
        date: state.cosmetologyReducer.date,
        humanDate: state.cosmetologyReducer.humanDate,
        time: state.cosmetologyReducer.time,
        loading: state.commonReducer.loading,
        show: state.onlineReducer.showModal === flag
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (flag) => dispatch(showModal(flag)),
        selectTime: ({ date, time, humanDate, doctors }) => dispatch(selectTime({time, date, humanDate, doctors}))
    };
};

function m(props){

    const clearTime = () => {
        props.selectTime({time: null, humanDate: null, date: null, doctors: new Set});
        props.showModal(false);
    }

    return (
        <>
            <div
                className={"rounded-lg p-4 border border-primary bg-white cursor-pointer"}
                style={{ display: "grid", alignItems: "center", gridTemplateColumns: "max-content 1fr", gridGap: "1rem" }}
            >
                <div
                    className={"rounded-circle bg-primary d-flex align-items-center justify-content-center"} style={{ height: 50, width: 50 }}
                    onClick={ () => props.showModal(flag) }
                >
                    <i className={"text-white far fa-calendar fa-15x"}></i>
                </div>

                {
                    (props.humanDate && props.time) && (
                        <div className={"d-flex align-items-center justify-content-between"}>
                            <div
                                className={"h-100 d-flex align-items-center"}
                                onClick={ () => props.showModal(flag) }
                            >
                                <div>
                                    {props.humanDate} в {props.time}
                                </div>
                            </div>
                            <i
                                className={"fas fa-trash text-secondary fa-15x"}
                                onClick={ clearTime }
                            ></i>
                        </div>
                    )
                }

                {
                    !(props.humanDate && props.time) &&
                    <div onClick={ () => props.showModal(flag) }>
                        Выберите дату и время
                    </div>
                }

            </div>
            <div>
                { props.show && <FreeTime />}
            </div>
        </>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(m);